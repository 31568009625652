import { AxiosError } from 'axios';
import clsx from 'clsx';
import { ChangeEvent, useState } from 'react';

import { queryClient } from '@/common/react-query';
import { Button, Spinner, TextInput } from '@/components';
import { useWorkspaceContext } from '@/providers/WorkspaceProvider';

import {
  useGetUserForEmail,
  useInviteUserToWorkspace,
} from '../hooks/useUsers';

export const InviteUser = () => {
  const { workspaceId } = useWorkspaceContext();
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [inviteEmail, setInviteEmail] = useState<string>('');
  const { isLoading, refetch: getUserWithEmail } =
    useGetUserForEmail(inviteEmail);

  const { mutate: inviteUser, isLoading: isInviting } =
    useInviteUserToWorkspace(workspaceId);

  const isValidEmail = (email: string | undefined) => {
    if (!email) {
      return false;
    }
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleEmailChange = (
    event: ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    event.preventDefault();
    const email = event.target.value.trim();
    setErrorMsg('');
    setInviteEmail(email);
  };

  const handleError = (error: AxiosError) => {
    const statusCode = error?.response?.status;
    switch (statusCode) {
      case 409:
        setErrorMsg('User already has access.');
        break;
      case 404:
        setErrorMsg('User not found.');
        break;
      default:
        setErrorMsg('An error occurred. Please try again.');
    }
  };

  const handleInviteUser = async () => {
    if (!isValidEmail(inviteEmail)) {
      setErrorMsg('Please enter a valid email address.');
      return;
    }

    try {
      const user = await getUserWithEmail();
      if (user.data) {
        const { email } = user.data;
        inviteUser(email, {
          onSuccess: () => {
            queryClient.invalidateQueries(['workspace', workspaceId, 'users']);
            setInviteEmail('');
          },
          onError: (error: AxiosError) => {
            handleError(error);
          },
        });
      } else {
        setErrorMsg('You can only invite users who have Dashpivot access.');
      }
    } catch (error) {
      setErrorMsg('An error occurred. Please try again.');
    }
  };

  return (
    <div>
      <div className="flex justify-start space-between items-center mb-2">
        <TextInput
          id="invite-user"
          dataTestId="invite-user-input"
          className={clsx(
            'w-57.5 mr-2',
            !isValidEmail(inviteEmail) && inviteEmail
              ? '!border-default-red '
              : ''
          )}
          placeholder="Email address..."
          value={inviteEmail}
          onChange={handleEmailChange}
        />
        {isLoading || isInviting ? (
          <Spinner />
        ) : (
          <Button
            disabled={!isValidEmail(inviteEmail)}
            type="button"
            onClick={handleInviteUser}
          >
            Invite
          </Button>
        )}
        {errorMsg && (
          <p className="ml-2 text-default-red text-sm">{errorMsg}</p>
        )}
      </div>
      <p className="mb-2 text-sm">
        You can only invite users who have Dashpivot access.
      </p>
    </div>
  );
};
