/* eslint-disable jsx-a11y/label-has-associated-control */
import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { Template } from '@site-mate/dashpivot-shared-library';

import { Combobox, TextInput } from '@/components';
import { Spinner } from '@/components/elements/spinner';
import { TriggerProps } from '@/pages/flows/components/flows/Trigger';
import { useFilterByContainsQuery } from '@/pages/flows/hooks/useFilteredItems';
import {
  useRemoveTemplateQuery,
  useTemplate,
} from '@/pages/flows/hooks/useTemplate';
import { useWorkspaceContext } from '@/providers/WorkspaceProvider';
import { nodeService, templateService } from '@/services';

interface ITriggerColumn {
  _id: string;
  label: string;
  value: number;
}

export const DashpivotFormSignedTrigger = ({
  flow,
  onFlowChange,
}: TriggerProps) => {
  const { workspaceId } = useWorkspaceContext();
  const [templateId, setTemplateId] = useState<string>(
    nodeService.getTemplateId(flow)
  );
  const removeTemplateQuery = useRemoveTemplateQuery();

  const updateMetadataTriggerFilter = useCallback(
    (template: Template) => {
      let updatedFlow = nodeService.setPathRegex(
        flow,
        templateService.getFolderPath(template)
      );
      updatedFlow = nodeService.setTemplateId(
        updatedFlow,
        templateId,
        template.isOrganisationTemplate ?? false
      );
      onFlowChange(updatedFlow);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flow, templateId]
  );

  const resetMetadataTriggerFilter = () => {
    let updatedFlow = nodeService.setPathRegex(flow, '');
    updatedFlow = nodeService.setTemplateId(updatedFlow, '');
    onFlowChange(updatedFlow);
  };

  const templateQuery = useTemplate(workspaceId, templateId, {
    onError: resetMetadataTriggerFilter,
  });

  const { data: template } = templateQuery;
  useEffect(() => {
    if (template) {
      updateMetadataTriggerFilter(template);
    }
  }, [updateMetadataTriggerFilter, template]);

  const templateInputId = `template-input-${flow._id}`;
  const columns = template?.columns || [];

  const columnOptions: ITriggerColumn[] = columns.map((column, i) => ({
    _id: String(i + 1),
    label: column.title,
    value: i + 1,
  }));

  const { setQuery, items: filteredColumnOptions } = useFilterByContainsQuery(
    columnOptions,
    'label'
  );
  const selectedTriggerColumn = filteredColumnOptions.find(
    (option) => option.value === nodeService.getTriggerColumn(flow)
  );

  const handleTemplateChange = async (
    event: ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const newTemplateId = event.target.value;
    removeTemplateQuery.removeTemplateId(templateId);
    setTemplateId(newTemplateId);
    if (!newTemplateId) {
      resetMetadataTriggerFilter();
    }
  };

  const handleSetTriggerColumn = useCallback(
    (column: ITriggerColumn) => {
      onFlowChange(nodeService.setTriggerColumn(flow, column.value));
    },
    [flow, onFlowChange]
  );

  const placeholder = templateId
    ? 'Please select a trigger'
    : 'Select a template first';

  return (
    <>
      <div className="mb-2 mt-4 font-bold text-default-text">
        Trigger: Form Signed
      </div>
      <table className="mb-4 w-full border-collapse border border-grey-2 text-left">
        <thead className="bg-grey-2 text-sm">
          <tr>
            <th className="border-slate-600 w-1/2 border p-2 text-default-text">
              Template
            </th>
            <th className="border-slate-600 w-1/2 border p-2 text-default-text">
              Workflow Column Name
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border-slate-500 relative border p-2 text-sm text-default-text">
              <label htmlFor={templateInputId}>
                <span className="block">Template URL ID (required)</span>
                <p className="my-2 text-xs">
                  Go to the template in Dashpivot, then copy the 24-digit string
                  in the URL and insert it here:
                </p>
                <TextInput
                  id={templateInputId}
                  className="mr-2 w-50"
                  name="template-input"
                  dataTestId="template-input"
                  placeholder="Input template ID..."
                  value={templateId}
                  onChange={handleTemplateChange}
                />
              </label>
              {templateQuery.isLoading && (
                <span className="absolute p-1">
                  <Spinner />
                </span>
              )}
              {templateQuery.isError && (
                <span className="block py-1 text-default-red">
                  {templateQuery.error.message}
                </span>
              )}
              {templateQuery.isSuccess && (
                <span className="inline-block py-1">
                  {templateQuery.data?.name}
                </span>
              )}
            </td>

            <td className="border-slate-500 border p-2 align-top text-sm">
              <Combobox
                name="workflow-column"
                dataTestId="workflow-column"
                labelKey="label"
                valueKey="_id"
                width="w-57.5"
                value={selectedTriggerColumn}
                options={filteredColumnOptions}
                placeholder={placeholder}
                disabled={!templateId}
                onInputChange={setQuery}
                onChange={handleSetTriggerColumn}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
