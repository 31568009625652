import { IError, IErrorLevel } from '@site-mate/sitemate-flowsite-shared';

import { capitalize } from '@/common/formatter';

type ErrorListProps = {
  errors: IError[];
};

const toDisplayedError = (error: IErrorLevel) => {
  if (error === IErrorLevel.Warn) {
    return 'Warning';
  }
  return capitalize(error);
};

export const ErrorList = ({ errors }: ErrorListProps) => (
  <ul className="divide-y divide-grey-3">
    {errors.map((error) => {
      const { level, metadata } = error;
      const levelStyle =
        error.level === IErrorLevel.Error
          ? 'text-default-red'
          : 'text-default-orange';
      return (
        <li key={error._id} className="py-2 first:pt-0 last:pb-0">
          <p>
            {level && (
              <>
                <span className={`${levelStyle} font-bold`}>
                  {toDisplayedError(level)}:
                </span>
                &nbsp;
              </>
            )}
            {error.message}
          </p>
          {metadata?.action && (
            <p>
              <span className="font-bold">Action:</span>
              &nbsp;
              {metadata.action}
            </p>
          )}
        </li>
      );
    })}
  </ul>
);
