import { useEffect } from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';

export interface ISignInState {
  path: string;
  searchParams: string;
}

/**
 * Hook for processing the internal oAuth signIn using fusionAuth
 */
export function useInternalSignIn() {
  const auth = useAuth();

  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading
    ) {
      const userState: ISignInState = {
        path: window.location.pathname,
        searchParams: window.location.search,
      };

      auth.signinRedirect({ state: { ...userState } });
    }
  }, [auth]);

  return {
    auth,
    isSigningIn: auth.activeNavigator === 'signinSilent',
    isSigningOut: auth.activeNavigator === 'signoutRedirect',
    isLoading: auth.isLoading,
    error: auth.error,
    isAuthenticated: auth.isAuthenticated,
  };
}
