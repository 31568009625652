import { useCallback, useState } from 'react';

import {
  DataExportTypeNameMapping,
  CsvDataExportTypes,
} from '@site-mate/sitemate-flowsite-shared';

import { Dropdown } from '@/components';
import { nodeService } from '@/services';

import { OutgoingActionProps } from '../../flows';

export const DataExportTypeSelector = ({
  flow,
  onFlowChange,
  node,
}: OutgoingActionProps) => {
  const initialDataExportType = nodeService.getDataExportTypeSetting(
    node?.config
  );

  const [dataExportType, setDataExportType] = useState<CsvDataExportTypes>(
    initialDataExportType ?? CsvDataExportTypes.StandardData // if initialDataExportType is undefined, set to StandardData
  );
  const [isTooltipVisible, setTooltipVisible] = useState<boolean>(false);

  const handleDataExportTypeChange = useCallback(
    (selectedOption: CsvDataExportTypes) => {
      onFlowChange(
        nodeService.setNodeConfig(
          flow,
          nodeService.setDataExportTypeSetting(node.config, selectedOption)
        )
      );
    },
    [flow, node.config, onFlowChange]
  );

  const handleDropdownChange = (value: CsvDataExportTypes) => {
    const exportType = Object.keys(DataExportTypeNameMapping).find(
      (key) =>
        DataExportTypeNameMapping[
          key as keyof typeof DataExportTypeNameMapping
        ] === value
    ) as CsvDataExportTypes;
    setDataExportType(exportType);
    handleDataExportTypeChange(exportType);
  };

  return (
    <div className="flex flex-row items-center text-sm">
      <p className="mr-2">Dashpivot data export type</p>
      <div className="relative mb-[-4px]">
        <span
          className="mr-2 text-xl"
          onMouseEnter={() => setTooltipVisible(true)}
          onMouseLeave={() => setTooltipVisible(false)}
        >
          <i className="cc-icon cc-icon-question" aria-label="help-icon" />
        </span>
        {isTooltipVisible && (
          <div
            className="border-1 absolute left-1/2 top-full z-10 w-[270px] -translate-x-1/2 transform rounded-md border-solid border-black bg-white p-2"
            style={{ boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.2)' }} // once off shadow style for tooltip
          >
            <p>
              Choose between standard export with all columns or autofill export
              with all columns.
            </p>
          </div>
        )}
      </div>
      <Dropdown
        options={Object.values(DataExportTypeNameMapping)}
        value={DataExportTypeNameMapping[dataExportType]}
        onChange={(value: CsvDataExportTypes) => handleDropdownChange(value)}
      />
    </div>
  );
};
