import { useCallback, useEffect, useState } from 'react';

import { INodeType } from '@site-mate/sitemate-flowsite-shared';

import { Button, IButtonColor, IButtonSize, Modal } from '@/components';

import { IFlowOptionItem } from './FlowOptions';

export type SelectFlowModalProps = {
  /**
   * Flow to be created when one selected.
   */
  onAddFlow: (nodeType: INodeType) => void;
  image: string;
  options: IFlowOptionItem[];
};

export const SelectFlowModal = ({
  onAddFlow,
  image,
  options,
}: SelectFlowModalProps) => {
  const [open, setIsOpen] = useState(false);

  const onClose = useCallback(() => {
    setIsOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onAddFlow]);

  /**
   * Resets and closes the modal when create workspace is successful.
   */
  useEffect(() => {
    onClose();
  }, [onClose]);

  return (
    <>
      <Button
        size={IButtonSize.WIDE}
        onClick={() => setIsOpen(true)}
        className="whitespace-nowrap"
      >
        <div>Create flow</div>
      </Button>
      <Modal open={open} onClose={onClose}>
        <Modal.Header>
          <div className="flex flex-col">
            <Modal.Title>Create Flow</Modal.Title>
            <p className="pt-4 text-sm leading-sm-21">
              What would you like to automate?
            </p>
          </div>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Content>
          <div className="flex flex-col space-y-1">
            {options.map((option) => (
              <Button
                key={option.flow}
                color={IButtonColor.WHITE}
                size={IButtonSize.WIDE}
                className="flex h-[62px] space-x-[8px] border-2 border-grey-3 p-2"
                onClick={() => onAddFlow(option.flow)}
              >
                {image && (
                  <img
                    src={image}
                    className="m-0 p-0 w-[40px] h-[40px]"
                    alt="logo"
                  />
                )}
                <div className="m-0 flex w-full flex-grow flex-col p-0">
                  <p className="w-full flex-grow text-left font-normal text-default-text">
                    {option.description}
                  </p>
                  {option.subtext && (
                    <p className="flex-grow text-left text-xs font-normal text-secondary-text">
                      {option.subtext}
                    </p>
                  )}
                </div>
              </Button>
            ))}
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};
