import { useMemo } from 'react';

import { DateUtil } from '@site-mate/sitemate-flowsite-shared';

import { PageLayout } from '@/components';
import {
  ICombinedBillingSettings,
  useAuthenticatedUser,
  useBillingWithWorkspace,
  useFlowsiteBilling,
  useGlobalBilling,
  useSitemateStartAccount,
  useWorkspace,
} from '@/hooks';
import { BillingForm, WorkspaceTable } from '@/pages/settings/components';
import { useWorkspaceContext } from '@/providers/WorkspaceProvider';

export const SettingsPage = () => {
  const { workspaceId, accountId } = useWorkspaceContext();
  const { data: workspace, isLoading: workspaceLoading } =
    useWorkspace(workspaceId);

  const { data: account, isLoading: accountLoading } =
    useSitemateStartAccount(accountId);
  const { data: globalBilling, isLoading: globalBillingLoading } =
    useGlobalBilling(accountId);
  const { data: flowsiteBilling, isLoading: flowsiteBillingLoading } =
    useFlowsiteBilling(accountId);
  const billingData: ICombinedBillingSettings = useMemo(
    () =>
      ({
        ...globalBilling,
        ...flowsiteBilling,
        accountType: account?.accountType,
        billingDate: DateUtil.toISODate(globalBilling?.billingDate),
        trialEndDate: DateUtil.toISODate(flowsiteBilling?.trialEndDate),
        initialSubscriptionDate: DateUtil.toISODate(
          globalBilling?.initialSubscriptionDate
        ),
        flowsiteInitialSubscriptionDate: DateUtil.toISODate(
          flowsiteBilling?.initialSubscriptionDate
        ),
        ssoEnabled: account?.ssoEnabled ? 'On' : 'Off',
      }) as ICombinedBillingSettings,
    [account, globalBilling, flowsiteBilling]
  );

  // TODO: remove when Flowsite billing support is completely dropped for SMS billing
  const { data: billing, isLoading: billingLoading } =
    useBillingWithWorkspace(workspaceId);

  const { data: user, isLoading: isUserLoading } = useAuthenticatedUser();
  const isSuperUser = !isUserLoading && (user?.isSuperUser ?? false);

  const billingInfoLoading =
    accountLoading ||
    globalBillingLoading ||
    flowsiteBillingLoading ||
    billingLoading;

  return (
    <PageLayout>
      <div className="mt-4 space-y-4">
        <WorkspaceTable
          isSuperUser={isSuperUser}
          workspace={workspace}
          loading={workspaceLoading}
          legacyBillingPath={billing?._id}
        />
        <BillingForm
          billingData={billingData}
          editable={isSuperUser && !!accountId}
          loading={billingInfoLoading}
          legacyBillingPath={billing?._id}
        />
      </div>
    </PageLayout>
  );
};
