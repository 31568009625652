import { useMutation, useQuery, useQueryClient } from 'react-query';

import { HttpStatus, IBilling } from '@site-mate/sitemate-flowsite-shared';
import { PathUtility } from '@site-mate/sitemate-global-shared';

import { api } from '@/common/api';
import { IBillingResponse } from '@/models';

function createBilling(workspaceId: string) {
  return api.post(`/workspaces/${workspaceId}/billing`);
}

function getOrCreateBilling(
  workspaceId: string
): () => Promise<IBillingResponse> {
  return async () => {
    const billing = await api
      .get(`/workspaces/${workspaceId}/billing`)
      .catch((error) => ({
        error: {
          status: error.response.status,
          message: error.response.data,
        },
      }));

    if (!billing.error) {
      return billing;
    }

    const { error } = billing;
    if (error.status === HttpStatus.NOT_FOUND) {
      await createBilling(workspaceId);
      return api.get<IBilling>(`/workspaces/${workspaceId}/billing`);
    }
    throw error;
  };
}

export interface NewBillingParams {
  billingPath: string;
  newBilling?: Partial<IBillingResponse> & Pick<IBillingResponse, '_id'>;
}

// use when you are querying by workspaceId, will wait until workspaceId is present
export function useBillingWithWorkspace(workspaceId?: string) {
  return useQuery({
    queryKey: ['workspace', workspaceId, 'billing'],
    queryFn: getOrCreateBilling(workspaceId!),
    enabled: !!workspaceId,
  });
}

export async function updateBilling(billingParam: NewBillingParams) {
  const { billingPath, newBilling } = billingParam;
  const workspaceId = PathUtility.getParent(billingPath);
  const billingId = PathUtility.getLeaf(billingPath);

  return api.patch<IBillingResponse>(
    `/workspaces/${workspaceId}/billing/${billingId}`,
    newBilling
  );
}

export function useUpdateBilling(workspaceId: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateBilling,
    onSuccess: () =>
      queryClient.invalidateQueries(['workspace', workspaceId, 'billing']),
  });
}
