import { FC } from 'react';
import { Control, UseFormRegister } from 'react-hook-form';

import {
  AccountTypes,
  PaymentCurrencyTypes,
  PaymentMethods,
  PaymentTermContexts,
} from '@site-mate/sitemate-global-shared';

import { DateInput, Dropdown } from '@/components';
import { ICombinedBillingSettings } from '@/hooks';

interface IGlobalBillingTableProps {
  control: Control<ICombinedBillingSettings>;
  register: UseFormRegister<ICombinedBillingSettings>;
  editable: boolean;
}

export const GlobalBillingTable: FC<IGlobalBillingTableProps> = ({
  control,
  register,
  editable,
}) => {
  const disabled = !editable;

  const paymentTermDays = Array.from({ length: 61 }, (_, index) =>
    String(index)
  );

  return (
    <>
      <p className="text-sm font-bold">Global Billing</p>
      <p className="text-sm">
        These billing settings apply to all of your product subscriptions (not
        only Flowsite).
      </p>
      <table className="w-full border-collapse border bg-white text-left">
        <tbody>
          <tr className="h-14">
            <td className="border-slate-500 w-1/2 space-x-2 border p-2 align-top text-sm">
              <span className="h-full w-1/2 text-default-text">
                Billing company
              </span>
            </td>
            <td className="border-slate-500 w-1/2 border p-2 text-sm">
              <div className="item-stretch flex w-80 space-x-1">
                <input
                  {...register('billingCompanyName')}
                  disabled={disabled}
                  aria-label="billingCompanyName"
                  type="text"
                  className="w-80 rounded-md border-2 border-grey-3 bg-white p-1.4 text-sm leading-normal disabled:cursor-not-allowed disabled:bg-grey-1 disabled:text-default-text"
                />
              </div>
            </td>
          </tr>
          <tr className="h-14">
            <td className="border-slate-500 w-1/2 space-x-2 border p-2 align-top text-sm">
              <span className="h-full w-1/2 text-default-text">
                Billing address
              </span>
            </td>
            <td className="border-slate-500 w-1/2 border p-2 text-sm">
              <div className="item-stretch flex w-80 space-x-1">
                <input
                  {...register('billingAddress')}
                  disabled={disabled}
                  aria-label="billingAddress"
                  type="text"
                  className="w-80 rounded-md border-2 border-grey-3 bg-white p-1.4 text-sm leading-normal disabled:cursor-not-allowed disabled:bg-grey-1 disabled:text-default-text"
                />
              </div>
            </td>
          </tr>
          <tr className="h-14">
            <td className="border-slate-500 w-1/2 space-x-2 border p-2 align-top text-sm">
              <span className="h-full w-1/2 text-default-text">
                Billing contact
              </span>
            </td>
            <td className="border-slate-500 w-1/2 border p-2 text-sm">
              <div className="item-stretch flex w-80 space-x-1">
                <input
                  {...register('billingContact')}
                  disabled={disabled}
                  aria-label="billingContact"
                  type="text"
                  className="w-80 rounded-md border-2 border-grey-3 bg-white p-1.4 text-sm leading-normal disabled:cursor-not-allowed disabled:bg-grey-1 disabled:text-default-text"
                />
              </div>
            </td>
          </tr>
          <tr className="h-14">
            <td className="border-slate-500 w-1/2 space-x-2 border p-2 align-top text-sm">
              <span className="h-full w-1/2 text-default-text">
                Billing date
              </span>
            </td>
            <td className="border-slate-500 w-1/2 border p-2 text-sm">
              <DateInput
                {...register('billingDate')}
                dataTestId="billingDate-dropdown"
                name="billingDate"
                disabled={disabled}
                className="w-64 p-1.4"
                containerClassName="inline-block"
              />
            </td>
          </tr>
          <tr className="h-14">
            <td className="border-slate-500 w-1/2 space-x-2 border p-2 align-top text-sm">
              <span className="h-full w-1/2 text-default-text">
                Billing initial subscription date
              </span>
            </td>
            <td className="border-slate-500 w-1/2 border p-2 text-sm">
              <DateInput
                {...register('initialSubscriptionDate')}
                dataTestId="initialSubscriptionDate-dropdown"
                name="initialSubscriptionDate"
                disabled={disabled}
                className="w-64 p-1.4"
                containerClassName="inline-block"
              />
            </td>
          </tr>
          <tr className="h-14">
            <td className="border-slate-500 w-1/2 space-x-2 border p-2 align-top text-sm">
              <span className="h-full w-1/2 text-default-text">
                Payment term
              </span>
            </td>
            <td className="border-slate-500 w-1/2 border p-2 text-sm">
              <div className="item-stretch flex w-80 space-x-1">
                <Dropdown
                  placeholder=" "
                  name="paymentTermDay"
                  control={control}
                  disabled={disabled}
                  width="w-16"
                  options={paymentTermDays}
                />
                <Dropdown
                  className="min-w-0 flex-1"
                  name="paymentTermContext"
                  control={control}
                  placeholder="Select..."
                  disabled={disabled}
                  options={Object.values(PaymentTermContexts)}
                />
              </div>
            </td>
          </tr>
          <tr className="h-14">
            <td className="border-slate-500 w-1/2 space-x-2 border p-2 align-top text-sm">
              <span className="h-full w-1/2 text-default-text">
                Payment method
              </span>
            </td>
            <td className="border-slate-500 w-1/2 border p-2 text-sm">
              <Dropdown
                name="paymentMethod"
                control={control}
                placeholder="Select..."
                disabled={disabled}
                options={Object.values(PaymentMethods)}
                width="w-80"
              />
            </td>
          </tr>
          <tr className="h-14">
            <td className="border-slate-500 w-1/2 space-x-2 border p-2 align-top text-sm">
              <span className="h-full w-1/2 text-default-text">Currency</span>
            </td>
            <td className="border-slate-500 w-1/2 border p-2 text-sm">
              <Dropdown
                name="currency"
                control={control}
                placeholder="Select..."
                disabled={disabled}
                options={Object.values(PaymentCurrencyTypes)}
                width="w-80"
              />
            </td>
          </tr>
          <tr className="h-14">
            <td className="border-slate-500 w-1/2 space-x-2 border p-2 align-top text-sm">
              <span className="h-full w-1/2 text-default-text">
                Account type
              </span>
            </td>
            <td className="border-slate-500 w-1/2 border p-2 text-sm">
              <Dropdown
                name="accountType"
                control={control}
                disabled={disabled}
                options={Object.values(AccountTypes)}
                width="w-80"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
