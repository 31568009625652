import { SigninResponse } from 'oidc-client-ts';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';

import {
  CreateConnectionMetadata,
  ErrorUtil,
  IConnection,
  IConnectionType,
  IIntegration,
  IntegrationType,
} from '@site-mate/sitemate-flowsite-shared';
import { Products } from '@site-mate/sitemate-global-shared';

import { api } from '@/common/api';
import { useIntegration } from '@/hooks/useIntegration';
import { useWorkspaceContext } from '@/providers/WorkspaceProvider';

export interface IConnectionFilters {
  integrationId?: string;
}

function getConnections(
  workspaceId: string,
  filters: IConnectionFilters = {}
): () => Promise<IConnection[]> {
  return () =>
    api.get(`/workspaces/${workspaceId}/connections`, {
      params: {
        ...filters,
      },
    });
}

export function useConnections(workspaceId: string) {
  return useQuery({
    queryKey: ['workspace', workspaceId, 'connections'],
    queryFn: getConnections(workspaceId),
  });
}

export function useConnectionsWithType(integrationType: IntegrationType) {
  const { workspaceId } = useWorkspaceContext();
  const integration = useIntegration(integrationType);

  if (integration.isSuccess && !integration.data) {
    throw new Error(
      `Unable to get ${integrationType} connection: integrationId required`
    );
  }

  return useQuery({
    queryKey: ['workspace', workspaceId, 'connections', integrationType],
    queryFn: getConnections(workspaceId, {
      integrationId: integration.data?._id,
    }),
    enabled: integration.isSuccess,
  });
}

type CreateConnectionParams = {
  workspaceId: string;
};

type CreateOauthConnectionParams = CreateConnectionParams & {
  integration: UseQueryResult<IIntegration | undefined, unknown>;
  response: SigninResponse;
  metadata?: CreateConnectionMetadata;
};

export function useCreateOAuthConnection() {
  return useMutation({
    mutationFn: ({
      response,
      integration,
      workspaceId,
      metadata,
    }: CreateOauthConnectionParams) => {
      if (integration.isError) {
        throw new Error(`Unable to create connection: ${integration.error}`);
      }
      if (!integration.data) {
        throw new Error('Integration id not yet loaded');
      }

      const connectionParams = {
        type: IConnectionType.OAuth,
        credential: {
          accessToken: response.access_token,
          refreshToken: response.refresh_token,
          scope: response.scope,
          expiresAt: response.expires_at,
        },
        integrationId: integration.data._id,
        metadata,
      };

      return api.post(
        `workspaces/${workspaceId}/connections`,
        connectionParams
      ) as Promise<IConnection>;
    },
    onError: (error) => {
      const errorMessage = ErrorUtil.handleErrorMessage(
        error,
        `connection failed`
      );
      throw new Error(errorMessage);
    },
  });
}

export function useCreateDashpivotBasicConnection(workspaceId: string) {
  const queryClient = useQueryClient();
  const { data: dashpivotIntegration } = useIntegration(
    IntegrationType.DASHPIVOT
  );

  return useMutation({
    mutationFn: () => {
      if (!dashpivotIntegration) {
        throw new Error('Integration not yet loaded');
      }

      const connectionParams = {
        type: IConnectionType.Basic,
        name: Products.Dashpivot,
        integrationId: dashpivotIntegration._id,
        credential: {
          username: '',
          password: '',
        },
      };

      return api.post(
        `workspaces/${workspaceId}/connections`,
        connectionParams
      );
    },
    onError: (error) => {
      const errorMessage = ErrorUtil.handleErrorMessage(
        error,
        `create connection failed`
      );
      throw new Error(errorMessage);
    },
    onSuccess: () =>
      queryClient.invalidateQueries(['workspace', workspaceId, 'connections']),
  });
}

export function useDeleteConnection(workspaceId: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (connectionPath: string) => {
      const connectionId = connectionPath.split('/').pop();

      return api.delete(
        `/workspaces/${workspaceId}/connections/${connectionId}`
      );
    },
    onSuccess: () =>
      queryClient.invalidateQueries(['workspace', workspaceId, 'connections']),
  });
}
