import { FC } from 'react';
import { Control, UseFormRegister } from 'react-hook-form';

import {
  BillingCycles,
  FlowsitePlanTypes,
  SubscriptionStatuses,
  SubscriptionTerms,
} from '@site-mate/sitemate-global-shared';

import { DateInput, Dropdown } from '@/components';
import { ICombinedBillingSettings } from '@/hooks/useSitemateStartBilling';

interface IFlowsiteBillingTableProps {
  control: Control<ICombinedBillingSettings>;
  register: UseFormRegister<ICombinedBillingSettings>;
  editable: boolean;
}

export const FlowsiteBillingTable: FC<IFlowsiteBillingTableProps> = ({
  control,
  register,
  editable,
}) => {
  const disabled = !editable;

  return (
    <>
      <p className="text-sm font-bold">Flowsite Billing</p>
      <p className="text-sm">
        These billing settings apply to your Flowsite subscription only.
      </p>
      <table className="w-full border-collapse border bg-white text-left">
        <tbody>
          <tr className="h-14">
            <td className="border-slate-500 w-1/2 space-x-2 border p-2 align-top text-sm">
              <span className="h-full w-1/2 text-default-text">
                Flowsite trial end date
              </span>
            </td>

            <td className="border-slate-500 w-1/2 border p-2 text-sm">
              <DateInput
                {...register('trialEndDate')}
                dataTestId="trialEndDate-dropdown"
                disabled={disabled}
                className="w-64 p-1.4"
                containerClassName="inline-block"
                name="trialEndDate"
              />
            </td>
          </tr>
          <tr className="h-14">
            <td className="border-slate-500 w-1/2 space-x-2 border p-2 align-top text-sm">
              <span className="h-full w-1/2 text-default-text">
                Flowsite plan type
              </span>
            </td>
            <td className="border-slate-500 w-1/2 border p-2 text-sm">
              <Dropdown
                name="planType"
                control={control}
                disabled={disabled}
                options={Object.values(FlowsitePlanTypes)}
                width="w-80"
              />
            </td>
          </tr>
          <tr className="h-14">
            <td className="border-slate-500 w-1/2 space-x-2 border p-2 align-top text-sm">
              <span className="h-full w-1/2 text-default-text">
                Flowsite subscription status
              </span>
            </td>
            <td className="border-slate-500 w-1/2 border p-2 text-sm">
              <Dropdown
                name="subscriptionStatus"
                control={control}
                disabled={disabled}
                options={Object.values(SubscriptionStatuses)}
                width="w-80"
              />
            </td>
          </tr>
          <tr className="h-14">
            <td className="border-slate-500 w-1/2 space-x-2 border p-2 align-top text-sm">
              <span className="h-full w-1/2 text-default-text">
                Flowsite initial subscription date
              </span>
            </td>
            <td className="border-slate-500 w-1/2 border p-2 text-sm">
              <DateInput
                {...register('flowsiteInitialSubscriptionDate')}
                dataTestId="flowsiteInitialSubscriptionDate-dropdown"
                name="flowsiteInitialSubscriptionDate"
                disabled={disabled}
                className="w-64 p-1.4"
                containerClassName="inline-block"
              />
            </td>
          </tr>
          <tr className="h-14">
            <td className="border-slate-500 w-1/2 space-x-2 border p-2 align-top text-sm">
              <span className="h-full w-1/2 text-default-text">
                Flowsite subscription term
              </span>
            </td>
            <td className="border-slate-500 w-1/2 border p-2 text-sm">
              <Dropdown
                name="subscriptionTerm"
                control={control}
                placeholder="Select..."
                disabled={disabled}
                options={Object.values(SubscriptionTerms)}
                width="w-80"
              />
            </td>
          </tr>
          <tr className="h-14">
            <td className="border-slate-500 w-1/2 space-x-2 border p-2 align-top text-sm">
              <span className="h-full w-1/2 text-default-text">
                Flowsite billing cycle
              </span>
            </td>
            <td className="border-slate-500 w-1/2 border p-2 text-sm">
              <Dropdown
                name="billingCycle"
                control={control}
                placeholder="Select..."
                disabled={disabled}
                options={Object.values(BillingCycles)}
                width="w-80"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
