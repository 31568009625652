import { Frequency, RRule } from 'rrule';

import {
  FlowUtil,
  IFlow,
  IHostedFileFlowMetadataRequest,
  INodeType,
  IntegrationType,
} from '@site-mate/sitemate-flowsite-shared';

import { getStartingDate } from '@/common/datetime.util';

import { credentialsService } from '..';

const DEFAULT_TRIGGER_SCHEDULE_INTERVAL = 24; // 24 hours

export const defaultAccessUrl = 'https://dev.flowsite.com';
export const defaultTriggerScheduleRule = new RRule({
  freq: Frequency.HOURLY,
  interval: DEFAULT_TRIGGER_SCHEDULE_INTERVAL,
  dtstart: getStartingDate(),
});

export const hostedFileIntegrationTypeMap: Record<string, string> = {
  [INodeType.POWERBI_CONNECTOR_V1]: IntegrationType.POWERBI,
  [INodeType.EXCEL_CONNECTOR_V1]: IntegrationType.EXCEL,
};

export const generateHostedFileMetadata = (
  flow: IFlow
): IHostedFileFlowMetadataRequest => {
  const { hostname } = window.location;

  if (
    !FlowUtil.hasAnyNodeType(flow, [
      INodeType.POWERBI_CONNECTOR_V1,
      INodeType.EXCEL_CONNECTOR_V1,
    ])
  ) {
    throw new Error(
      `${generateHostedFileMetadata.name}: flow does not contain a supported node type`
    );
  }

  const integrationType = hostedFileIntegrationTypeMap[flow.nodes[0].type];

  const baseUrl =
    hostname && hostname !== 'localhost'
      ? window.location.origin
      : defaultAccessUrl;

  return {
    triggerFilter: {
      templateId: '',
    },
    triggerSchedule: {
      rule: defaultTriggerScheduleRule.toString(),
    },
    credentials: {
      username: credentialsService.generateUsername(),
      password: credentialsService.generatePassword(),
    },
    accessUrl: `${baseUrl}/integrations/${integrationType}/outputs/${flow.nodes[0]._id}`,
  };
};
