import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  RowData,
  useReactTable,
} from '@tanstack/react-table';
import { OidcClient } from 'oidc-client-ts';

import {
  IConnection,
  IIntegration,
  IntegrationType,
  Maybe,
} from '@site-mate/sitemate-flowsite-shared';

import { IExternalSignInUserState } from '@/auth';
import { getOAuthConfig } from '@/auth/oauth-callbacks';
import { xeroConfig } from '@/auth/xero.config';
import { Spinner } from '@/components';
import { useConnections, useDeleteConnection } from '@/hooks/useConnections';
import { useIntegrationsMap } from '@/hooks/useIntegrations';
import { useWorkspaceContext } from '@/providers/WorkspaceProvider';

declare module '@tanstack/react-table' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends RowData> {
    integrationsMap: Maybe<Map<IntegrationType, IIntegration | undefined>>;
    deleteConnection: (connectionId: string) => void;
    reconnectConnection: (connectionId: string) => void;
  }
}

const columnHelper = createColumnHelper<IConnection>();

const columns = [
  columnHelper.accessor('_id', {
    id: '_id',
    header: 'System',
    cell: ({ row, table }) => {
      const { integrationsMap } = table.options.meta!;
      return (
        Array.from(integrationsMap?.values() ?? []).find(
          (integration) => integration?._id === row.original.integrationId
        )?.name ?? 'Unknown'
      );
    },
  }),
  columnHelper.accessor('status', {
    id: 'status',
    header: 'Status',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('name', {
    id: 'name',
    header: 'Name',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('status', {
    id: 'actions',
    header: 'Actions',
    cell: ({ getValue, row, table }) => {
      const { integrationsMap, deleteConnection, reconnectConnection } =
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        table.options.meta!;

      const handleDisconnect = async (connectionId: string) => {
        deleteConnection(connectionId);
      };

      const handleReconnect = async (connectionId: string) => {
        reconnectConnection(connectionId);
      };

      // Only Xero supports reconnecting at the moment
      const isReconnectSupported =
        integrationsMap?.get(IntegrationType.XERO)?._id ===
        row.original.integrationId;

      return getValue() !== 'connected' && isReconnectSupported ? (
        <button
          onClick={() => handleReconnect(row.getValue('_id'))}
          type="button"
          className="bg-transparent underline"
        >
          Reconnect
        </button>
      ) : (
        <button
          onClick={() => handleDisconnect(row.getValue('_id'))}
          type="button"
          className="bg-transparent underline"
        >
          Remove connection
        </button>
      );
    },
  }),
];

export const handleConnect = async (
  type: IntegrationType,
  workspaceId: string
) => {
  const config = getOAuthConfig(type);

  if (!config) {
    throw new Error(`No OAuth config found for integration type: ${type}`);
  }

  const oidcClient = new OidcClient(config);
  const state: IExternalSignInUserState = { workspaceId };

  const signinRequest = await oidcClient.createSigninRequest({ state });

  // Redirect to the external auth provider url
  window.location.href = signinRequest.url;
};

export const Connections = () => {
  const { workspaceId } = useWorkspaceContext();

  const connectionsQuery = useConnections(workspaceId);
  const { data: integrationsMap } = useIntegrationsMap();
  const deleteConnection = useDeleteConnection(workspaceId);

  const connections = connectionsQuery.data || [];

  const table = useReactTable({
    data: connections,
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      integrationsMap,
      deleteConnection: (connectionId: string) => {
        deleteConnection.mutate(connectionId, {
          onSuccess: () => connectionsQuery.refetch(),
          // TODO display errors to users using onError callback
        });
      },
      reconnectConnection: async (connectionId: string) => {
        const oidcClient = new OidcClient(xeroConfig);
        const state: IExternalSignInUserState = { workspaceId, connectionId };
        const signinRequest = await oidcClient.createSigninRequest({ state });

        // Redirect to the external auth provider url
        window.location.href = signinRequest.url;
      },
    },
  });

  return (
    <div className="w-full">
      <h2 className="mb-2 mt-4 text-xl font-bold">Connections</h2>
      {connectionsQuery.isLoading ? (
        <Spinner />
      ) : (
        <table className="border-slate-500 mb-4 w-full table-fixed border-collapse border text-left">
          <thead className="bg-grey-2 text-sm">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    className="border-slate-600 border px-2 py-2"
                    key={header.id}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="bg-white">
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    className="border-slate-500 border p-2 text-sm"
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};
