import { FC } from 'react';
import { useAuth } from 'react-oidc-context';
import { useQueryClient } from 'react-query';
import {
  Route,
  Routes,
  useLocation,
  useMatch,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import FlowsiteLogo from '@/assets/flowsite-logo.svg';
import { fusionAuthConfig } from '@/auth/fusionAuth.config';
import { Button } from '@/components';
import { IButtonColor } from '@/components/elements/button/Button.variants';
import { NavBar } from '@/components/navigation/navbar';
import { useAuthenticatedUser } from '@/hooks/useUsers';
import {
  ConnectionsPage,
  FlowsPage,
  NotFound,
  RunsPage,
  UsersPage,
  SettingsPage,
} from '@/pages';
import { CreateWorkspaceModal } from '@/pages/navigation/components/CreateWorkspaceModal';
import { WorkspaceSwitcher } from '@/pages/navigation/components/WorkspaceSwitcher';
import { SignUpPage } from '@/pages/signup/SignUpPage';
import { WorkspaceProvider } from '@/providers/WorkspaceProvider';

/**
 * Generate a workspace path or a hash if no workspaceId is specified
 */
const generateWorkspacePath = (
  workspaceId: string | undefined,
  path: string = ''
) => (workspaceId ? `/workspaces/${workspaceId}${path}` : '#');

const WorkspaceRoute = ({ workspaceId }: { workspaceId?: string }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { params: forbiddenParams } = useMatch('forbidden') || {};
  const isForbidden = !!forbiddenParams;

    // Check if freeTrialExpired param is provided
    const isFreeTrialExpired = searchParams.get('trialExpired') === 'true';

  const handleInvalidWorkspace = () => {
    navigate('/forbidden');
  };

  // Called when the workspace provider redirects to the default workspace
  const handleAutoRedirectWorkspace = (newWorkspaceId: string) => {
    if (!isForbidden) {
      navigate(`/workspaces/${newWorkspaceId}`);
    }
  };

  // Called when the user has no workspaces and is to be redirected to the sign up page
  const handleSignUpRedirect = () => {
    navigate('/signup');
  };

  const handleExpiredTrialRedirect = () => {
    if(!isFreeTrialExpired) {
      navigate(`/workspaces/${workspaceId}?trialExpired=true`);
    }
  };

  return (
    <WorkspaceProvider
      workspaceId={workspaceId}
      onInvalidWorkspace={handleInvalidWorkspace}
      onChangeWorkspace={handleAutoRedirectWorkspace}
      onSignUpRedirect={handleSignUpRedirect}
      onFreeTrialExpired={handleExpiredTrialRedirect}
      enabled={!isForbidden}
    >
      <Routes>
        <Route path="workspaces/:workspaceId">
          <Route index element={<ConnectionsPage />} />
          <Route path="flows" element={<FlowsPage />} />
          <Route path="runs" element={<RunsPage />} />
          <Route path="users" element={<UsersPage />} />
          <Route path="settings" element={<SettingsPage />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </WorkspaceProvider>
  );
};

export const PrivateRoutes: FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const auth = useAuth();
  const user = useAuthenticatedUser();

  // Keep track of the current page
  const { params: workspaceParams } =
    useMatch('workspaces/:workspaceId/*') || {};
  const workspaceId = workspaceParams?.workspaceId;

  const path = workspaceParams?.['*'] ?? '';

  const params = useLocation();
  const [searchParams] = useSearchParams();

  // Hide the content of the nav bar for normal sign up flow
  const isReferredSignUp = searchParams.get('reference') !== null;
  const hideNavBarContent = params.pathname === '/signup' && !isReferredSignUp;

  // Called when a user changes the active workspace
  const handleSwitchWorkspace = (
    targetWorkspaceId: string,
    resetPath: boolean = false
  ) => {
    // Remove all queries related to workspace
    queryClient.removeQueries({ queryKey: 'sitemate-account' });

    const targetPath = !resetPath && path ? `/${path}` : '';

    navigate(`workspaces${targetWorkspaceId}${targetPath}`);
  };

  const onLogout = async () => {
    await auth.signoutRedirect({
      post_logout_redirect_uri: fusionAuthConfig.redirect_uri,
    });
  };

  return (
    <div className="flex h-screen flex-col overflow-hidden text-default-text">
      <NavBar contentHidden={hideNavBarContent}>
        <NavBar.Logo heading="Flowsite" source={FlowsiteLogo} />
        <NavBar.NavLink
          icon="cc-icon cc-icon-puzzle text-xl"
          url={generateWorkspacePath(workspaceId)}
          disabled={!workspaceId}
        >
          Connections
        </NavBar.NavLink>
        <NavBar.NavLink
          icon="cc-icon cc-icon-roadmap text-xl"
          url={generateWorkspacePath(workspaceId, '/flows')}
          disabled={!workspaceId}
        >
          Flows
        </NavBar.NavLink>
        <NavBar.NavLink
          icon="cc-icon cc-icon-logs text-xl"
          url={generateWorkspacePath(workspaceId, '/runs')}
          disabled={!workspaceId}
        >
          Runs
        </NavBar.NavLink>
        <NavBar.NavLink
          icon="cc-icon cc-icon-users text-xl"
          url={generateWorkspacePath(workspaceId, '/users')}
          disabled={!workspaceId}
        >
          Users
        </NavBar.NavLink>
        <NavBar.NavLink
          icon="cc-icon cc-icon-gear text-xl"
          url={generateWorkspacePath(workspaceId, '/settings')}
          disabled={!workspaceId}
        >
          Settings
        </NavBar.NavLink>
        {!hideNavBarContent && (
          <NavBar.RightItems>
            {user.data && user.data.isSuperUser && (
              <CreateWorkspaceModal
                onWorkspaceCreated={(createdWorkspaceId) =>
                  handleSwitchWorkspace(createdWorkspaceId, true)
                }
              />
            )}
            <WorkspaceSwitcher
              workspaceId={workspaceId}
              onChangeWorkspace={handleSwitchWorkspace}
            />
            <Button type="submit" onClick={onLogout} color={IButtonColor.WHITE}>
              <i
                className="cc-icon cc-icon-logout text-xl"
                aria-hidden="true"
              />
              Logout
            </Button>
          </NavBar.RightItems>
        )}
      </NavBar>

      <Routes>
        <Route path="signup" element={<SignUpPage />} />
        <Route path="forbidden" element={<NotFound />} />
        <Route
          path="*"
          element={<WorkspaceRoute workspaceId={workspaceId} />}
        />
      </Routes>
    </div>
  );
};
