import axios, { AxiosRequestConfig } from 'axios';

import { userManager } from '@/auth/userManager';

export const globalAPI = axios.create({
  baseURL: import.meta.env.PUBLIC_GLOBAL_API_URL,
});

const authInterceptor = async (requestConfig: AxiosRequestConfig) => {
  const config = { ...requestConfig };
  const user = await userManager.getUser();
  const accessToken = user?.access_token;

  if (accessToken) {
    config.headers = {
      ...config.headers,
      authorization: accessToken,
      authtype: 'fusionauth',
    };
  }

  return config;
};

globalAPI.interceptors.request.use(authInterceptor);

globalAPI.interceptors.response.use((response) => response.data);
