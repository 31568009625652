export const hoursInDay = 24;
export const DEFAULT_TIME_IN_HOURS = 0; // 12am

export const toISOString = (date?: string | null) =>
  date ? new Date(date).toISOString() : null;

/**
 * Compute a date object with the following default values: year: 2023, month: 0, day: 1
 * The hour is set to the provided value, otherwise it is set to the default value
 * @param hours - The hour of the day to set the date to. Defaults to the default time in hours
 * @returns - A date object with the hour set to the provided value or the default value
 */
export const getStartingDate = (hours: number = DEFAULT_TIME_IN_HOURS) =>
  new Date(Date.UTC(2023, 0, 1, hours, 0, 0));

export const toHumanReadableUTCTime = (hour: number) =>
  `${hour.toString().padStart(2, '0')}:00 UTC`;

/**
 * Generate a hour options array, which can be used for dropdowns
 * @returns - Array of hour options
 */
export const getHourOptions = () =>
  [...Array(hoursInDay).keys()].map((hour) => ({
    _id: hour.toString(),
    label: toHumanReadableUTCTime(hour),
    value: hour.toString(),
  }));

/**
 * Generate a interval options array, which can be used for dropdowns
 * TODO: Consolidate this with interval.service once this gets merged into FS 24.2.0
 * @returns - Array of interval options
 */
export const getIntervalOptions = () => [
  {
    id: '24',
    label: 'Day',
    value: 24,
  },
  {
    id: '12',
    label: '12 hours',
    value: 12,
  },
  {
    id: '4',
    label: '4 hours',
    value: 4,
  },
  {
    id: '1',
    label: '1 hour',
    value: 1,
  },
];
