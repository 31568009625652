import { INodeType } from '@site-mate/sitemate-flowsite-shared';

import { TriggerProps } from '@/pages/flows/components/flows/Trigger';
import { ScheduledTrigger } from '@/pages/flows/components/nodes/triggers/ScheduledTrigger';
import { DashpivotFormSignedTrigger } from '@/pages/flows/components/nodes/xero/DashpivotFormSignedTrigger';

export const TriggerComponents: Record<
  INodeType,
  ({ flow, onFlowChange }: TriggerProps) => JSX.Element
> = {
  [INodeType.XERO_TIMESHEET_V1]: DashpivotFormSignedTrigger,
  [INodeType.XERO_INVOICE_V1]: DashpivotFormSignedTrigger,
  [INodeType.XERO_BILL_V1]: DashpivotFormSignedTrigger,
  [INodeType.POWERBI_CONNECTOR_V1]: ScheduledTrigger,
  [INodeType.EXCEL_CONNECTOR_V1]: ScheduledTrigger,
  [INodeType.QUICKBOOKS_INVOICE_V1]: DashpivotFormSignedTrigger,
  [INodeType.MICROSOFT_SHAREPOINT_FORM_UPLOAD_V1]: DashpivotFormSignedTrigger,
};
