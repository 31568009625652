import { SigninResponse } from 'oidc-client-ts';
import { useMutation } from 'react-query';

import { IntegrationType } from '@site-mate/sitemate-flowsite-shared';

import { api } from '@/common/api';

type TokenExchangeParams = {
  integrationType: IntegrationType;
  code: string;
  redirectUri: string;
};

export function useTokenExchange() {
  return useMutation({
    mutationFn: ({
      integrationType,
      code,
      redirectUri,
    }: TokenExchangeParams) => {
      const params = { code, redirect_uri: redirectUri };

      return api.post<SigninResponse>(
        `/integrations/${integrationType}/token`,
        {},
        { params }
      );
    },
  });
}
