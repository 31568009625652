import {
  IGenerateRandomStringOptions,
  generateRandomString,
} from '../common/random.util';

export const generateUsername = (
  length: number = 8,
  options: IGenerateRandomStringOptions = {
    hasUppercase: true,
    hasNumbers: true,
    hasSymbols: false,
  }
): string => generateRandomString(length, options);

/**
 * Generates a random password
 * @param {number} length The length of the password. Defaults to 16 characters.
 * @param {IGenerateRandomStringOptions} options The options for the password. Defaults to having uppercase letters, numbers, and symbols.
 * @returns {string} A random password
 */
export const generatePassword = (
  length: number = 16,
  options: IGenerateRandomStringOptions = {
    hasUppercase: true,
    hasNumbers: true,
    hasSymbols: true,
  }
): string => generateRandomString(length, options);
