import { useEffect } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { HelpArticles } from '@/common';
import { Button, IButtonColor, Spinner } from '@/components';
import { useGetOrCreateWorkspaceForAccount } from '@/hooks';
import { CreateWorkspaceErrorMessages } from '@/models';

import { ErrorMessage } from './ErrorMessage';

export type SignUpReferralProps = {
  reference: string;
  sitemateStartAccountId?: string;
  referenceWorkspaceId?: string;
};

export const SignUpReferral = ({
  reference,
  sitemateStartAccountId,
  referenceWorkspaceId,
}: SignUpReferralProps) => {
  const navigate = useNavigate();

  const workspace = useGetOrCreateWorkspaceForAccount(sitemateStartAccountId, {
    reference,
    referenceWorkspaceId,
  });

  useEffect(() => {
    if (workspace.data?.workspace) {
      const search = workspace.data.isCreated ? '?firstLogin=true' : undefined;
      const targetWorkspaceId = workspace.data.workspace._id;
      navigate({
        pathname: `/workspaces${targetWorkspaceId}`,
        search,
      });
    }
  }, [workspace.data, navigate]);

  return (
    <>
      {!sitemateStartAccountId && (
        <ErrorMessage
          title="Unable to process your request"
          message="The URL you are trying to access is invalid."
        />
      )}

      {workspace.error &&
        (workspace.error.message ===
        CreateWorkspaceErrorMessages.WorkspaceAlreadyExists ? (
          <ErrorMessage icon="cc-icon-users" title="Please contact your admin">
            <p>
              Please contact your company or site administrator and ask them to
              invite you to the existing Flowsite workspace using your work
              email address.
            </p>
            <p className="mt-4">
              You can send them this article on how to invite you.
            </p>
            <Button
              as="a"
              className="mt-4"
              color={IButtonColor.BLUE}
              href={HelpArticles.InvitingUsersToFlowsite}
            >
              How to invite users
            </Button>
          </ErrorMessage>
        ) : (
          <ErrorMessage
            title="Unable to process your request"
            message={String(workspace.error.message)}
          />
        ))}

      {workspace.isLoading && (
        <div className="m-auto">
          <Spinner />
        </div>
      )}
    </>
  );
};
