import { useQuery } from 'react-query';

import { api } from '@/common/api';
import { IRequestParameterItem } from '@/pages/flows/types';

function getEditorOptions(
  workspaceId: string,
  connectionId?: string,
  optionSourceKey?: string
) {
  return async (): Promise<IRequestParameterItem[]> => {
    if (!connectionId) return Promise.resolve([]);
    const req = await api.post(`workspaces/${workspaceId}/editor/options`, {
      connectionId,
      optionSourceKey,
      parameters: {
        routeParameters: [],
        queryParameters: [],
        headers: [],
      },
    });
    if (!req.data) return Promise.resolve([]);
    return req.data;
  };
}

export function useEditorOptions(
  workspaceId: string,
  connectionId?: string,
  optionSourceKey?: string
) {
  return useQuery({
    queryKey: [
      'workspace',
      workspaceId,
      'editorOptions',
      connectionId,
      optionSourceKey,
    ],
    queryFn: getEditorOptions(workspaceId, connectionId, optionSourceKey),
    enabled: !!connectionId && !!optionSourceKey,
  });
}
