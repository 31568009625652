import { DateUtil, Maybe } from '@site-mate/sitemate-flowsite-shared';
import { Countries, TimezoneMapping } from '@site-mate/sitemate-global-shared';

/**
 * Retrieves the user's timezone
 * @returns The user's timezone (e.g. 'Australia/Sydney')
 */
export function getUserTimezone(): string {
  return DateUtil.guessUserTimezone();
}

/**
 * Retrieves the user's continent code based on their timezone
 * @returns The user's continent code (e.g. 'NA')
 */
export function getUserContinentCode(): Maybe<string> {
  const localTimezone = getUserTimezone();
  const timezoneMapping = TimezoneMapping[localTimezone];
  const userCountry = Countries.find(
    (country) => country.value === timezoneMapping?.mcode
  );

  return userCountry?.continentCode;
}
