/* eslint-disable jsx-a11y/label-has-associated-control */
import { ChangeEvent, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { TextInput } from '@/components';
import { Spinner } from '@/components/elements/spinner';
import { CopyToClipboardTextInput } from '@/components/elements/text-input/CopyToClipboardTextInput';
import { OutgoingActionProps } from '@/pages/flows/components/flows';
import { useFlowCredentials } from '@/pages/flows/hooks';
import {
  useRemoveTemplateQuery,
  useTemplate,
} from '@/pages/flows/hooks/useTemplate';
import { useWorkspaceContext } from '@/providers/WorkspaceProvider';
import { nodeService } from '@/services';
import { NEW_FLOW_ID } from '@/services/node.service';

import { DataExportTypeSelector } from './DataExportTypeSelector';

export const ExcelConnectorActionNode = ({
  flow,
  onFlowChange,
  node,
}: OutgoingActionProps) => {
  const { workspaceId } = useWorkspaceContext();
  const templateId = nodeService.getTemplateId(flow);
  const template = useTemplate(workspaceId, templateId);
  const credentials = useFlowCredentials(flow._id);
  const removeTemplateQuery = useRemoveTemplateQuery();
  const flowId = flow._id;

  const templateInputId = `template-input-${flowId}`;
  const handleTemplateChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
      const inputTemplateId = event.target.value;
      removeTemplateQuery.removeTemplateId(templateId);
      onFlowChange(nodeService.setTemplateId(flow, inputTemplateId));
    },
    [removeTemplateQuery, templateId, onFlowChange, flow]
  );

  const destinationFields = [
    { key: 'url', label: 'URL', value: flow?.metadata?.accessUrl ?? '' },
    {
      key: 'username',
      label: 'Username',
      value: flow?.metadata?.credentials?.username ?? '',
    },
    {
      key: 'password',
      label: 'Password',
      value: credentials?.password ?? flow?.metadata?.credentials?.password,
      hidden: flowId !== NEW_FLOW_ID && !credentials?.password,
    },
  ];

  return (
    <>
      <div className="mb-2 mt-4 font-bold">
        Action: Create Excel hosted source
      </div>
      <p className="mb-2 text-sm">
        This action will generate a hosted CSV with the latest forms data from
        Dashpivot (max 10,000 forms), which Excel can use as a source.
      </p>
      <DataExportTypeSelector
        flow={flow}
        node={node}
        onFlowChange={onFlowChange}
      />
      <table className="mb-4 mt-2 w-full table-fixed border-collapse border text-left">
        <thead className="bg-grey-2 text-sm">
          <tr>
            <th className="border-slate-600 border p-2">Source</th>
            <th className="border-slate-600 border p-2">Destination</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border-slate-500 border p-2 align-text-top text-sm">
              <label className="space-y-2" htmlFor={templateInputId}>
                <p>Template URL ID (required)</p>
                <p className="text-xs">
                  Go to the template in Dashpivot, then copy the 24-digit string
                  in the URL and insert it here:
                </p>
                <TextInput
                  id={templateInputId}
                  className="mr-2 w-50"
                  name="template-input"
                  dataTestId="template-input"
                  placeholder="Input template ID..."
                  value={templateId}
                  onChange={handleTemplateChange}
                />
              </label>
              {template.isLoading && (
                <>
                  <span>Loading template</span>
                  <Spinner />
                </>
              )}
              {template.isError && (
                <span className="text-default-red">
                  {template.error.message}
                </span>
              )}
              {template.isSuccess && <span>{template.data?.name}</span>}
            </td>
            <td className="border-slate-500 space-y-1 border p-2 text-sm">
              <p>
                You will need to use the following values to connect Excel to
                the hosted source:{' '}
                <Link
                  className="text-default-text"
                  to="https://intercom.help/dashpivot/en/articles/8636278-microsoft-excel-integration"
                  target="_blank"
                >
                  Learn more
                </Link>
              </p>
              <ul className="space-y-2">
                {destinationFields.map(({ key, label, value, hidden }) => (
                  <li key={key}>
                    <label
                      htmlFor={key}
                      className="flex items-center space-x-2"
                    >
                      <span className="w-20">{`${label}:`}</span>
                      {!hidden ? (
                        <CopyToClipboardTextInput
                          name={key}
                          value={value}
                          width="grow"
                          placeholder="Available after saving"
                          disabled
                        />
                      ) : (
                        <span className="text-grey-5">Not available</span>
                      )}
                    </label>
                  </li>
                ))}
              </ul>
              <p>
                <span className="font-bold">Note:</span> the password is only
                visible when the flow is first created. It will not be visible
                later, so we recommend making a record of this for future use.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
