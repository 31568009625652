import { Combobox as HCombobox, Transition } from '@headlessui/react';
import { Float } from '@headlessui-float/react';
import clsx from 'clsx';
import { Fragment, useEffect, useState } from 'react';
import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  PathValue,
} from 'react-hook-form';

import { useDebounce } from '@/hooks/useDebounce';
import { Identifiable } from '@/models/identifiable.model';

export type ComboboxProps<TOption> = {
  /**
   * Additional class names to apply to the dropdown.
   */
  className?: string;
  /**
   * Test ID used for testing
   */
  dataTestId?: string;
  /**
   * If true, the dropdown is disabled.
   * @default false
   */
  disabled?: boolean;
  /**
   * The label to display when there are no options.
   * @default 'No results found'
   */
  emptyLabel?: string;
  /**
   * Hides the placeholder option.
   * @default false
   */
  hidePlaceholderOption?: boolean;
  /**
   * The key to use as the label for each option.
   */
  labelKey: keyof TOption;
  /**
   * Name attribute of the combobox.
   */
  name?: string;
  /**
   * Array of options to display.
   */
  options: TOption[];
  /**
   * The placeholder of the input.
   * @default 'Select...'
   */
  placeholder?: string;
  /**
   * The selected option.
   */
  value?: TOption;
  /**
   * The key to use as the value for each option.
   */
  valueKey: TOption extends null ? string : keyof TOption & string;
  /**
   * The width of the dropdown.
   * @default 'w-50' (200px)
   */
  width?: string;
  /**
   * Specify whether the items in the dropdown should be capitalized
   */
  capitalize?: boolean;
  /**
   * Callback when the selected option changes.
   *
   * @param value The new selected option.
   */
  onChange?(value: TOption): void;
  /**
   * Callback when the input value changes.
   *
   * @param event The source event.
   * @param value The new input value.
   */
  onInputChange?(value: string): void;
};

const BaseCombobox = <TOption extends Identifiable>({
  className,
  dataTestId,
  disabled = false,
  emptyLabel = 'No results found',
  labelKey,
  hidePlaceholderOption = false,
  name,
  options,
  placeholder,
  value,
  valueKey,
  width = 'w-50',
  capitalize = false,
  onChange,
  onInputChange,
}: ComboboxProps<TOption>) => {
  const [inputValue, setInputValue] = useState<string>('');
  const debouncedValue = useDebounce<string>(inputValue);

  useEffect(() => {
    onInputChange?.(debouncedValue);
  }, [debouncedValue, onInputChange]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const DEFAULT_DROPDOWN_CLASS =
    'box-border p-1 rounded whitespace-pre-wrap cursor-pointer hover:bg-grey-1 focus-visible:bg-grey-1 ui-active:bg-grey-1 text-sm text-default-text';

  return (
    <div className={clsx('relative inline-block', width, className)}>
      <HCombobox<TOption>
        by={valueKey}
        disabled={disabled}
        name={name}
        onChange={onChange}
        // Prevents the combobox from being an uncontrolled input
        value={value ?? ({} as TOption)}
      >
        {({ open }) => (
          <Float as="div" className="relative" flip={10} floatingAs={Fragment}>
            <div
              className={clsx(
                'box-border relative cursor-default rounded border-2 border-grey-3 flex items-center justify-between text-sm leading-normal text-default-text',
                'focus-visible:border-default-blue focus-within:border-default-blue',
                'ui-active:border-default-blue ui-open:border-default-blue',
                disabled
                  ? 'bg-grey-1 cursor-not-allowed focus-within:border-grey-3 text-grey-5'
                  : 'bg-white text-default-text',
                width
              )}
            >
              <HCombobox.Button
                className="flex-1"
                as="div"
                onClick={(e) => {
                  // Prevents the dropdown from closing when clicking on the input
                  // https://github.com/tailwindlabs/headlessui/discussions/1236#discussioncomment-2970969
                  if (open) {
                    e.preventDefault();
                  }
                }}
              >
                <HCombobox.Input
                  data-testid={dataTestId}
                  autoComplete="off"
                  className={clsx(
                    'p-1.4 border-none outline-none rounded placeholder:text-grey-5 caret-default-text w-full pr-[27px]',
                    disabled && 'cursor-not-allowed',
                    capitalize && 'capitalize'
                  )}
                  onChange={handleInputChange}
                  displayValue={(option: TOption) => option[labelKey] as string}
                  placeholder={placeholder}
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-1.4">
                  <i
                    aria-hidden="true"
                    className={clsx(
                      'cc-icon cc-icon-arrow-down-small text-xl leading-5 cursor-pointer text-default-text ui-disabled:text-grey-5'
                    )}
                  />
                </div>
              </HCombobox.Button>
            </div>
            <Transition
              className="absolute w-full z-20"
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
              afterLeave={() => setInputValue('')}
              show={open}
            >
              <HCombobox.Options className="bg-white p-1 max-h-80 overflow-auto rounded border-2 border-grey-3 shadow-sm-2 focus-visible:outline-none sm:text-sm">
                {options.length === 0 ? (
                  <div className="relative cursor-default select-none p-1 text-grey-5">
                    {emptyLabel}
                  </div>
                ) : (
                  <>
                    {placeholder && !inputValue && !hidePlaceholderOption && (
                      <HCombobox.Option
                        className={clsx(DEFAULT_DROPDOWN_CLASS, 'text-grey-5', {
                          capitalize,
                        })}
                        key="placeholder"
                        value=""
                      >
                        {placeholder}
                      </HCombobox.Option>
                    )}

                    {options.map((option: TOption) => (
                      <HCombobox.Option
                        data-testid={option._id}
                        key={option._id}
                        className={clsx(DEFAULT_DROPDOWN_CLASS, {
                          capitalize,
                        })}
                        value={option}
                      >
                        {option[labelKey] as string}
                      </HCombobox.Option>
                    ))}
                  </>
                )}
              </HCombobox.Options>
            </Transition>
          </Float>
        )}
      </HCombobox>
    </div>
  );
};

export const Combobox = <
  TOption extends Identifiable,
  TFieldValues extends FieldValues
>({
  control,
  name,
  ...props
}: {
  control?: Control<TFieldValues>;
  name?: FieldPath<TFieldValues>;
  width?: string;
} & ComboboxProps<TOption>) =>
  control && name ? (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref, onChange, ...field } }) => (
        <BaseCombobox
          {...field}
          {...props}
          width={props.width ?? 'w-50'}
          onChange={(newValue) =>
            onChange(
              newValue as PathValue<TFieldValues, FieldPath<TFieldValues>>
            )
          }
        />
      )}
    />
  ) : (
    <BaseCombobox name={name} {...props} />
  );
