import { IIntegrationRow } from './integration.config';
import { IntegrationCard } from './IntegrationCard';

interface IIntegrationListProps {
  integrationRows: IIntegrationRow[];
}

export const IntegrationList = ({ integrationRows }: IIntegrationListProps) => (
  <div className="flex flex-col">
    <h2 className="text-xl font-bold">Integrations Available</h2>
    {integrationRows.map((row) => (
      <div key={row.title}>
        <h4 className="mb-4 mt-4 font-bold">{row.title}</h4>
        <div className="flex flex-row" data-testid="integration-row">
          {row.integrations.map((integration) => {
            const {
              logoType,
              integrationName,
              description,
              isButtonEnabled,
              buttonText,
              buttonLink,
              clickHandler,
            } = integration;
            return (
              <div
                key={integration.integrationName}
                className="mr-4"
                data-testid="integration-card"
              >
                <IntegrationCard
                  logoType={logoType}
                  integrationName={integrationName}
                  description={description}
                  isButtonEnabled={isButtonEnabled}
                  buttonText={buttonText}
                  buttonLink={buttonLink}
                  clickHandler={clickHandler}
                />
              </div>
            );
          })}
        </div>
      </div>
    ))}
  </div>
);
