import { pick } from 'lodash';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { IBilling, ISODate } from '@site-mate/sitemate-flowsite-shared';
import {
  AccountSchema,
  AccountTypes,
  IAccount,
  IUpdateAccountBillingSettingsRequestDto,
  IUpdateFlowsiteBillingSettingsRequestDto,
  PathUtility,
  UpdateAccountBillingSettingsRequestDtoSchema,
  UpdateFlowsiteBillingSettingsRequestDtoSchema,
} from '@site-mate/sitemate-global-shared';

import { toISOString } from '@/common';
import { globalAPI } from '@/common/global.api';
import { updateBilling } from '@/hooks/useBilling';
import { useSitemateStartAccount } from '@/hooks/useSitemateStartAccount';

type ToggleString = 'On' | 'Off';

/**
 * Structure to represent account billing settings in a flat structure
 */
export interface ICombinedBillingSettings
  extends IUpdateAccountBillingSettingsRequestDto,
    IUpdateFlowsiteBillingSettingsRequestDto {
  // extra field to avoid overlapping initialSubscriptionDate fields when merging
  // IUpdateAccountBillingSettingsRequestDto and IUpdateFlowsiteBillingSettingsRequestDto
  flowsiteInitialSubscriptionDate: ISODate;
  accountType: AccountTypes;
  ssoEnabled: ToggleString;
}

/**
 * Convert all dates within the settings into Sitemate Start-compatible dates (ISO strings)
 *
 * @param billing - The billing settings to convert
 * @returns - The billing settings with all dates converted to ISO strings
 */
export function convertBillingToCompatibleDates(
  billing: ICombinedBillingSettings
) {
  const {
    billingDate,
    initialSubscriptionDate,
    trialEndDate,
    flowsiteInitialSubscriptionDate,
    ...remainingBilling
  } = billing;

  return {
    ...remainingBilling,
    billingDate: toISOString(billingDate),
    initialSubscriptionDate: toISOString(initialSubscriptionDate),
    trialEndDate: toISOString(trialEndDate),
    flowsiteInitialSubscriptionDate: toISOString(
      flowsiteInitialSubscriptionDate
    ),
  };
}

function extractGlobalBilling(account: IAccount, accountId: string) {
  const globalBilling = account.billing;
  if (!globalBilling) {
    throw new Error(`No global billing found for ${accountId}`);
  }
  return UpdateAccountBillingSettingsRequestDtoSchema.parse(globalBilling);
}

export function useGlobalBilling(accountId?: string) {
  const account = useSitemateStartAccount(accountId);
  return useQuery({
    queryKey: ['sitemate-account', accountId, 'global-billing'],
    queryFn: () => extractGlobalBilling(account.data!, accountId!),
    enabled: !account.isFetching && account.isSuccess && !!accountId,
  });
}

function extractFlowsiteBilling(account: IAccount, accountId: string) {
  const flowsiteBilling = account.billing?.flowsite;
  if (!flowsiteBilling) {
    throw new Error(`No flowsite billing found for ${accountId}`);
  }
  return UpdateFlowsiteBillingSettingsRequestDtoSchema.parse(flowsiteBilling);
}

export function useFlowsiteBilling(accountId?: string) {
  const account = useSitemateStartAccount(accountId);
  return useQuery({
    queryKey: ['sitemate-account', accountId, 'flowsite-billing'],
    queryFn: () => extractFlowsiteBilling(account.data!, accountId!),
    enabled: !account.isFetching && account.isSuccess && !!accountId,
  });
}

export function updateGlobalBilling(
  accountId: string,
  newGlobalBilling: IUpdateAccountBillingSettingsRequestDto
) {
  return globalAPI.patch(`/accounts/${accountId}/billing`, newGlobalBilling);
}

export function updateFlowsiteBilling(
  accountId: string,
  newFlowsiteBilling: IUpdateFlowsiteBillingSettingsRequestDto
) {
  return globalAPI.patch(
    `/accounts/${accountId}/billing/flowsite`,
    newFlowsiteBilling
  );
}

function updateAccount(accountId: string, newAccount: IAccount) {
  return globalAPI.patch(`/accounts/${accountId}`, newAccount);
}

export async function updateSitemateStartBilling(
  accountId: string,
  billing: ICombinedBillingSettings,
  // TODO: remove when Flowsite billing support is completely dropped for SMS billing
  billingPath?: string
) {
  const convertedBilling = convertBillingToCompatibleDates(billing);
  await updateGlobalBilling(
    accountId,
    UpdateAccountBillingSettingsRequestDtoSchema.parse(convertedBilling)
  );
  await updateFlowsiteBilling(
    accountId,
    UpdateFlowsiteBillingSettingsRequestDtoSchema.parse({
      ...convertedBilling,
      initialSubscriptionDate: convertedBilling.flowsiteInitialSubscriptionDate,
    })
  );

  if (billingPath) {
    const legacyBillingParams = [
      'planType',
      'subscriptionStatus',
      'trialEndDate',
      'subscriptionTerm',
      'billingCycle',
      'paymentTermDay',
      'paymentTermContext',
      'paymentMethod',
      'currency',
    ];
    const legacyBilling = {
      ...pick(billing, legacyBillingParams),
      initialSubscriptionDate: billing.flowsiteInitialSubscriptionDate,
    };

    await updateBilling({
      billingPath,
      newBilling: { ...legacyBilling, _id: billingPath } as IBilling,
    });
  }
  const updatedAccount = await updateAccount(accountId, {
    accountType: convertedBilling.accountType,
    ssoEnabled: convertedBilling.ssoEnabled === 'On',
  } as IAccount);
  return AccountSchema.parse(updatedAccount);
}

export function useUpdateSitemateStartBilling(
  accountId?: string,
  // TODO: remove billingPath when Flowsite billing support is completely dropped for SMS billing
  billingPath?: string
) {
  const queryClient = useQueryClient();
  const workspaceId = billingPath ? PathUtility.getParent(billingPath) : '';
  return useMutation({
    mutationFn: async (newBilling: ICombinedBillingSettings) =>
      updateSitemateStartBilling(accountId!, newBilling, billingPath),
    onSuccess: async (updatedAccount) => {
      queryClient.setQueryData(['sitemate-account', accountId], updatedAccount);
      queryClient.setQueryData(
        ['sitemate-account', accountId, 'global-billing'],
        extractGlobalBilling(updatedAccount, accountId!)
      );
      queryClient.setQueryData(
        ['sitemate-account', accountId, 'flowsite-billing'],
        extractFlowsiteBilling(updatedAccount, accountId!)
      );
      if (workspaceId) {
        queryClient.invalidateQueries(['workspace', workspaceId, 'billing']);
      }
    },
  });
}
