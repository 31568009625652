import { Link } from 'react-router-dom';

import { PageLayout } from '@/components';

export const NotFound = () => (
  <PageLayout>
    <div className="mt-2 flex flex-col space-y-2">
      <h2>Page Not Found</h2>
      <Link to="/">Go Home</Link>
    </div>
  </PageLayout>
);
