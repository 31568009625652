import { useState } from 'react';

/**
 * Custom hook to filter items by a query string
 *
 * @param items - An array of items to filter
 * @param filterKey - The key to filter by
 * @param initialQuery - The initial query string
 * @returns - An object containing the filtered items and a function to set the query string
 */
export const useFilterByContainsQuery = <T>(
  items: T[],
  filterKey: keyof T,
  initialQuery: string = ''
) => {
  const [query, setQuery] = useState(initialQuery);

  return {
    setQuery,
    items:
      query === ''
        ? items
        : items.filter((item: T) =>
            String(item[filterKey])?.toLowerCase().includes(query.toLowerCase())
          ),
  };
};
