import { FC } from 'react';

import {
  IConnection,
  IntegrationType,
  Maybe,
} from '@site-mate/sitemate-flowsite-shared';

import { Combobox } from '@/components';
import { useConnectionsWithType } from '@/hooks';

import { useFilterByContainsQuery } from '../../hooks/useFilteredItems';

type ConnectionSelectorProps = {
  connectionType: IntegrationType;
  connectionId: Maybe<string>;
  title?: string;
  onChangeConnection?: (connection: IConnection) => void;
};

export const ConnectionSelector: FC<ConnectionSelectorProps> = ({
  connectionType,
  connectionId,
  title,
  onChangeConnection,
}) => {
  const { data = [] } = useConnectionsWithType(connectionType);
  const { setQuery, items: connections } = useFilterByContainsQuery(
    data,
    'name'
  );

  const selectedConnection = connections.find(
    (connection) => connection._id === connectionId
  );

  return (
    <>
      <p className="font-bold text-sm pb-1">{title}</p>
      <Combobox
        name="tenant"
        dataTestId="tenant-dropdown"
        placeholder="Select..."
        labelKey="name"
        valueKey="_id"
        options={connections}
        value={selectedConnection}
        onInputChange={setQuery}
        onChange={onChangeConnection}
        width="w-57.5"
      />
    </>
  );
};
