import { useQuery } from 'react-query';

import {
  IIntegration,
  IntegrationType,
} from '@site-mate/sitemate-flowsite-shared';

import { api } from '@/common/api';

function useIntegrations() {
  return useQuery({
    queryKey: ['integrations'],
    queryFn: () => api.get<IIntegration[]>('/integrations'),
  });
}

export type IntegrationsMap = Map<IntegrationType, IIntegration | undefined>;
export const emptyIntegrationsMap = new Map<
  IntegrationType,
  IIntegration | undefined
>();

export function useIntegrationsMap() {
  const integrationsQuery = useIntegrations();
  return useQuery({
    queryKey: ['integrationsMap'],
    queryFn: async () => {
      const integrations = integrationsQuery.data;
      if (!integrations) {
        return emptyIntegrationsMap;
      }

      return new Map<IntegrationType, IIntegration | undefined>(
        Object.values(IntegrationType).map((integrationType) => [
          integrationType,
          integrations.find(
            (integration) => integration.type === integrationType
          ),
        ])
      );
    },
    enabled: integrationsQuery.isSuccess,
  });
}
