import { useState } from 'react';

import WelcomeImage from '@/assets/svg/welcome.svg';

import { Modal, ModalPositions } from './Modal';
import { Button, IButtonColor } from '../button';

export const WelcomeModal = () => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Modal
      open={isOpen}
      position={ModalPositions.Top}
      onClose={() => setIsOpen(false)}
    >
      <div className="flex flex-col items-center text-center align-middle">
        <Modal.CloseButton onClose={() => setIsOpen(false)} />
        <img
          src={WelcomeImage}
          alt="Welcome to Flowsite!"
          className="mb-4 h-[200px]"
        />
        <h1 className="mb-4 text-lg font-bold">Welcome to Flowsite</h1>
        <p className="mb-4">
          Flowsite is an integrations platform which allows you to connect your
          software tools through instant no-code integrations.
        </p>
        <p className="mb-4">Flowsite is free for the first 30 days.</p>
        <Button color={IButtonColor.GREEN} onClick={() => setIsOpen(false)}>
          Get Started
        </Button>
      </div>
    </Modal>
  );
};
