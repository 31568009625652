import { FC } from 'react';

import { IFlow, INodeType } from '@site-mate/sitemate-flowsite-shared';

import { useIntegrationsMap } from '@/hooks';
import { SelectFlowModal } from '@/pages/flows/components/flow-card/SelectFlowModal';
import { nodeService } from '@/services';

import { IFlowOptionItem } from './FlowOptions';

type FlowCardProps = {
  title?: string;
  image: string;
  options: IFlowOptionItem[];
  setNewFlow: (flow: IFlow) => void;
};

export const FlowCard: FC<FlowCardProps> = ({
  title,
  image,
  options,
  setNewFlow,
}) => {
  const { data: integrationsMap } = useIntegrationsMap();

  const onAddFlow = async (selectedNodeType: INodeType) => {
    const flow = await nodeService.generateNewFlow(
      { nodeType: selectedNodeType },
      integrationsMap
    );
    setNewFlow(flow);
  };

  return (
    <div className="flex h-[101px] w-[235px] flex-col place-content-center items-center space-y-2 rounded bg-white p-2 shadow">
      <div className="flex place-content-center items-center space-x-2">
        {image && (
          <img
            src={image}
            alt={`${title} logo`}
            className='w-[30px] h-[30px]'/>
        )}
        <p className="text-base font-bold text-default-text">{title}</p>
      </div>
      <SelectFlowModal options={options} image={image} onAddFlow={onAddFlow} />
    </div>
  );
};
