import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  FlowsitePlanTypes,
  SubscriptionStatuses,
} from '@site-mate/sitemate-global-shared';

import { Spinner } from '@/components';
import {
  useBillingWithWorkspace,
  useWorkspace,
  useWorkspaces,
  useLocalStorage,
  useAuthenticatedUser,
  useFlowsiteBilling,
} from '@/hooks';

export interface IWorkspaceContextData {
  /**
   * The active workspaceId (not the full path)
   */
  workspaceId: string;
  /**
   * The Sitemate account id
   */
  accountId?: string;
}

export const WorkspaceContext = createContext<
  IWorkspaceContextData | undefined
>(undefined);

export const useWorkspaceContext = () => {
  const workspaceContext = useContext(WorkspaceContext);

  if (!workspaceContext) {
    throw new Error(
      'useWorkspaceContext must be used within a WorkspaceContextProvider'
    );
  }

  return workspaceContext;
};

type WorkspaceProviderProps = {
  /**
   * The children to render
   */
  children: ReactNode;
  /**
   * Flag whether to show the children or not
   */
  enabled?: boolean;
  /**
   * The requested workspace id to use
   */
  workspaceId?: string;
  /**
   * Callback when the user is to be redirected to the sign up page.
   * This will be called when the user has no workspaces.
   */
  onSignUpRedirect?: () => void;
  /**
   * Callback when the workspace changes.
   * This will be called when the default workspace is automatically selected.
   */
  onChangeWorkspace?: (workspaceId: string) => void;
  /**
   * Callback when the workspace is invalid
   */
  onInvalidWorkspace?: () => void;
  /**
   * Callback when the free trial is expired
   */
  onFreeTrialExpired?: () => void;
};

export const WorkspaceProvider: FC<WorkspaceProviderProps> = ({
  children,
  enabled = true,
  workspaceId: requestWorkspaceId,
  onChangeWorkspace,
  onInvalidWorkspace,
  onSignUpRedirect,
  onFreeTrialExpired,
}) => {
  const workspace = useWorkspace(requestWorkspaceId);
  const workspaces = useWorkspaces();

  const [workspaceId, setWorkspaceId] = useState<string>();
  const [accountId, setAccountId] = useState<string>();

  const location = useLocation();
  const navigate = useNavigate();

  const user = useAuthenticatedUser();

  useBillingWithWorkspace(workspaceId);
  const { data: billingData } = useFlowsiteBilling(accountId);

  const isTrialExpired = useCallback(() => {
    if (billingData && workspace && user.data) {
      if (user.data.isSuperUser) {
        return false;
      }

      const { subscriptionStatus, planType, trialEndDate } = billingData;
      if (!subscriptionStatus || !trialEndDate) {
        return false;
      }

      if (
        planType === FlowsitePlanTypes.Freemium &&
        subscriptionStatus === SubscriptionStatuses.ExpiredTrial
      ) {
        // edge case for freemium
        return false;
      }

      const trialSubscriptions = [
        // these subscriptions must be checked for trial expiry, ignore other subscriptions (paid etc.)
        SubscriptionStatuses.FreeTrial,
        SubscriptionStatuses.ExpiredTrial,
        SubscriptionStatuses.Paused,
        SubscriptionStatuses.Churned,
      ];

      if (!trialSubscriptions.includes(subscriptionStatus)) {
        return false;
      }

      const expiryDate = new Date(trialEndDate);
      const now = new Date();
      const isExpired = now > expiryDate;
      if (isExpired) {
        return true;
      }
    }
    return false;
  }, [billingData, workspace, user]);

  const [lastVisitedWorkspaceId, setLastVisitedWorkspaceId] = useLocalStorage(
    'lastVisitedWorkspaceId'
  );

  const hasNoWorkspace = workspaces.isSuccess && workspaces.data?.length === 0;

  const removeQueryParam = useCallback((param: string) => {
    const { pathname, search } = location;
    const queryParams = new URLSearchParams(search);
    if (queryParams.has(param)) {
      queryParams.delete(param);
      navigate(`${pathname}?${queryParams.toString()}`, { replace: true });
    }
  }, [location, navigate]);

  useEffect(() => {
    if (hasNoWorkspace) {
      onSignUpRedirect?.();
    }
  }, [hasNoWorkspace, onSignUpRedirect]);

  useEffect(() => {
    if (!requestWorkspaceId || workspace.isLoading) {
      return;
    }

    if (!workspace.data) {
      onInvalidWorkspace?.();
      return;
    }

    const { sitemateAccountId } = workspace.data;

    setWorkspaceId(requestWorkspaceId);
    setAccountId(sitemateAccountId);

    // Save the last visited workspace id to local storage
    setLastVisitedWorkspaceId(requestWorkspaceId);
  }, [
    workspace,
    onInvalidWorkspace,
    requestWorkspaceId,
    setLastVisitedWorkspaceId,
  ]);

  useEffect(() => {
    if (isTrialExpired()) {
      onFreeTrialExpired?.();
    } else {
      removeQueryParam('trialExpired');
    }
  }, [billingData, isTrialExpired, onFreeTrialExpired, removeQueryParam]);

  useEffect(() => {
    if (requestWorkspaceId) {
      // No need to check for default workspace if requestWorkspaceId is provided
      return;
    }

    // Default to the first workspace
    let defaultWorkspace = workspaces.data?.[0];

    // Try to retrieve the last visited workspace as the default workspace
    if (lastVisitedWorkspaceId) {
      const lastVisitedWorkspace = workspaces.data?.find(
        (item) => item._id === `/${lastVisitedWorkspaceId}`
      );

      if (lastVisitedWorkspace) {
        defaultWorkspace = lastVisitedWorkspace;
      }
    }

    const defaultWorkspaceId = defaultWorkspace?._id?.split('/').pop();
    if (defaultWorkspaceId) {
      onChangeWorkspace?.(defaultWorkspaceId);
    }
  }, [
    requestWorkspaceId,
    workspaces.data,
    onChangeWorkspace,
    enabled,
    lastVisitedWorkspaceId,
  ]);

  const workspaceData = useMemo(
    () => (workspaceId ? { workspaceId, accountId } : undefined),
    [workspaceId, accountId]
  );

  if (!workspaceId && enabled) {
    return (
      <div className="flex h-full items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <WorkspaceContext.Provider value={workspaceData}>
      {workspaceId && enabled ? children : null}
    </WorkspaceContext.Provider>
  );
};
