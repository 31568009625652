export enum FeatureToggles {
  QUICKBOOKS = 'quickbooks',
  SHAREPOINT = 'sharepoint',
}

const toBoolean = (value?: string) => {
  if (!value) {
    return false;
  }

  return String(value).toLowerCase() === 'true';
};

const featureToggleSettings = new Map<FeatureToggles, boolean>([
  [
    FeatureToggles.QUICKBOOKS,
    toBoolean(import.meta.env.PUBLIC_QUICKBOOKS_INTEGRATION_TOGGLE),
  ],
  [
    FeatureToggles.SHAREPOINT,
    toBoolean(import.meta.env.PUBLIC_SHAREPOINT_INTEGRATION_TOGGLE),
  ],
]);

export const isFeatureEnabled = (feature: FeatureToggles): boolean =>
  featureToggleSettings.get(feature) ?? false;
