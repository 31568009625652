import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';

import { IRun, PathService } from '@site-mate/sitemate-flowsite-shared';
import { Flake } from '@site-mate/sitemate-global-shared';

import { capitalize } from '@/common/formatter';
import { Button, Spinner } from '@/components';
import { IButtonColor } from '@/components/elements/button/Button.variants';
import { ErrorList } from '@/pages/runs/components';
import { useRuns } from '@/pages/runs/hooks/useRuns';
import { useWorkspaceContext } from '@/providers/WorkspaceProvider';

const QUERY_LIMIT = 10;

const columnHelper = createColumnHelper<IRun>();

/**
 * Gets the embedded error from a run, first accesses node errors,
 * then accesses run errors to support legacy runs.
 *
 * @param run Run object where errors will be extracted
 * @returns error list from node, or from run errors as fallback
 */
const errorAccessorFn = (run: IRun) => {
  const { errors, nodes } = run;
  return nodes && nodes.length && nodes[0].errors && nodes[0].errors.length
    ? nodes[0].errors
    : errors;
};

const columns = [
  columnHelper.accessor('_id', {
    id: 'runId',
    header: () => 'Run ID',
    size: 1,
    cell: (info) => PathService.extractId(info.getValue()),
  }),
  columnHelper.accessor('createdAt', {
    header: () => 'Created At',
    size: 1,
    cell: (info) => dayjs(info.getValue()).format('DD/MM/YYYY [at] h:mm A'),
  }),
  columnHelper.accessor('_id', {
    id: 'flowId',
    header: () => 'Flow ID',
    size: 1,
    cell: (info) => PathService.extractId(info.getValue(), Flake.prefixes.Flow),
  }),
  columnHelper.accessor('name', {
    header: () => 'Flow Name',
    size: 1,
  }),
  columnHelper.accessor('status', {
    header: () => 'Status',
    size: 1,
    cell: (info) => capitalize(info.getValue()),
  }),
  columnHelper.accessor('metadata', {
    header: () => 'Trigger Object',
    size: 1,
    cell: (info) => {
      const { triggerObject } = info.getValue();

      return (
        triggerObject?.url && (
          <a href={triggerObject.url} target="_blank" rel="noreferrer">
            Dashpivot Form
          </a>
        )
      );
    },
  }),
  columnHelper.accessor(errorAccessorFn, {
    id: 'errors',
    header: () => 'Errors',
    size: 2,
    cell: (errors) => <ErrorList errors={errors.getValue()} />,
  }),
];

export const Runs = () => {
  const [lastRetrievedDate, setLastRetrievedDate] = useState<string>();
  const [runs, setRuns] = useState<IRun[]>([]);
  const { workspaceId } = useWorkspaceContext();

  const { data, isFetching, refetch } = useRuns(workspaceId, {
    limit: QUERY_LIMIT,
    beforeDate: lastRetrievedDate,
  });

  const shouldHideLoadMore = !data || data.length < QUERY_LIMIT || isFetching;

  const table = useReactTable({
    data: runs,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 1,
    },
  });

  const handleLoadMore = useCallback(() => {
    const lastRun = runs[runs.length - 1];
    setLastRetrievedDate(lastRun.createdAt);
  }, [runs]);

  const handleRefresh = async () => {
    setRuns([]);
    setLastRetrievedDate(undefined);
    const res = await refetch();
    if (res.isSuccess && res.data) {
      setRuns(res.data);
    }
  };

  useEffect(() => {
    // Resets the runs when workspaceId has changed
    setRuns([]);
  }, [workspaceId]);

  useEffect(() => {
    if (data) {
      setRuns((prevRuns) => [...prevRuns, ...data]);
    }
  }, [data]);

  return (
    <div>
      <div className="flex justify-between items-start mt-4">
        <h2 className="text-xl font-bold">Runs</h2>
        <Button
          className="pr-3"
          hidden={isFetching}
          onClick={handleRefresh}
          color={IButtonColor.GREY_2}
        >
          <span className="px-1 -mb-[2px]">
            <i className="cc-icon cc-icon-redo" />
          </span>
          Refresh
        </Button>
      </div>
      <div className="runs mt-2">
        <table className="border-slate-500 mb-4 w-full table-fixed border-collapse border text-left">
          <thead className="bg-grey-2 text-sm">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const colWidth = `${(
                    (header.getSize() / table.getTotalSize()) *
                    100
                  ).toFixed(2)}%`;
                  return (
                    <th
                      key={header.id}
                      className="border-slate-600 border px-2 py-2"
                      style={{
                        width: colWidth,
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className="bg-white">
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className="align-top">
                {row.getVisibleCells().map((cell) => (
                  <td
                    className="border-slate-500 text-clip break-words border p-2 text-sm"
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Button
        hidden={!data || data.length < QUERY_LIMIT || isFetching}
        className={shouldHideLoadMore ? 'hidden' : ''}
        onClick={handleLoadMore}
        color={IButtonColor.GREY_2}
      >
        Load more
      </Button>
      {isFetching && <Spinner />}
    </div>
  );
};
