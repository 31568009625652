import { ulid } from 'ulid';

import {
  IOptionSourceKeys,
  IXeroInvoiceFieldMappingConfigKey,
  MappingConfigType,
} from '@site-mate/sitemate-flowsite-shared';

const generateLineItemMappingSetArray = (
  dashpivotId: string | undefined,
  xeroId: string | undefined
) => [
  {
    _id: ulid(),
    mappings: [
      {
        _id: ulid(),
        key: IXeroInvoiceFieldMappingConfigKey.Description,
        isMultiple: false,
        isRequired: true,
        type: MappingConfigType.Single,
        source: {
          isEditable: true,
          fields: [
            {
              _id: ulid(),
              integrationId: dashpivotId,
              fieldType: 'inputData',
              customResolver: 'dashpivotTableColumnField',
              filter: "form.items[kind in ['table', 'prefilledTable']].columns",
            },
          ],
          valueData: undefined,
        },
        destination: {
          isEditable: false,
          fields: [],
          valueData: {
            integrationId: xeroId,
            label: 'Line Item Description',
          },
        },
        metadata: {},
      },
      {
        _id: ulid(),
        key: IXeroInvoiceFieldMappingConfigKey.Quantity,
        isMultiple: false,
        isRequired: false,
        type: MappingConfigType.Single,
        source: {
          isEditable: true,
          fields: [
            {
              _id: ulid(),
              integrationId: dashpivotId,
              fieldType: 'inputData',
              customResolver: 'dashpivotTableColumnField',
              filter: "form.items[kind in ['table', 'prefilledTable']].columns",
            },
          ],
          valueData: undefined,
        },
        destination: {
          isEditable: false,
          fields: [],
          valueData: {
            integrationId: xeroId,
            label: 'Line Item Quantity',
          },
        },
        metadata: {},
      },
      {
        _id: ulid(),
        key: IXeroInvoiceFieldMappingConfigKey.Amount,
        isMultiple: false,
        isRequired: false,
        type: MappingConfigType.Single,
        source: {
          isEditable: true,
          fields: [
            {
              _id: ulid(),
              integrationId: dashpivotId,
              fieldType: 'inputData',
              customResolver: 'dashpivotTableColumnField',
              filter: "form.items[kind in ['table', 'prefilledTable']].columns",
            },
          ],
          valueData: undefined,
        },
        destination: {
          isEditable: false,
          fields: [],
          valueData: {
            integrationId: xeroId,
            label: 'Line Item Unit Amount',
          },
        },
        metadata: {},
      },
      {
        _id: ulid(),
        key: IXeroInvoiceFieldMappingConfigKey.Account,
        isMultiple: false,
        isRequired: false,
        type: MappingConfigType.Single,
        source: {
          isEditable: true,
          fields: [
            {
              _id: ulid(),
              integrationId: xeroId,
              fieldType: 'dropdown',
              optionType: 'dynamic',
              optionSource: IOptionSourceKeys.XeroAccountingAccounts,
              optionParams: [],
            },
          ],
          valueData: undefined,
        },
        destination: {
          isEditable: false,
          fields: [],
          valueData: {
            integrationId: xeroId,
            label: 'Line Item Account Code',
          },
        },
        metadata: {},
      },
      {
        _id: ulid(),
        key: IXeroInvoiceFieldMappingConfigKey.TrackingCategory1,
        isMultiple: false,
        isRequired: false,
        type: MappingConfigType.Single,
        source: {
          isEditable: true,
          fields: [
            {
              _id: ulid(),
              integrationId: dashpivotId,
              fieldType: 'inputData',
              customResolver: 'dashpivotTableColumnField',
              filter: "form.items[kind in ['table', 'prefilledTable']].columns",
            },
          ],
          valueData: undefined,
        },
        destination: {
          isEditable: true,
          fields: [
            {
              _id: ulid(),
              integrationId: xeroId,
              fieldType: 'dropdown',
              optionType: 'dynamic',
              optionSource: IOptionSourceKeys.XeroAccountingTrackingCategories,
              optionParams: [],
            },
          ],
          valueData: undefined,
        },
        metadata: {},
      },
      {
        _id: ulid(),
        key: IXeroInvoiceFieldMappingConfigKey.TrackingCategory2,
        isMultiple: false,
        isRequired: false,
        type: MappingConfigType.Single,
        source: {
          isEditable: true,
          fields: [
            {
              _id: ulid(),
              integrationId: dashpivotId,
              fieldType: 'inputData',
              customResolver: 'dashpivotTableColumnField',
              filter: "form.items[kind in ['table', 'prefilledTable']].columns",
            },
          ],
          valueData: undefined,
        },
        destination: {
          isEditable: true,
          fields: [
            {
              _id: ulid(),
              integrationId: xeroId,
              fieldType: 'dropdown',
              optionType: 'dynamic',
              optionSource: IOptionSourceKeys.XeroAccountingTrackingCategories,
              optionParams: [],
            },
          ],
          valueData: undefined,
        },
        metadata: {},
      },
    ],
  },
];

export const generateXeroInvoiceMappings = (
  dashpivotId: string | undefined,
  xeroId: string | undefined
) => [
  {
    _id: ulid(),
    key: IXeroInvoiceFieldMappingConfigKey.Contact,
    isMultiple: false,
    isRequired: true,
    type: MappingConfigType.Single,
    source: {
      isEditable: true,
      fields: [
        {
          _id: ulid(),
          integrationId: dashpivotId,
          fieldType: 'inputData',
          customResolver: 'dashpivotField',
          filter: "form.items[kind='category']",
        },
      ],
      valueData: undefined,
    },
    destination: {
      isEditable: false,
      fields: [],
      valueData: {
        integrationId: xeroId,
        label: IXeroInvoiceFieldMappingConfigKey.Contact,
      },
    },
    metadata: {},
  },
  {
    _id: ulid(),
    key: IXeroInvoiceFieldMappingConfigKey.InvoiceDate,
    isMultiple: false,
    isRequired: false,
    type: MappingConfigType.Single,
    source: {
      isEditable: true,
      fields: [
        {
          _id: ulid(),
          integrationId: dashpivotId,
          fieldType: 'inputData',
          customResolver: 'dashpivotField',
          filter: "form.items[kind='date']",
        },
      ],
      valueData: undefined,
    },
    destination: {
      isEditable: false,
      fields: [],
      valueData: {
        integrationId: xeroId,
        label: 'Date',
      },
    },
    metadata: {},
  },
  {
    _id: ulid(),
    key: IXeroInvoiceFieldMappingConfigKey.LineItems,
    isMultiple: true,
    isRequired: false,
    type: MappingConfigType.MultipleSet,
    items: generateLineItemMappingSetArray(dashpivotId, xeroId),
  },
];
