import { AxiosResponse } from 'axios';
import { OidcClient, SigninResponse } from 'oidc-client-ts';
import { useEffect, useRef, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

import {
  CreateConnectionMetadata,
  ErrorUtil,
  IntegrationType,
  IntegrationUtil,
  IConnection,
  IConnectionType,
  Maybe,
} from '@site-mate/sitemate-flowsite-shared';

import { OAuthCallbacks, getOAuthConfig } from '@/auth/oauth-callbacks';
import { api } from '@/common/api';
import { useCreateOAuthConnection } from '@/hooks/useConnections';
import { useIntegration } from '@/hooks/useIntegration';
import { useTokenExchange } from '@/hooks/useTokenExchange';

interface ISignInState {
  isProcessing: boolean;
  isSuccess: boolean;
  error?: string;
}

interface IProcessedSignin {
  processedSignin: SigninResponse;
  metadata?: CreateConnectionMetadata;
}

/**
 * The user state sent to the external auth provider.
 * This state is sent back to the redirectUri on completion of the auth process.
 */
export interface IExternalSignInUserState {
  /**
   * The workspace id to create or modify the connection in.
   * If undefined, the connection operation will be aborted.
   */
  workspaceId: string;
  /**
   * The connection id to update.
   * Only applicable for reconnecting connections:
   * - If undefined, a new connection will be created.
   * - If defined, the connection will be updated.
   */
  connectionId?: string;
}

export function buildCreateConnectionMetadata(
  integrationType: IntegrationType
): Maybe<CreateConnectionMetadata> {
  if (integrationType === IntegrationType.QUICKBOOKS) {
    const urlParams = new URLSearchParams(window.location.search);

    return {
      realmId: urlParams.get('realmId') ?? '',
    };
  }
  return undefined;
}

/**
 * Perform token exchange based on the sign in response.
 * For Integrations supporting PKCE flow, this will be performed by the client.
 * Otherwise, the server will do the token exchange and returns the token instead.
 *
 * @param oidcClient instance of the oidc client
 * @param integrationType type of the integration
 * @param exchangeToken
 * @returns
 */
export async function processSignin(
  oidcClient: OidcClient,
  integrationType: IntegrationType,
  exchangeToken: ReturnType<typeof useTokenExchange>
): Promise<IProcessedSignin> {
  if (IntegrationUtil.isPkceSupported(integrationType)) {
    return {
      processedSignin: await oidcClient.processSigninResponse(
        window.location.href
      ),
    };
  }

  const { state, response } = await oidcClient.readSigninResponseState(
    window.location.href
  );
  const exchangedTokens = await exchangeToken.mutateAsync({
    integrationType,
    code: response.code ?? '',
    redirectUri: state.redirect_uri,
  });

  return {
    processedSignin: {
      ...exchangedTokens,
      scope: state.scope,
      userState: {
        ...(state.data as IExternalSignInUserState),
      },
    } as SigninResponse,
    metadata: buildCreateConnectionMetadata(integrationType),
  };
}

/**
 * Attempts to update an existing connection based on the sign in response
 * Will fail if the api rejects the reconnect query
 *
 * @param {SigninResponse} signInResponse - the error
 * @returns {Promise<void>}
 */
async function updateXeroConnection(
  signInResponse: SigninResponse
): Promise<void> {
  if (
    !signInResponse.access_token ||
    !signInResponse.refresh_token ||
    !signInResponse.id_token ||
    !signInResponse.scope
  ) {
    throw new Error('One or more credentials missing');
  }

  const { connectionId: connectionPath } =
    signInResponse.userState as Required<IExternalSignInUserState>;
  const [, workspaceId, connectionId] = connectionPath.split('/');

  const connectionParams: Partial<IConnection> = {
    type: IConnectionType.OAuth,
    credential: {
      accessToken: signInResponse.access_token,
      refreshToken: signInResponse.refresh_token,
      scope: signInResponse.scope,
      expiresAt: signInResponse.expires_at,
    },
  };

  try {
    const reconnectResponse = (await api.patch(
      `/workspaces/${workspaceId}/connections/${connectionId}/reconnect`,
      connectionParams
    )) as AxiosResponse;

    if (reconnectResponse.status >= 300) {
      const errorMessage =
        reconnectResponse.data?.message ??
        `Request failed with status code ${reconnectResponse.status}`;
      throw new Error(errorMessage);
    }
  } catch (error) {
    const errorMessage = ErrorUtil.handleErrorMessage(
      error,
      `reconnection failed for connectionId ${connectionPath}`
    );
    throw new Error(errorMessage);
  }
}

/**
 * Hook for processing oAuth callback for connecting integrations.
 * Checks the url if it matches the oauth redirectUri pattern.
 * If valid, it processes the signIn response, then calls the API to create the connection.
 * Parses the workspaceId and the connectionId (if applicable) from the signIn user state.
 *
 * @returns The SignInState of the connection
 */
export function useExternalSignIn() {
  const auth = useAuth();
  const navigate = useNavigate();

  const integrationType = Array.from(OAuthCallbacks.keys()).find(
    (integrationKey) =>
      window.location.pathname === `/oauth-callback-${integrationKey}`
  );
  const integration = useIntegration(integrationType);

  /**
   * Prevents the external signIn effect from being executed twice
   * During development, React StrictMode intentionally invokes the component twice
   * https://github.com/facebook/react/issues/24502
   */
  const signInEffectExecuted = useRef(false);

  const [signInState, setSignInState] = useState<ISignInState>({
    isProcessing: true,
    isSuccess: false,
    error: undefined,
  });

  const createConnection = useCreateOAuthConnection();
  const exchangeToken = useTokenExchange();

  const onSuccess = (workspaceId?: string) => {
    // TODO: Might need a state restore functionality to navigate back to the previous view:
    // Store state right before redirect, rehydrate state after loading if stored.

    if (!workspaceId) {
      setSignInState({
        error: 'Workspace Id not found',
        isProcessing: false,
        isSuccess: false,
      });
      return;
    }

    navigate(`workspaces/${workspaceId}`, { replace: true });

    setSignInState({
      isProcessing: false,
      isSuccess: true,
      error: undefined,
    });
  };

  const onError = async (error: string) => {
    // TODO: Handle errors better to remove the need to log issues in case of error
    // eslint-disable-next-line no-console
    console.error(error);
    setSignInState({
      ...signInState,
      isProcessing: false,
      error,
    });
  };

  useEffect(() => {
    if (signInEffectExecuted.current) {
      return undefined;
    }
    if (!window.location.pathname.startsWith('/oauth-callback')) {
      setSignInState({ ...signInState, error: undefined, isProcessing: false });
      return undefined;
    }
    if (integrationType && !integration.isFetched) {
      // prevent race condition when creating connections
      // getting integration metadata is required first
      return undefined;
    }

    async function processExternalSignIn() {
      if (!integrationType) {
        onError(`Invalid Service`);
        return;
      }

      const config = getOAuthConfig(integrationType);
      if (!config) {
        onError(`Missing config: ${integrationType}`);
        return;
      }
      const oidcClient = new OidcClient(config);

      let workspaceId: string | undefined;

      try {
        const { processedSignin, metadata } = await processSignin(
          oidcClient,
          integrationType,
          exchangeToken
        );
        if (!processedSignin) {
          onError('Invalid sign in');
          return;
        }

        if (!processedSignin?.access_token) {
          onError('Missing access token');
          return;
        }

        if (!processedSignin?.refresh_token) {
          onError('Missing refresh token');
          return;
        }

        if (processedSignin?.error) {
          onError(processedSignin?.error);
          return;
        }

        const { userState } = processedSignin as {
          userState?: IExternalSignInUserState;
        };

        const { connectionId } = userState || {};
        workspaceId = userState?.workspaceId;

        if (!workspaceId) {
          throw new Error('Missing workspaceId');
        }

        if (connectionId) {
          if (integrationType !== IntegrationType.XERO) {
            throw new Error(
              `Updating ${integrationType} connection not yet supported`
            );
          }
          await updateXeroConnection(processedSignin);
        } else {
          await createConnection.mutateAsync({
            response: processedSignin,
            workspaceId,
            integration,
            metadata,
          });
        }
      } catch (error: unknown) {
        onError(`${integrationType} connect error: ${error}`);
        window.location.replace('/');
        return;
      }

      await auth.signinSilent();

      onSuccess(workspaceId);
    }
    processExternalSignIn();

    return () => {
      signInEffectExecuted.current = true;
    };

    // Only execute this once on mount and once integration id is already loaded
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integration.isFetched]);

  return signInState;
}
