import { useSearchParams } from 'react-router-dom';

import { PageLayout } from '@/components';

import { SignUpReferral } from './components';
import { SignUp } from './components/SignUp';

export const SignUpPage = () => {
  const [searchParams] = useSearchParams();
  const sitemateStartAccountId =
    searchParams.get('sitemateStartAccountId') ?? undefined;
  const reference = searchParams.get('reference') ?? undefined;
  const referenceWorkspaceId = searchParams.get('workspaceId') ?? undefined;

  return (
    <PageLayout>
      {reference ? (
        <SignUpReferral
          sitemateStartAccountId={sitemateStartAccountId}
          reference={reference}
          referenceWorkspaceId={referenceWorkspaceId}
        />
      ) : (
        <SignUp />
      )}
    </PageLayout>
  );
};
