import { FC } from 'react';

import { Combobox } from '@/components';
import { useSearchWorkspaces } from '@/hooks';
import { useFilterByContainsQuery } from '@/pages/flows/hooks/useFilteredItems';

type WorkspaceSwitcherProps = {
  width?: string;
  workspaceId?: string;
  onChangeWorkspace?: (workspaceId: string) => void;
};

interface IWorkspaceOption {
  _id: string;
  name: string;
}

export const WorkspaceSwitcher: FC<WorkspaceSwitcherProps> = ({
  width,
  workspaceId,
  onChangeWorkspace,
}) => {
  const { data = [] } = useSearchWorkspaces();
  const { setQuery, items: workspaces } = useFilterByContainsQuery(
    data,
    'name'
  );

  const workspacePath = `/${workspaceId}`;
  const selectedWorkspace = workspaces.find(
    (item) => item._id === workspacePath
  );

  const handleChange = (option: IWorkspaceOption) => {
    onChangeWorkspace?.(option._id);
  };

  return (
    <Combobox
      labelKey="name"
      valueKey="_id"
      emptyLabel="No workspace found"
      placeholder="Select..."
      hidePlaceholderOption
      width={width}
      options={workspaces}
      onInputChange={setQuery}
      onChange={handleChange}
      value={selectedWorkspace}
    />
  );
};
