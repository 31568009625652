/**
 * The available characters for generating a random string
 */
interface IAvailableCharacters {
  lowercase: string;
  uppercase: string;
  numbers: string;
  symbols: string;
}

/**
 * Options for generating a random string
 */
export interface IGenerateRandomStringOptions {
  hasUppercase?: boolean;
  hasNumbers?: boolean;
  hasSymbols?: boolean;
}

/**
 * Generates a random string
 * @param {number} length The length of the string. Must be greater than 0.
 * @param {IGenerateRandomStringOptions} options The options for the string. Defaults to having lowercase letters.
 * @returns {string} A random string
 */
export const generateRandomString = (
  length: number,
  options: IGenerateRandomStringOptions = {}
) => {
  const defaultChars: IAvailableCharacters = {
    lowercase: 'abcdefghijklmnopqrstuvwxyz',
    uppercase: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    numbers: '0123456789',
    symbols: '!@#$%^&*()_+~`|}{[];?><,./-=',
  };

  if (length <= 0) {
    throw new Error('Length must be greater than 0');
  }

  // use lowercase characters by default
  let chars = defaultChars.lowercase;

  // append sets of characters if required
  if (options.hasUppercase) {
    chars += defaultChars.uppercase;
  }
  if (options.hasNumbers) {
    chars += defaultChars.numbers;
  }
  if (options.hasSymbols) {
    chars += defaultChars.symbols;
  }

  let result = '';

  // append random characters until the length is reached
  for (let i = length; i > 0; i -= 1) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }

  return result;
};
