import { useQuery } from 'react-query';
import Container from 'typedi';

import { Template } from '@site-mate/dashpivot-shared-library';
import {
  IDropdownOption,
  MappingConfigService,
  MappingField,
} from '@site-mate/sitemate-flowsite-shared';

function determineDataId(data?: Template | IDropdownOption[]) {
  if (!data) {
    return undefined;
  }
  if (Array.isArray(data)) {
    return data.map((item) => item.id).join(',');
  }
  return data._id;
}

export const useOptionsResolver = (
  data?: Template | IDropdownOption[],
  fields?: MappingField[]
) => {
  const dataId = determineDataId(data);
  const fieldIds = fields?.map((field) => field._id).join(',');

  return useQuery({
    queryKey: ['workspace', 'fields', dataId, fieldIds],
    queryFn: async () => {
      if (!fields) {
        throw new Error("fields can't be undefined with enabled flag");
      }
      return (
        await Promise.all(
          fields.map(async (field) => {
            const resolver = Container.get(
              MappingConfigService
            ).getFieldDataMapperFactory(field.fieldType);
            return resolver.getOptions(field, data);
          })
        )
      )
        .flat()
        .filter((item) => item);
    },
    enabled: !!data && !!fields,
  });
};
