import { useCallback, useEffect, useState } from 'react';

import { IFlow, INodeType } from '@site-mate/sitemate-flowsite-shared';

import { Button, IButtonColor } from '@/components';
import { Spinner } from '@/components/elements/spinner';
import { FlowOptions } from '@/pages/flows/components/flow-card/';
import { FlowCard } from '@/pages/flows/components/flow-card/FlowCard';
import { useFlows } from '@/pages/flows/hooks/useFlows';
import { useWorkspaceContext } from '@/providers/WorkspaceProvider';
import { IFlowEvent } from '@/services/node.service';

import { FlowForm } from './FlowForm';

const QUERY_LIMIT = 10;

export const Flows = () => {
  const { workspaceId } = useWorkspaceContext();
  const [newFlow, setNewFlow] = useState<IFlow | undefined>(undefined);
  const [lastFlowPath, setLastFlowPath] = useState<string | undefined>();
  const [flows, setFlows] = useState<IFlow[]>([]);
  const { data, isLoading, isFetching } = useFlows(workspaceId, {
    limit: QUERY_LIMIT,
    lastFlowPath,
  });

  const shouldHideLoadMore = !data || data.length < QUERY_LIMIT || isFetching;

  useEffect(() => {
    setFlows([]);
  }, [workspaceId]);

  useEffect(() => {
    if (data) {
      setFlows((prevFlows) => {
        const flowMap = new Map(prevFlows.map((item) => [item._id, item]));
        data.forEach((newItem) => {
          flowMap.set(newItem._id, newItem);
        });
        return Array.from(flowMap.values());
      });
    }
  }, [workspaceId, data]);

  const handleFlowEvent = useCallback(
    async (event: IFlowEvent, flow: IFlow) => {
      switch (event) {
        case IFlowEvent.CREATE:
          setFlows((prevFlows) => [flow, ...prevFlows]);
          setNewFlow(undefined);
          break;
        case IFlowEvent.DELETE:
          if (flow._id) {
            const updatedFlows = flows.filter((f) => f._id !== flow._id);
            setFlows(updatedFlows);
          }
          break;
        case IFlowEvent.UPDATE:
          if (flow._id) {
            const updatedFlows = flows.map((f) =>
              f._id === flow._id ? flow : f
            );
            setFlows(updatedFlows);
          }
          break;
        default:
          break;
      }
    },
    [flows]
  );

  return (
    <div className="w-full">
      <h2 className="mb-4 mt-4 text-xl font-bold">Flows</h2>
      <div className="mb-4 flex items-center space-x-2">
        {FlowOptions &&
          FlowOptions.map((option) => (
            <FlowCard
              key={option.title}
              title={option.title}
              image={option.image}
              options={option.options}
              setNewFlow={setNewFlow}
            />
          ))}
      </div>
      {!!newFlow && (
        <FlowForm
          key={newFlow.nodes[0].type}
          flow={newFlow}
          onFlowEvent={handleFlowEvent}
        />
      )}
      {flows &&
        flows.map(
          (flow: IFlow) =>
            flow.nodes[0].type !==
              INodeType.MICROSOFT_SHAREPOINT_FORM_UPLOAD_V1 && ( // TODO: remove if Sharepoint flows are visible in React app
              <FlowForm
                key={flow._id}
                flow={flow}
                onFlowEvent={handleFlowEvent}
              />
            )
        )}
      <div className="flex flex-row">
        {flows && flows.length >= 10 && !shouldHideLoadMore && (
          <Button
            color={IButtonColor.GREY_2}
            onClick={() => setLastFlowPath(flows[flows.length - 1]._id)}
          >
            Load more
          </Button>
        )}
        {(isFetching || isLoading) && (
          <div className="mt-2 flex w-full items-center justify-center">
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};
