import {
  INodeType,
  INodeTypeIntercomMapping,
  IntegrationType,
} from '@site-mate/sitemate-flowsite-shared/lib';

import { FeatureToggles, isFeatureEnabled } from '@/common/feature-toggle';
import { ILogoTypes } from '@/components/elements/integration-logo/IntegrationLogo';
import { useCreateDashpivotBasicConnection } from '@/hooks';

import { handleConnect } from '../connections';

export interface IIntegrationCardProps {
  logoType: ILogoTypes;
  integrationName: string;
  description: string;
  isButtonEnabled: boolean;
  buttonText: string;
  clickHandler?: (workspaceId: string) => void;
  buttonLink?: string;
}

export interface IIntegrationRow {
  title: string;
  integrations: IIntegrationCardProps[];
}

type MutationHook = {
  createDashpivotConnection: ReturnType<
    typeof useCreateDashpivotBasicConnection
  >;
};

export const prepareIntegrationConfig = (hook: MutationHook) =>
  [
    {
      title: 'Sitemate Suite',
      integrations: [
        {
          integrationName: 'Dashpivot',
          description:
            'Connect to Dashpivot to allow data to be accessed by other softwares you connect.',
          logoType: ILogoTypes.DASHPIVOT,
          isButtonEnabled: true,
          buttonText: 'Connect',
          clickHandler: async () => {
            await hook.createDashpivotConnection.mutateAsync();
          },
        },
      ],
    },
    {
      title: 'Accounting',
      integrations: [
        {
          integrationName: 'Xero',
          description:
            'Automatically populate timesheets, invoices and bills with data from Dashpivot.',
          logoType: ILogoTypes.XERO,
          isButtonEnabled: true,
          buttonText: 'Connect',
          clickHandler: (workspaceId: string) =>
            handleConnect(IntegrationType.XERO, workspaceId),
        },
        {
          integrationName: 'QuickBooks',
          description:
            'Automatically populate invoices with data from Dashpivot.',
          logoType: ILogoTypes.QUICKBOOKS,
          isButtonEnabled: !!isFeatureEnabled(FeatureToggles.QUICKBOOKS),
          buttonText: isFeatureEnabled(FeatureToggles.QUICKBOOKS)
            ? 'Connect'
            : 'Coming soon',
          buttonLink: isFeatureEnabled(FeatureToggles.QUICKBOOKS)
            ? undefined
            : 'https://portal.productboard.com/wm6whau7st9md1ulztigrv4n/c/358-flowsite-quickbooks-payroll-integration',
          clickHandler: (workspaceId: string) =>
            handleConnect(IntegrationType.QUICKBOOKS, workspaceId),
        },
      ],
    },
    {
      title: 'Business Intelligence',
      integrations: [
        {
          integrationName: 'Microsoft Power BI',
          description: 'Enable Power BI to access forms data in Dashpivot.',
          logoType: ILogoTypes.MICROSOFT_POWER_BI,
          isButtonEnabled: true,
          buttonText: 'Get started',
          buttonLink: INodeTypeIntercomMapping[INodeType.POWERBI_CONNECTOR_V1],
        },
        {
          integrationName: 'Microsoft Excel',
          description: 'Enable Excel to access forms data in Dashpivot.',
          logoType: ILogoTypes.MICROSOFT_EXCEL,
          isButtonEnabled: true,
          buttonText: 'Get started',
          buttonLink: INodeTypeIntercomMapping[INodeType.EXCEL_CONNECTOR_V1],
        },
      ],
    },
    {
      title: 'File Storage',
      integrations: [
        {
          integrationName: 'Microsoft Sharepoint',
          description:
            'Automatically send data from Dashpivot into Sharepoint.',
          logoType: ILogoTypes.MICROSOFT_SHAREPOINT,
          isButtonEnabled: !!isFeatureEnabled(FeatureToggles.SHAREPOINT),
          buttonText: isFeatureEnabled(FeatureToggles.SHAREPOINT)
            ? 'Connect'
            : 'Coming soon',
          buttonLink: isFeatureEnabled(FeatureToggles.SHAREPOINT)
            ? undefined
            : 'https://portal.productboard.com/wm6whau7st9md1ulztigrv4n/c/350-flowsite-sharepoint-integration',
          clickHandler: (workspaceId: string) =>
            handleConnect(IntegrationType.MICROSOFT, workspaceId),
        },
      ],
    },
    {
      title: 'Communication',
      integrations: [
        {
          integrationName: 'Microsoft Teams',
          description:
            'Automatically notify your team when updates occur in your systems.',
          logoType: ILogoTypes.MICROSOFT_TEAMS,
          isButtonEnabled: false,
          buttonLink:
            'https://portal.productboard.com/wm6whau7st9md1ulztigrv4n/c/349-flowsite-microsoft-teams-integration',
          buttonText: 'Coming soon',
        },
      ],
    },
  ] as IIntegrationRow[];
