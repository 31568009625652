import {
  IFieldMappingConfigKey,
  IXeroInvoiceFieldMappingConfigKey,
} from '@site-mate/sitemate-flowsite-shared';

export const MappingLabels = new Map<string, string>([
  [IFieldMappingConfigKey.Date, 'date'],
  [IFieldMappingConfigKey.TrackingItem, 'tracking item'],
  [IXeroInvoiceFieldMappingConfigKey.Contact, 'contact'],
  [IXeroInvoiceFieldMappingConfigKey.Description, 'description'],
  [IXeroInvoiceFieldMappingConfigKey.Quantity, 'quantity'],
  [IXeroInvoiceFieldMappingConfigKey.Account, 'code'],
  [IXeroInvoiceFieldMappingConfigKey.Amount, 'amount'],
  [IXeroInvoiceFieldMappingConfigKey.TrackingCategory1, 'tracking category'],
  [IXeroInvoiceFieldMappingConfigKey.TrackingCategory2, 'tracking category'],
]);
