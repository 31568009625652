import { AuthProvider } from 'react-oidc-context';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import { useExternalSignIn } from '@/auth/useExternalAuth';
import { useInternalSignIn } from '@/auth/useInternalAuth';
import { onSigninCallback, userManager } from '@/auth/userManager';
import { setupMonitoring } from '@/common/monitoring';
import { queryClient } from '@/common/react-query';
import { Spinner } from '@/components/elements/spinner';
import { PrivateRoutes } from '@/routes/private';

const ErrorBoundary = setupMonitoring();

export const App = () => {
  const externalAuth = useExternalSignIn();
  const internalAuth = useInternalSignIn();

  if (externalAuth.isProcessing || internalAuth.isLoading) {
    return (
      <div className="flex h-screen items-center justify-items-center">
        <div className="m-auto">
          <Spinner />
        </div>
      </div>
    );
  }

  if (externalAuth.error) {
    return (
      <div className="p-4">
        <div>Connection failed - Error: {externalAuth.error}</div>
        <a href="/">Refresh</a> and try again.
      </div>
    );
  }

  if (internalAuth.isSigningIn) {
    return <div>Signing in...</div>;
  }

  if (internalAuth.isSigningOut) {
    return <div>Signing out...</div>;
  }

  if (internalAuth.error) {
    return (
      <>
        <div> Login error: {internalAuth.error.message} </div>
        <a href="/">Refresh</a> and try again
      </>
    );
  }

  if (!internalAuth.isAuthenticated) {
    return (
      <>
        <div> Sorry, we are having trouble logging you in</div>
        <a href="/">Refresh</a> and try again
      </>
    );
  }

  return <PrivateRoutes />;
};

export const WrappedApp = () => (
  <ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <AuthProvider
        userManager={userManager}
        onSigninCallback={onSigninCallback}
      >
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthProvider>
    </QueryClientProvider>
  </ErrorBoundary>
);
