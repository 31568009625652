import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { IUserProfile } from '@site-mate/sitemate-global-shared';

import { api } from '@/common/api';

function getUsers(workspaceId: string): () => Promise<IUserProfile[]> {
  return () => api.get(`workspaces/${workspaceId}/users`);
}

/**
 * Fetches all the users associated with a workspace
 * @param {string} workspaceId
 * @returns list of users
 */
export function useUsers(workspaceId: string) {
  return useQuery({
    queryKey: ['workspace', workspaceId, 'users'],
    queryFn: getUsers(workspaceId),
    refetchOnWindowFocus: false,
  });
}

/**
 * Get the user profile for a specific email address
 * @param {string} email
 * @returns user profile if it exists
 */
export function useGetUserForEmail(email: string) {
  return useQuery({
    queryKey: ['user', email],
    queryFn: () => api.get(`/users?email=${email}`),
    refetchOnWindowFocus: false,
    enabled: false,
  });
}

/**
 * Invite a user to a workspace
 * @param {string} workspaceId - id of the workspace to invite the user to
 * @param {string} email - email of the user to invite
 * @returns updated workspace
 */
export function useInviteUserToWorkspace(workspaceId: string) {
  return useMutation<
    { email: string; workspaceId: string },
    AxiosError,
    { email: string }
  >({
    mutationFn: (email) =>
      api.post(`/workspaces/${workspaceId}/users/invite`, {
        email,
      }),
  });
}

/**
 * Remove a user from a workspace
 * @param {string} workspaceId - workspace to remove the user from
 * @param {string} email - email of the user to remove
 * @returns updated workspace
 */
export function useRemoveUserFromWorkspace(workspaceId: string) {
  const queryClient = useQueryClient();
  return useMutation<
    { email: string; workspaceId: string },
    AxiosError,
    { email: string }
  >({
    mutationFn: (email) =>
      api.post(`/workspaces/${workspaceId}/users/uninvite`, email),
    onSuccess: () => {
      queryClient.invalidateQueries(['workspace', workspaceId, 'users']);
    },
  });
}
