import { User, UserManager, UserManagerSettings } from 'oidc-client-ts';

import { fusionAuthConfig } from './fusionAuth.config';
import { ISignInState } from './useInternalAuth';

/**
 * The UserManager used by the AuthProvider.
 * Exposing this allows us to use the UserManager directly in our app without having to use the AuthContext.
 * Creating multiple instances of UserManager for the same clientId causes issues when trying to refresh the tokens.
 *
 * https://github.com/authts/react-oidc-context/pull/466
 */
const userManager = new UserManager(fusionAuthConfig as UserManagerSettings);

const onSigninCallback = (user: void | User): void => {
  const state = user?.state as ISignInState;

  // Strips url params after login
  window.history.replaceState({}, document.title, window.location.pathname);

  // Path is set as state on signin if present
  if (state?.path) {
    const searchParams = state.searchParams ?? '';
    window.location.replace(`${state.path}${searchParams}`);
  }
};

export { userManager, onSigninCallback };
