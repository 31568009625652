import Loading from '@/assets/loading.svg';

export const Spinner = () => (
  <div className="relative">
    <img
      src={Loading}
      alt="Loading"
      className="relative flex animate-spin items-center justify-center"
      aria-label="loading"
      role="status"
    />
    <span className="sr-only">Loading...</span>
  </div>
);
