import { OidcClientSettings } from 'oidc-client-ts';

export const microsoftConfig: OidcClientSettings = {
  authority: 'https://login.microsoftonline.com/organizations/v2.0',
  client_authentication: 'client_secret_basic',
  client_id: import.meta.env.PUBLIC_MICROSOFT_CLIENT_ID,
  disablePKCE: true,
  redirect_uri: `${window.location.origin}${
    import.meta.env.PUBLIC_MICROSOFT_REDIRECT_PATH
  }`,
  response_type: 'code',
  scope:
    'openid profile email offline_access https://graph.microsoft.com/User.Read https://graph.microsoft.com/Sites.Manage.All',
};
