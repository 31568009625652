import { useQuery, useQueryClient } from 'react-query';

import { Template } from '@site-mate/dashpivot-shared-library';
import { IntegrationType } from '@site-mate/sitemate-flowsite-shared';

import { api } from '@/common/api';
import { useConnectionsWithType } from '@/hooks/useConnections';
import { IRequestParameterItem } from '@/pages/flows/types';

function getTemplate(
  workspaceId: string,
  templateId: string,
  connectionId?: string
) {
  return async (): Promise<Template> => {
    if (!connectionId) {
      throw new Error(
        'Unable to get template: please add a Dashpivot connection'
      );
    }
    const res = await api.post(`workspaces/${workspaceId}/editor/options`, {
      connectionId,
      optionSourceKey: 'dashpivot-template',
      parameters: {
        routeParameters: [
          {
            key: 'templateId',
            value: templateId,
          },
        ],
        queryParameters: [],
        headers: [],
      },
    });

    if (!res.data) {
      throw new Error(
        'Template not found (please check your provided template id)'
      );
    }

    return res.data;
  };
}

export function useRemoveTemplateQuery() {
  const queryClient = useQueryClient();

  return {
    removeTemplateId: (templateId: string) => {
      queryClient.removeQueries(['workspace', 'template', templateId]);
    },
  };
}

export function useTemplate(
  workspaceId: string,
  templateId: string,
  options?: {
    onSuccess?: (data: Template) => void;
    onError?: (error: Error) => void;
  }
) {
  const dashpivotConnections = useConnectionsWithType(
    IntegrationType.DASHPIVOT
  );
  return useQuery<Template, Error>({
    queryKey: ['workspace', 'template', templateId],
    queryFn: getTemplate(
      workspaceId,
      templateId,
      dashpivotConnections.data?.length
        ? dashpivotConnections.data[0]._id
        : undefined
    ),
    staleTime: 10 * 60 * 1000,
    enabled:
      dashpivotConnections.isSuccess &&
      !dashpivotConnections.isRefetching &&
      templateId !== '',
    ...options,
  });
}

function getTemplateList(
  workspaceId: string,
  companyId: string,
  connectionId?: string
) {
  return (): Promise<IRequestParameterItem[]> => {
    if (!connectionId) {
      throw new Error(
        `Unable to get template list: please add a Dashpivot connection`
      );
    }
    return api
      .post(`workspaces/${workspaceId}/editor/options`, {
        connectionId,
        optionSourceKey: 'dashpivot-templates',
        parameters: {
          routeParameters: [
            {
              key: 'companyId',
              value: companyId,
            },
          ],
          queryParameters: [],
          headers: [],
        },
      })
      .then((resp) => resp.data);
  };
}

export function useTemplateList(workspaceId: string, companyId: string) {
  const dashpivotConnections = useConnectionsWithType(
    IntegrationType.DASHPIVOT
  );
  return useQuery({
    queryKey: ['workspace', workspaceId, 'template-list', companyId],
    queryFn: getTemplateList(
      workspaceId,
      companyId,
      dashpivotConnections.data?.length
        ? dashpivotConnections.data[0]._id
        : undefined
    ),
    enabled: dashpivotConnections.isSuccess,
  });
}
