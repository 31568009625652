import clsx from 'clsx';

import DashpivotLogo from '@/assets/integrations/icons/logo-dashpivot.svg';
import MicrosoftExcelLogo from '@/assets/integrations/icons/logo-microsoft-excel.svg';
import MicrosoftPowerBILogo from '@/assets/integrations/icons/logo-microsoft-power-bi.svg';
import MicrosoftSharepointLogo from '@/assets/integrations/icons/logo-microsoft-sharepoint.svg';
import MicrosoftTeamsLogo from '@/assets/integrations/icons/logo-microsoft-teams.svg';
import QuickbooksLogo from '@/assets/integrations/icons/logo-quickbooks.svg';
import XeroLogo from '@/assets/integrations/icons/logo-xero.svg';

export enum ILogoTypes {
  DASHPIVOT = 'Dashpivot',
  MICROSOFT_EXCEL = 'Microsoft Excel',
  MICROSOFT_POWER_BI = 'Microsoft Power BI',
  MICROSOFT_SHAREPOINT = 'Microsoft Sharepoint',
  MICROSOFT_TEAMS = 'Microsoft Teams',
  QUICKBOOKS = 'Quickbooks',
  XERO = 'Xero',
}

export const LogoTypes: Record<ILogoTypes, string> = {
  [ILogoTypes.DASHPIVOT]: DashpivotLogo,
  [ILogoTypes.MICROSOFT_EXCEL]: MicrosoftExcelLogo,
  [ILogoTypes.MICROSOFT_POWER_BI]: MicrosoftPowerBILogo,
  [ILogoTypes.MICROSOFT_SHAREPOINT]: MicrosoftSharepointLogo,
  [ILogoTypes.MICROSOFT_TEAMS]: MicrosoftTeamsLogo,
  [ILogoTypes.QUICKBOOKS]: QuickbooksLogo,
  [ILogoTypes.XERO]: XeroLogo,
};

interface IntegrationLogoProps {
  integrationLogo: ILogoTypes;
  className?: string;
}

export const IntegrationLogo = ({
  integrationLogo,
  className,
}: IntegrationLogoProps) => {
  const defaultClassName = clsx('h-10', className);

  return (
    <img
      src={LogoTypes[integrationLogo]}
      className={defaultClassName}
      alt={`${integrationLogo} Logo`}
    />
  );
};
