import { useState } from 'react';

import { IAccountSummary, Maybe } from '@site-mate/sitemate-flowsite-shared';

import WelcomeImage from '@/assets/svg/welcome.svg';
import {
  Modal,
  ModalPositions,
  Button,
  IButtonColor,
  Combobox,
} from '@/components';
import { useFilterByContainsQuery } from '@/pages/flows/hooks';

export const AccountSelectorModal = ({
  open,
  accounts,
  onSelectAccount,
}: {
  open: boolean;
  accounts: IAccountSummary[];
  onSelectAccount: (accountId: string) => void;
}) => {
  const { setQuery, items: filteredAccounts } = useFilterByContainsQuery(
    accounts,
    'name'
  );

  const [selectedAccount, setSelectedAccount] =
    useState<Maybe<IAccountSummary>>();

  const handleAccountChange = (account: IAccountSummary) => {
    setSelectedAccount(account);
  };

  const handleClick = () => {
    if (selectedAccount) {
      onSelectAccount(selectedAccount._id);
    }
  };

  return (
    <Modal open={open} position={ModalPositions.Top}>
      <div className="flex flex-col items-center text-center align-middle leading-normal text-default-text">
        <img
          src={WelcomeImage}
          alt="Welcome to Flowsite"
          className="w-70 pb-4"
        />
        <h1 className="pb-4 text-xl font-medium">Welcome to Flowsite</h1>
        <p className="pb-4">
          Flowsite is an integrations platform that allows you to connect your
          systems and automate processes with zero coding experience.
        </p>
        <p className="pb-4">
          Which Sitemate Start Account are you looking to access Flowsite for?
        </p>
        <Combobox
          className="pb-4 text-left"
          placeholder="Select..."
          hidePlaceholderOption
          options={filteredAccounts}
          labelKey="name"
          valueKey="_id"
          value={selectedAccount}
          onInputChange={setQuery}
          onChange={handleAccountChange}
        />
        <Button
          disabled={!selectedAccount}
          color={IButtonColor.BLUE}
          onClick={handleClick}
        >
          Continue
        </Button>
      </div>
    </Modal>
  );
};
