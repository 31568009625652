import { Button, IButtonColor, IButtonSize } from '@/components';
import { IntegrationLogo } from '@/components/elements/integration-logo/IntegrationLogo';
import { useWorkspaceContext } from '@/providers/WorkspaceProvider';

import { IIntegrationCardProps } from './integration.config';

export const IntegrationCard = ({
  logoType,
  integrationName,
  description,
  isButtonEnabled,
  buttonText,
  clickHandler,
  buttonLink,
}: IIntegrationCardProps) => {
  const { workspaceId } = useWorkspaceContext();

  return (
    <div className="flex h-[238px] w-[235px] flex-col justify-between rounded bg-white p-4 align-middle shadow">
      <IntegrationLogo integrationLogo={logoType} className="mb-2" />
      <div>
        <h3 className="mb-2 text-center font-bold">{integrationName}</h3>
        <p className="mb-2 text-start text-sm font-normal">{description}</p>
      </div>
      <Button
        color={isButtonEnabled ? IButtonColor.GREEN : IButtonColor.GREY_1}
        size={IButtonSize.DEFAULT}
        as={buttonLink ? 'a' : undefined}
        href={buttonLink}
        onClick={
          clickHandler && isButtonEnabled && !buttonLink
            ? () => clickHandler(workspaceId)
            : undefined
        }
      >
        {buttonText}
      </Button>
    </div>
  );
};
