import { ITriggerFilterFlowMetadata } from '@site-mate/sitemate-flowsite-shared';

export const generateTriggerFilterMetadata =
  (): ITriggerFilterFlowMetadata => ({
    triggerFilter: {
      pathRegex: '',
      templateId: '',
      isInColumn: 0,
    },
  });
