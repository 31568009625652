import { FC, useMemo } from 'react';
import { Control } from 'react-hook-form';

import { Dropdown } from '@/components';
import { ICombinedBillingSettings } from '@/hooks';

interface SecurityTableProps {
  control: Control<ICombinedBillingSettings>;
  editable: boolean;
}

export const SecurityTable: FC<SecurityTableProps> = ({
  control,
  editable,
}) => {
  const disabled = !editable;

  const ssoOptions = useMemo(() => ['On', 'Off'], []);

  return (
    <>
      <p className="text-sm font-bold">Security</p>
      <table className="w-full border-collapse border bg-white text-left">
        <tbody>
          <tr className="h-14">
            <td className="border-slate-500 w-1/2 space-x-2 border p-2 align-top text-sm">
              <span className="h-full w-1/2 text-default-text">
                Single sign-on (SSO)
              </span>
            </td>
            <td className="border-slate-500 w-1/2 border p-2 text-sm">
              <Dropdown
                name="ssoEnabled"
                control={control}
                disabled={disabled}
                options={ssoOptions}
                width="w-80"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
