import {
  IConnection,
  IntegrationType,
  IOptionSourceKeys,
  IXeroPayrollType,
} from '@site-mate/sitemate-flowsite-shared';

import { useConnectionsWithType } from '@/hooks/useConnections';
import { useEditorOptions } from '@/pages/flows/hooks/useEditorOptions';

/**
 * Retrieve the source key for xero connection
 *
 * @param xeroConnection - the specific connection from which to get the source key
 * @returns
 */
export function getSourceKey(xeroConnection?: IConnection) {
  const payrollType =
    xeroConnection?.parameters?.find(
      (param) => param.key === 'xero-payroll-type'
    )?.value || IXeroPayrollType.AU;

  return payrollType === IXeroPayrollType.AU
    ? IOptionSourceKeys.XeroPayrollAU
    : IOptionSourceKeys.XeroPayrollUKNZ;
}

/**
 * Retrieves earnings rates from Xero (through the fs api)
 *
 * @param workspaceId - the xero workspace id for which to get xero earnings rates
 * @param connectionId - the specific connection id - contains the credentials to use to get the xero earnings rates
 * @returns
 */
export function useXeroEarningsRates(
  workspaceId: string,
  connectionId?: string
) {
  const xeroConnections = useConnectionsWithType(IntegrationType.XERO);

  const xeroConnection = xeroConnections.data?.find(
    (connection: IConnection) => connection._id === connectionId
  );

  return useEditorOptions(
    workspaceId,
    connectionId,
    getSourceKey(xeroConnection)
  );
}

export function useXeroTrackingItems(
  workspaceId: string,
  connectionId?: string
) {
  return useEditorOptions(
    workspaceId,
    connectionId,
    IOptionSourceKeys.XeroAccountingTrackingCategories
  );
}

/**
 * Retrieves account codes from Xero (through the fs api)
 *
 * @param workspaceId - the xero workspace id for which to get xero account codes
 * @param connectionId - the specific connection id - contains the credentials to use to get the xero account codes
 * @returns
 */
export function useXeroAccountCodes(
  workspaceId: string,
  connectionId?: string
) {
  return useEditorOptions(
    workspaceId,
    connectionId,
    IOptionSourceKeys.XeroAccountingAccounts
  );
}
