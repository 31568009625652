import { Template } from '@site-mate/dashpivot-shared-library';

const TEMPLATE_PATH_PATTERN = '/template:';

export function getFolderPath(template: Template) {
  if (!template.path) {
    return '';
  }
  const { path } = template;
  const templatePathIndex = path.indexOf(TEMPLATE_PATH_PATTERN);
  return path.substring(0, templatePathIndex);
}
