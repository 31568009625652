import { IFlow } from '@site-mate/sitemate-flowsite-shared';

import { TriggerComponents } from '@/pages/flows/components/nodes/TriggerComponents';

export type TriggerProps = {
  flow: IFlow;
  onFlowChange: (flow: IFlow) => void;
};

export const Trigger = (props: TriggerProps) => {
  const { flow } = props;
  const firstNode = flow.nodes[0]; // TODO only supports single node configs for now

  if (!firstNode) {
    return <p>No node on flow</p>;
  }

  const TriggerComponent = TriggerComponents[firstNode.type];

  return <TriggerComponent {...props} />;
};
