import clsx from 'clsx';
import { ReactElement, ReactNode, FC, Children, isValidElement } from 'react';
import { Link, useLocation } from 'react-router-dom';

export type INavBarProps = {
  children?: ReactNode;
  /** Hides the content of the nav bar */
  contentHidden?: boolean;
};

type ILogoProps = {
  source: string;
  heading: string;
  // eslint-disable-next-line react/no-unused-prop-types
  type?: IChildrenTypes;
};

type INavLinkProps = {
  url: string;
  children: ReactNode;
  icon?: string;
  disabled?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  type?: IChildrenTypes;
};

type IRightItemsProps = {
  children?: ReactNode;
  // eslint-disable-next-line react/no-unused-prop-types
  type?: IChildrenTypes;
};

type INavbarComponent = FC<INavBarProps> & {
  Logo: FC<ILogoProps>;
  NavLink: FC<INavLinkProps>;
  RightItems: FC<IRightItemsProps>;
};

enum IChildrenTypes {
  Logo = 'Logo',
  NavLink = 'NavLink',
  RightItems = 'RightItems',
}

function returnFuncComponent(
  children: ReactNode | ReactNode[],
  type: IChildrenTypes
) {
  return Children.map(children, (child) => {
    if (isValidElement(child) && child.props.type === type) {
      return child;
    }
    return null;
  });
}

const NavBar: INavbarComponent = ({ children, contentHidden = false }) => {
  const logos = returnFuncComponent(children, IChildrenTypes.Logo);
  const links = returnFuncComponent(children, IChildrenTypes.NavLink);
  const rightItems = returnFuncComponent(children, IChildrenTypes.RightItems);

  return (
    <nav className="sticky top-0 z-20 flex h-14 items-center justify-between border-b-2 border-grey-3 bg-white">
      {!contentHidden && (
        <>
          <div className="flex h-full items-center space-x-2 pl-2">
            {logos ? logos.map((logo: ReactElement) => logo) : null}
            <div className="flex h-full">
              {links ? links.map((link: ReactElement) => link) : null}
            </div>
          </div>
          {rightItems}
        </>
      )}
    </nav>
  );
};

const Logo: FC<ILogoProps> = ({ source, heading }) => (
  <div className="flex h-12 items-center space-x-6">
    <img src={source} alt="Logo" className="flex h-full w-36" />
    <h1 className="hidden">{heading}</h1>
  </div>
);

Logo.defaultProps = {
  type: IChildrenTypes.Logo,
};

function isTarget(url: string, location: string) {
  return url === location;
}

const NavLink: FC<INavLinkProps> = ({
  url,
  children,
  icon,
  disabled = false,
}) => {
  const location = useLocation();

  return (
    <div
      className={`full group box-border flex h-14 border-b-4 text-sm font-normal leading-4 group-hover:text-default-text ${
        isTarget(url, location.pathname)
          ? 'border-b-default-blue text-default-text'
          : 'border-b-transparent'
      }`}
    >
      <Link
        to={url}
        className={clsx(
          'flex h-14 w-full items-center justify-start space-x-1 px-2 text-default-text no-underline visited:text-secondary-text',
          disabled && 'pointer-events-none'
        )}
      >
        {icon ? (
          <i
            className={`${icon} group-hover:text-default-text ${
              isTarget(url, location.pathname)
                ? 'text-default-text'
                : 'text-secondary-text'
            }`}
            aria-hidden="true"
          />
        ) : null}
        <div
          className={`group-hover:text-default-text ${
            isTarget(url, location.pathname)
              ? 'text-default-text'
              : 'text-secondary-text'
          }`}
        >
          {children}
        </div>
      </Link>
    </div>
  );
};

NavLink.defaultProps = {
  type: IChildrenTypes.NavLink,
};

const RightItems: FC<IRightItemsProps> = ({ children }) => (
  <div className="flex items-center space-x-2 pr-1">{children ?? null}</div>
);

RightItems.defaultProps = {
  type: IChildrenTypes.RightItems,
};

NavBar.Logo = Logo;
NavBar.NavLink = NavLink;
NavBar.RightItems = RightItems;

export { NavBar };
