import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Button, Spinner } from '@/components';
import {
  ICombinedBillingSettings,
  useUpdateSitemateStartBilling,
} from '@/hooks';
import {
  FlowsiteBillingTable,
  GlobalBillingTable,
  SecurityTable,
} from '@/pages/settings/components';
import { useWorkspaceContext } from '@/providers/WorkspaceProvider';

interface IBillingFormProps {
  billingData?: ICombinedBillingSettings;
  editable: boolean;
  loading: boolean;
  // TODO: remove when Flowsite billing support is completely dropped for SMS billing
  legacyBillingPath?: string;
}

export const BillingForm: FC<IBillingFormProps> = ({
  billingData,
  editable,
  loading: billingLoading,
  legacyBillingPath,
}) => {
  // to prevent flickering when updating both account and billing
  const { accountId } = useWorkspaceContext();
  const { mutate: updateBilling, isLoading: mutateBillingLoading } =
    useUpdateSitemateStartBilling(accountId, legacyBillingPath);

  const disabled = !editable;

  const { control, register, handleSubmit, getValues, reset } =
    useForm<ICombinedBillingSettings>({
      defaultValues: {
        ...billingData,
      },
    });

  const onSubmit = async (formData: ICombinedBillingSettings) => {
    const submitData = {
      ...billingData,
      ...formData,
    };

    /* Types don't allow for checking for empty string or setting value to null
     * so we set it manually - this is all so that null gets sent to salesforce for number and date values
     */
    Object.keys(submitData).forEach((key) => {
      // @ts-ignore
      if (submitData[key] === '') {
        // @ts-ignore
        submitData[key] = null as unknown as undefined;
      }
    });

    submitData.paymentTermDay =
      submitData.paymentTermDay === ('' as unknown as undefined) ||
      submitData.paymentTermDay === null
        ? (null as unknown as undefined)
        : Number(submitData.paymentTermDay);

    updateBilling(submitData as ICombinedBillingSettings);
  };

  useEffect(() => {
    if (billingData) {
      reset(billingData);
    }
  }, [billingData, reset]);

  if (billingLoading) {
    return (
      <div className="flex w-full flex-col items-start space-y-2">
        <h2 className="text-xl font-bold">Sitemate Start</h2>
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col items-start space-y-2">
      <h2 className="text-xl font-bold">Sitemate Start</h2>

      {!billingData ? null : (
        <form
          className="w-full space-y-2"
          onSubmit={handleSubmit(() => onSubmit(getValues()))}
        >
          <GlobalBillingTable
            control={control}
            register={register}
            editable={editable}
          />
          <FlowsiteBillingTable
            control={control}
            register={register}
            editable={editable}
          />
          <SecurityTable control={control} editable={editable} />

          {/* TODO: restore !formState.isDirty as extra condition when a working solution for synching billing data to Salesforce is implemented */}
          {mutateBillingLoading ? (
            <Spinner />
          ) : (
            <Button disabled={disabled} type="submit">
              Save
            </Button>
          )}
        </form>
      )}
    </div>
  );
};
