import NoResultImage from '@/assets/svg/no-results.svg';
import { HelpArticles } from '@/common';
import { Modal, ModalPositions, Button, IButtonColor } from '@/components';

export const WorkspaceExistsModal = ({ open }: { open: boolean }) => (
  <Modal open={open} position={ModalPositions.Top}>
    <div className="flex flex-col items-center text-center align-middle leading-normal">
      <img
        src={NoResultImage}
        alt="No workspaces available"
        className="mb-4 h-6.25"
      />
      <h1 className="mb-4 text-xl font-medium">No workspaces available</h1>
      <p className="mb-4 ">
        You don’t have access to any Flowsite workspaces. Please contact your
        admin to gain access or create a new Flowsite workspace.
      </p>
      <Button
        as="a"
        className="mt-4"
        color={IButtonColor.BLUE}
        href={HelpArticles.InvitingUsersToFlowsite}
      >
        How to Invite Users
      </Button>
    </div>
  </Modal>
);
