import clsx from 'clsx';
import debounce from 'lodash/debounce';
import {
  Dispatch,
  FC,
  HTMLProps,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import {
  FieldError,
  UseFormClearErrors,
  UseFormRegisterReturn,
  UseFormSetError,
} from 'react-hook-form';

import { Maybe } from '@site-mate/sitemate-flowsite-shared';
import { IAccount } from '@site-mate/sitemate-global-shared';

import { useSitemateStartAccount } from '@/hooks/useSitemateStartAccount';

export type WithSitemateAccountId = { sitemateAccountId: string };

interface ISitemateStartAccountIdInput {
  className?: HTMLProps<HTMLElement>['className'];
  id?: string;
  sitemateAccountIdRegister: UseFormRegisterReturn<'sitemateAccountId'>;
  setError: UseFormSetError<WithSitemateAccountId>;
  clearErrors: UseFormClearErrors<WithSitemateAccountId>;
  errors?: FieldError;
  disabled?: boolean;
  setValidationError?: Dispatch<SetStateAction<boolean>>;
  placeholder?: string;
  onAccountChange?: (sitemateAccount: Maybe<IAccount>) => void;
}

export const SitemateStartAccountIdInput: FC<ISitemateStartAccountIdInput> = ({
  className,
  id,
  sitemateAccountIdRegister,
  setError,
  clearErrors,
  errors,
  disabled = false,
  setValidationError,
  placeholder,
  onAccountChange,
}) => {
  const errorMessage =
    'Sitemate Start Account could not be found with this ID.';
  const [debouncedInput, setDebouncedInput] = useState('');

  const debouncedFunc = debounce((value: string) => {
    setDebouncedInput(value);
  }, 1000);

  const debounceOnInputChange = (value: string) => {
    onAccountChange?.(undefined);

    debouncedFunc(value);
  };

  const { data: smsAccount, isLoading: smsAccountLoading } =
    useSitemateStartAccount(debouncedInput);

  useEffect(() => {
    if (debouncedInput && !smsAccountLoading && !smsAccount) {
      setError('sitemateAccountId', {
        type: 'account-missing',
        message: errorMessage,
      });
      setValidationError?.(true);
    } else if (smsAccount) {
      onAccountChange?.(smsAccount ?? undefined);
      setValidationError?.(false);
      clearErrors('sitemateAccountId');
    }
  }, [
    clearErrors,
    debouncedInput,
    setError,
    setValidationError,
    smsAccount,
    smsAccountLoading,
    onAccountChange,
  ]);

  return (
    <>
      <input
        id={id}
        {...sitemateAccountIdRegister}
        onChange={(e) => {
          sitemateAccountIdRegister.onChange(e);
          debounceOnInputChange(e.target.value);
        }}
        disabled={disabled}
        aria-label="sitemateAccountId"
        type="text"
        placeholder={placeholder}
        className={clsx(
          'rounded-md border-2 bg-white p-1.4 text-sm leading-normal disabled:cursor-not-allowed disabled:bg-grey-1 disabled:text-default-text',
          errors ? '!border-default-red' : 'border-grey-3',
          className
        )}
      />
      {errors && (
        <span className="text-xs leading-sm-18 text-default-red">
          {errors.message}
        </span>
      )}
    </>
  );
};
