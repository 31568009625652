import { Listbox, Transition } from '@headlessui/react';
import clsx from 'clsx';
import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  PathValue,
} from 'react-hook-form';

export interface IDropdownOption {
  id: string;
  label: string;
  value?: string | number;
}

type DropdownOption = IDropdownOption | (string | number);

export type DropdownProps = {
  /**
   * Additional class names to apply to the dropdown.
   */
  className?: string;
  /**
   * If true, the dropdown is disabled.
   * @default false
   */
  disabled?: boolean;
  /**
   * Name attribute of the dropdown. Also used as a testid prefix.
   */
  name?: string;
  /**
   * Array of options to display.
   */
  options: DropdownOption[];
  /**
   * The dropdown placeholder.
   */
  placeholder?: string;
  /**
   * The selected option.
   */
  value?: DropdownOption;
  /**
   * The width of the dropdown.
   * @default 'w-50' (200px)
   */
  width?: string;
  /**
   * Callback when the selected option changes.
   * @param option The new selected option.
   */
  onChange?(option?: DropdownOption): void;
};

const DropdownItem = ({ label, value }: Partial<IDropdownOption>) => (
  <Listbox.Option
    value={value}
    className="box-border p-1 rounded whitespace-pre-wrap cursor-pointer hover:bg-grey-1 focus-visible:bg-grey-1 ui-active:bg-grey-1 text-sm text-default-text"
  >
    {label}
  </Listbox.Option>
);

const BaseDropdown = ({
  className,
  disabled = false,
  name,
  options,
  placeholder,
  value,
  width = 'w-50',
  ...props
}: DropdownProps) => {
  const isFalsyValue = value === undefined || value === null || value === '';

  const selected: IDropdownOption | undefined =
    !isFalsyValue && (typeof value === 'string' || typeof value === 'number')
      ? { id: String(value), label: String(value), value }
      : (value as IDropdownOption);
  return (
    <div className={clsx('relative inline-block', width, className)}>
      <Listbox name={name} by="value" disabled={disabled} {...props}>
        {({ open }) => (
          <>
            <Listbox.Button
              className={clsx(
                'w-full box-border border-2 border-grey-3 p-1.4 flex items-center justify-between rounded whitespace-preleading-normal bg-white',
                'ui-open:border-default-blue hover:bg-grey-1 focus-visible:border-default-blue',
                'ui-disabled:bg-grey-1 ui-disabled:cursor-not-allowed ui-disabled:text-default-text',
                selected?.value !== undefined
                  ? 'text-default-text'
                  : 'text-grey-5'
              )}
              data-testid={`${name}-dropdown`}
            >
              <span className="truncate text-sm leading-normal">
                {selected ? selected.label : placeholder}
              </span>
              <i
                aria-hidden="true"
                className="cc-icon cc-icon-arrow-down-small text-xl  text-default-text ui-disabled:text-grey-5 leading-5"
              />
            </Listbox.Button>

            <Transition
              className="absolute w-full z-20"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              show={open}
            >
              <Listbox.Options
                static
                className="bg-white p-1 max-h-80 overflow-auto rounded border-2 border-grey-3 shadow-sm-2 focus-visible:outline-none sm:text-sm"
              >
                {placeholder && (
                  <DropdownItem
                    key={placeholder}
                    label={placeholder}
                    value=""
                  />
                )}

                {options.map((option: DropdownOption) =>
                  typeof option === 'string' || typeof option === 'number' ? (
                    <DropdownItem
                      key={option}
                      label={String(option)}
                      value={option}
                    />
                  ) : (
                    <DropdownItem key={option.id} {...option} />
                  )
                )}
              </Listbox.Options>
            </Transition>
          </>
        )}
      </Listbox>
    </div>
  );
};

export const Dropdown = <TFieldValues extends FieldValues>({
  control,
  name,
  ...props
}: {
  control?: Control<TFieldValues>;
  name?: FieldPath<TFieldValues>;
} & DropdownProps) =>
  control && name ? (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref, onChange, ...field } }) => (
        <BaseDropdown
          {...field}
          {...props}
          onChange={(newValue) =>
            onChange(
              newValue as PathValue<TFieldValues, FieldPath<TFieldValues>>
            )
          }
        />
      )}
    />
  ) : (
    <BaseDropdown name={name} {...props} />
  );
