import clsx from 'clsx';
import { ChangeEventHandler, forwardRef } from 'react';

export type TextInputProps = {
  className?: string;
  dataTestId?: string;
  disabled?: boolean;
  id?: string;
  name?: string;
  placeholder?: string;
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

export const TextInput = forwardRef(
  (
    {
      className,
      disabled = false,
      dataTestId,
      id,
      name,
      placeholder,
      value,
      onChange,
    }: TextInputProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => (
    <input
      id={id}
      name={name}
      data-testid={dataTestId}
      type="text"
      className={clsx(
        className,
        'box-border relative border-slate-300 border-2 border-grey-3 disabled:bg-grey-1 rounded-md p-1.4',
        'leading-normal text-sm text-default-text'
      )}
      value={value}
      disabled={disabled}
      placeholder={placeholder}
      onChange={onChange}
      ref={ref}
    />
  )
);

TextInput.displayName = 'TextInput';
