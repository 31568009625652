import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { WorkspaceSwitcher } from '@/pages/navigation/components/WorkspaceSwitcher';
import { useWorkspaceContext } from '@/providers/WorkspaceProvider';

import { Modal, ModalPositions } from './Modal';

interface WorkspaceSwitcherModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const WorkspaceSwitcherModal = ({
  isOpen,
  onClose,
}: WorkspaceSwitcherModalProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { workspaceId } = useWorkspaceContext();

  const handleSwitchWorkspace = (targetWorkspaceId: string) => {
    queryClient.removeQueries({ queryKey: 'sitemate-account' });
    navigate(`/workspaces${targetWorkspaceId}`);
  };

  return (
    <Modal
      open={isOpen}
      position={ModalPositions.Top}
      onClose={() => onClose()}
    >
      <div className="flex flex-col">
        <div className="flex flex-row items-start justify-between">
          <h1 className="mb-4 text-lg font-medium">Switch workspace</h1>
          <Modal.CloseButton onClose={() => onClose()} />
        </div>
        <div className="w-full">
          <WorkspaceSwitcher
            width="w-full"
            workspaceId={workspaceId || ''}
            onChangeWorkspace={handleSwitchWorkspace}
          />
        </div>
      </div>
    </Modal>
  );
};
