import { ReactNode } from 'react';

interface Props {
  children?: ReactNode;
}

export const PageLayout = ({ children }: Props) => (
  <div className="flex flex-grow flex-col items-center space-y-2 overflow-auto bg-grey-1 xl:px-50 lg:px-40 px-10 pb-6">
    <div className="flex flex-grow flex-col max-w-[1200px] w-full">
      {children}
    </div>
  </div>
);
