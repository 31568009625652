import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { IWorkspace, PathService } from '@site-mate/sitemate-flowsite-shared';
import { PathUtility } from '@site-mate/sitemate-global-shared';

import { queryClient } from '@/common/react-query';
import { Button, Spinner } from '@/components';
import {
  useUpdateBilling,
  useUpdateWorkspace,
  useUpdateWorkspaceName,
} from '@/hooks';
import { SitemateStartAccountIdInput } from '@/pages/settings/components/SitemateStartAccountIdInput';

interface IWorkspaceTableType {
  isSuperUser: boolean;
  workspace: IWorkspace | undefined;
  loading: boolean;
  // TODO: can remove this when FS billing tracking is removed
  legacyBillingPath: string | undefined;
}

export const WorkspaceTable: FC<IWorkspaceTableType> = ({
  isSuperUser = false,
  workspace: workspaceData,
  loading: workspaceLoading,
  legacyBillingPath,
}) => {
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState,
    setError,
    clearErrors,
  } = useForm<IWorkspace>({
    defaultValues: {
      ...workspaceData,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { isValid, errors: formErrors, dirtyFields } = formState;
  const [errorMsg, setErrorMsg] = useState<string>('');

  const nameRegister = register('name', {
    validate: (value) =>
      value.trim() ? true : 'Please enter a workspace name.',
  });

  const isWorkspaceNameUnchanged = workspaceData?.name === getValues().name;
  const isSmsAccountIdUnchanged =
    workspaceData?.sitemateAccountId === getValues().sitemateAccountId;

  /**
   * We want to check if the sitemateAccountId exists in the db
   * If it does, we want to show an error message if the user tries to submit an empty sitemateAccountId
   * If it doesn't, we don't want to show an error message if the user tries to submit an empty sitemateAccountId
   */
  const sitemateAccountIdExistsInDb = !!workspaceData?.sitemateAccountId;
  const sitemateAccountIdRegister = register('sitemateAccountId', {
    validate: (value) => {
      // we only want to fail validation if the sitemateAccountId exists in the db
      if (!value && sitemateAccountIdExistsInDb) {
        return 'Cannot be empty.';
      }

      return true;
    },
  });

  const [sitemateStartIdValidationError, setSitemateStartIdValidationError] =
    useState(false);

  const workspaceId = PathUtility.getLeaf(workspaceData?._id ?? '');
  const { mutate: updateWorkspace, isLoading: updateWorkspaceLoading } =
    useUpdateWorkspace();
  const { mutate: updateWorkspaceName, isLoading: updateWorkspaceNameLoading } =
    useUpdateWorkspaceName();
  const { mutate: updateBilling, isLoading: updateBillingLoading } =
    useUpdateBilling(workspaceId);

  const handleSubmissionError = () => {
    setErrorMsg('An error occurred. Please try again.');
    queryClient.invalidateQueries(['workspaces']);
    queryClient.invalidateQueries(['search', 'workspaces']);
  };

  const onSubmit = async (formData: IWorkspace) => {
    type PossibleProps = Pick<IWorkspace, 'name' | 'sitemateAccountId'>;
    const submitData: Partial<IWorkspace> = {};

    Object.keys(dirtyFields).forEach((field) => {
      submitData[field as keyof PossibleProps] =
        formData[field as keyof PossibleProps];
    });

    if (!workspaceData) {
      return;
    }
    if (isSuperUser) {
      updateWorkspace(
        {
          updatedWorkspace: submitData,
          workspaceId: workspaceData._id,
        },
        {
          onSuccess: () => {
            // TODO: remove when Flowsite billing tracking is completely removed
            if (legacyBillingPath) {
              updateBilling({ billingPath: legacyBillingPath });
            }
          },
          onError: () => {
            handleSubmissionError();
          },
        }
      );
    } else {
      updateWorkspaceName(
        {
          updatedWorkspace: submitData,
          workspaceId: workspaceData._id,
        },
        {
          onError: () => {
            handleSubmissionError();
          },
        }
      );
    }

    reset(submitData);
  };

  useEffect(() => {
    if (workspaceData) {
      reset(workspaceData);
    }
  }, [workspaceData, reset]);

  if (workspaceLoading) {
    return (
      <div className="mb-2 flex w-full flex-col items-start">
        <h2 className="mb-2 mt-4 text-lg font-bold">General</h2>
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col items-start space-y-2">
      <h2 className="text-xl font-bold">Workspace Settings</h2>
      <p className="text-sm font-bold">General</p>
      {errorMsg && <p className="pb-2 text-sm text-default-red">{errorMsg}</p>}
      <form
        className="w-full space-y-2"
        onSubmit={handleSubmit(() => onSubmit(getValues()))}
      >
        <table className="w-full border-collapse border bg-white text-left">
          <tbody>
            <tr className="h-14">
              <td className="border-slate-500 w-1/2 space-x-2 border p-2 align-top text-sm">
                <span className="h-full w-1/2 text-default-text">
                  Workspace Name
                </span>
              </td>
              <td className="flex flex-col gap-1 p-2 text-sm">
                <input
                  {...nameRegister}
                  aria-label="name"
                  type="text"
                  className={clsx(
                    'w-80 rounded-md border-2 bg-white p-1.4 text-sm leading-normal disabled:cursor-not-allowed disabled:bg-grey-1 disabled:text-default-text',
                    `${
                      formErrors.name ? 'border-default-red' : 'border-grey-3'
                    }`
                  )}
                />
                {formErrors.name && (
                  <span className="text-xs leading-sm-18 text-default-red">
                    {formErrors.name.message}
                  </span>
                )}
              </td>
            </tr>
            <tr className="h-14">
              <td className="border-slate-500 w-1/2 space-x-2 border p-2 align-top text-sm">
                <span className="h-full w-1/2 text-default-text">
                  Workspace ID
                </span>
              </td>
              <td className="border-slate-500 w-1/2 space-x-2 border px-2 text-sm">
                <input
                  value={PathService.extractId(workspaceData?._id ?? '')}
                  disabled
                  aria-label="_id"
                  type="text"
                  className="w-80 rounded-md border-2 border-grey-3 bg-white p-1.4 text-sm leading-normal disabled:cursor-not-allowed disabled:bg-grey-1 disabled:text-default-text"
                />
              </td>
            </tr>
            <tr className="h-14">
              <td className="border-slate-500 w-1/2 space-x-2 border p-2 align-top text-sm">
                <span className="h-full w-1/2 text-default-text">
                  Sitemate Start Account ID
                </span>
              </td>
              <td className="flex flex-col gap-1 p-2 text-sm">
                <SitemateStartAccountIdInput
                  className="w-80"
                  sitemateAccountIdRegister={sitemateAccountIdRegister}
                  errors={formErrors.sitemateAccountId}
                  disabled={!isSuperUser}
                  setError={setError}
                  clearErrors={clearErrors}
                  setValidationError={setSitemateStartIdValidationError}
                />
              </td>
            </tr>
          </tbody>
        </table>
        {/* TODO: restore !formState.isDirty as extra condition when a working solution for synching billing data to Salesforce is implemented */}
        {updateWorkspaceLoading ||
        updateWorkspaceNameLoading ||
        updateBillingLoading ? (
          <Spinner />
        ) : (
          <Button
            disabled={
              (isWorkspaceNameUnchanged && isSmsAccountIdUnchanged) ||
              sitemateStartIdValidationError ||
              !isValid
            }
            type="submit"
          >
            Save
          </Button>
        )}
      </form>
    </div>
  );
};
