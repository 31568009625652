import { useEffect, useRef } from 'react';
import Container from 'typedi';

import {
  DateUtil,
  IntervalService,
  Maybe,
  RuleFrequency,
} from '@site-mate/sitemate-flowsite-shared';

export enum Frequency {
  Hourly = 'Hourly',
  Weekly = 'Weekly',
  Fortnightly = 'Fortnightly',
  Monthly = 'Monthly',
}

export enum DayOfWeek {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

export enum DayOfMonth {
  FirstDay = 'First day of the month',
  LastDay = 'Last day of the month',
}

export interface IRuleOptions {
  frequency?: Frequency;
  dayOfWeek?: DayOfWeek;
  dayOfMonth?: DayOfMonth;
  startDate?: string;
}

// Represents RRule values that can be either a single number, an array of numbers, or null.
export type RuleByNumberValue = number | number[] | null;

// Represents RRule values for the byweekday option.
export type RuleByWeekday =
  | { weekday: number }
  | { weekday: number; n: number };

/**
 * Equivalent RRule values for each frequency.
 */
const frequencyRecurrenceValues: Record<
  string,
  { freq: number; interval: number }
> = {
  [Frequency.Weekly]: {
    freq: RuleFrequency.WEEKLY,
    interval: 1,
  },
  [Frequency.Fortnightly]: {
    freq: RuleFrequency.WEEKLY, // 2 = weekly
    interval: 2, // every 2 weeks
  },
  [Frequency.Monthly]: {
    freq: RuleFrequency.MONTHLY,
    interval: 1,
  },
};

/**
 * Day of week values as defined by RRule,
 * with 0 being Monday and 6 being Sunday.
 */
const weekdayRecurrenceValues = [
  DayOfWeek.Monday,
  DayOfWeek.Tuesday,
  DayOfWeek.Wednesday,
  DayOfWeek.Thursday,
  DayOfWeek.Friday,
  DayOfWeek.Saturday,
  DayOfWeek.Sunday,
];

/**
 * Day of month values as defined by RRule
 */
const monthDayRecurrenceValues: Record<string, number> = {
  [DayOfMonth.FirstDay]: 1,
  [DayOfMonth.LastDay]: -1,
};

/**
 * Parses the frequency and interval values from the RRule library to a Frequency enum value.
 *
 * @param frequency - The frequency value from the RRule library
 *  (e.g. 0 = yearly,  1 = monthly, 2 = weekly, etc).
 * @param interval - The interval value from the RRule library.
 * @returns The equivalent Frequency enum value.
 */
export const parseFrequency = (
  frequency: Maybe<number>,
  interval: Maybe<number>
): Maybe<Frequency> => {
  if (!frequency || !interval) {
    return undefined;
  }

  const matchedFrequency = Object.entries(frequencyRecurrenceValues).find(
    ([, { freq, interval: intervalValue }]) =>
      freq === frequency && intervalValue === interval
  );

  return matchedFrequency ? (matchedFrequency[0] as Frequency) : undefined;
};

/**
 * Converts the byweekday value from the RRule library to a DayOfWeek enum value.
 * @param dayOfWeek - The byweekday value from the RRule library.
 * @returns The equivalent DayOfWeek enum value.
 */
export const parseDayOfWeek = (
  weekdays: Maybe<RuleByWeekday[]>
): Maybe<DayOfWeek> => {
  if (!weekdays) {
    return undefined;
  }

  const dayOfWeekValue = Array.isArray(weekdays) ? weekdays[0] : weekdays;

  if (!dayOfWeekValue) {
    return undefined;
  }

  return weekdayRecurrenceValues[dayOfWeekValue.weekday];
};

/**
 * Parses the day of month value from the RRule library.
 *
 * @param dayOfMonth - The day of month value from the RRule library.\
 * @returns The equivalent DayOfMonth enum value.
 */
export const parseDayOfMonth = (
  dayOfMonth: Maybe<RuleByNumberValue>
): Maybe<DayOfMonth> => {
  const dayOfMonthValue = Array.isArray(dayOfMonth)
    ? dayOfMonth[0]
    : dayOfMonth;

  const matchedDayOfMonth = Object.entries(monthDayRecurrenceValues).find(
    ([, value]) => value === dayOfMonthValue
  );

  return matchedDayOfMonth ? (matchedDayOfMonth[0] as DayOfMonth) : undefined;
};

/**
 * Generates a recurrence rule string from the given options.
 *
 * @param defaultStartDate - The default start date to use if start date is not provided.
 * @returns A function that generates a recurrence rule string from the given options.
 */
const generateRule =
  (defaultStartDate?: string) =>
  ({
    frequency,
    dayOfWeek,
    startDate,
    dayOfMonth,
  }: IRuleOptions): Maybe<string> => {
    const frequencyValue = frequency
      ? frequencyRecurrenceValues[frequency]
      : undefined;

    if (!frequencyValue) {
      return undefined;
    }

    const weekdayValue = dayOfWeek
      ? weekdayRecurrenceValues.findIndex((value) => value === dayOfWeek)
      : undefined;

    const monthDayValue = dayOfMonth
      ? monthDayRecurrenceValues[dayOfMonth]
      : undefined;

    const startDateOrDefault = startDate ?? defaultStartDate;
    const startDateValue =
      startDateOrDefault && DateUtil.isValidDate(startDateOrDefault)
        ? DateUtil.parseUtcDate(startDateOrDefault)
        : undefined;

    const options = {
      freq: frequencyValue.freq,
      interval: frequencyValue.interval,
      byweekday: weekdayValue,
      bymonthday: monthDayValue,
      dtstart: startDateValue,
    };

    const intervalService = Container.get(IntervalService);
    const rule = intervalService.generateRule(options);

    return rule.toString();
  };

/**
 * Attempts to parse the given rule string into a set of options.
 * If the rule string is invalid, undefined is returned.
 *
 * @param rule - The rule string to parse.
 * @returns The options parsed from the rule string.
 */
const parseRule = (rule: string): Maybe<IRuleOptions> => {
  if (!rule) {
    return undefined;
  }

  const parsedRule = Container.get(IntervalService).parseRule(rule);

  if (!parsedRule) {
    return undefined;
  }

  const periodOptions = {
    frequency: parseFrequency(parsedRule.freq, parsedRule.interval),
    dayOfWeek: parseDayOfWeek(parsedRule.byweekday as Maybe<RuleByWeekday[]>),
    dayOfMonth: parseDayOfMonth(parsedRule.bymonthday),
    startDate: parsedRule.dtstart?.toISOString(),
  };

  return periodOptions;
};

/**
 * Hook for processing recurrence rule (RRule) string.
 *
 * @param rule - The recurrence rule string to parse.
 * @param defaultStartDate - The default start date to use if start date is not provided.
 * @param onRuleParseSuccess - A callback to be called when the rule is successfully parsed.
 * @param onRuleParseFail - A callback to be called when the rule fails to parse.
 * @returns An object containing the generateRule and parseRule functions.
 */
export const useRecurrenceRule = (
  rule?: string,
  defaultStartDate?: string,
  onRuleParseSuccess?: (
    periodOptions: IRuleOptions,
    rule: Maybe<string>
  ) => void,
  onRuleParseFail?: (error: Error, rule: Maybe<string>) => void
) => {
  const previousRule = useRef<string | undefined>();

  useEffect(() => {
    if (rule !== previousRule.current) {
      if (!rule) {
        return;
      }

      try {
        const parsedRule: Maybe<IRuleOptions> = rule
          ? parseRule(rule)
          : undefined;

        if (!parsedRule) {
          return;
        }

        onRuleParseSuccess?.(parsedRule, rule);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Unable to parse rule: ${rule}`, error);
        onRuleParseFail?.(new Error('Unable to parse rule'), rule);
      }
    }

    previousRule.current = rule;
  }, [rule, onRuleParseFail, onRuleParseSuccess]);

  return {
    generateRule: generateRule(defaultStartDate),
    parseRule,
  };
};
