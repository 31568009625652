import { IFlow, INode } from '@site-mate/sitemate-flowsite-shared';

import { NodeActionComponents } from '@/pages/flows/components/nodes/NodeActionComponents';
import { nodeService } from '@/services';

export type ActionProps = {
  flow: IFlow;
  onFlowChange: (flow: IFlow) => void;
};

export type OutgoingActionProps = {
  node: INode;
} & ActionProps;

export const Action = (props: ActionProps) => {
  const { flow } = props;
  const firstNode = nodeService.getNode(flow, 0); // TODO only supports single node configs for now

  if (!firstNode) {
    return <p>No node on flow</p>;
  }

  const NodeActionComponent = NodeActionComponents[firstNode.type];

  const newProps = {
    ...props,
    node: firstNode,
  };

  return <NodeActionComponent {...newProps} />;
};
