import { OidcClientSettings } from 'oidc-client-ts';

import { IntegrationType, Maybe } from '@site-mate/sitemate-flowsite-shared';

import { dashpivotConfig } from '@/auth/dashpivot.config';
import { microsoftConfig } from '@/auth/microsoft.config';
import { quickbooksConfig } from '@/auth/quickbooks.config';
import { xeroConfig } from '@/auth/xero.config';

export const OAuthCallbacks = new Map<
  IntegrationType,
  { settings: OidcClientSettings }
>([
  [IntegrationType.XERO, { settings: xeroConfig }],
  [IntegrationType.DASHPIVOT, { settings: dashpivotConfig }],
  [IntegrationType.QUICKBOOKS, { settings: quickbooksConfig }],
  [IntegrationType.MICROSOFT, { settings: microsoftConfig }],
]);

export const getOAuthConfig = (
  type: IntegrationType
): Maybe<OidcClientSettings> => OAuthCallbacks.get(type)?.settings;
