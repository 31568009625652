import { useQuery } from 'react-query';

import { IRun } from '@site-mate/sitemate-flowsite-shared';

import { api } from '@/common/api';

export interface IRunsQueryParams {
  limit: number;
  beforeDate?: string;
}

function getRuns(
  workspaceId: string,
  params: IRunsQueryParams
): () => Promise<IRun[]> {
  return () => api.get(`workspaces/${workspaceId}/runs`, { params });
}

export function useRuns(workspaceId: string, queryParams: IRunsQueryParams) {
  return useQuery({
    queryKey: ['workspace', workspaceId, 'runs', queryParams],
    queryFn: getRuns(workspaceId, queryParams),
    refetchOnWindowFocus: false,
  });
}
