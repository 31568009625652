export enum IButtonSize {
  DEFAULT = 'default',
  SMALL = 'small',
  WIDE = 'wide',
}

export enum IButtonColor {
  GREEN = 'Green',
  BLUE = 'Blue',
  RED = 'Red',
  GREY_1 = 'Grey 1',
  GREY_2 = 'Grey 2',
  GREY_3 = 'Grey 3',
  GREY_9 = 'Grey 9',
  WHITE = 'White',
}

export const ButtonColors: Record<IButtonColor, string> = {
  [IButtonColor.GREEN]:
    'bg-default-green text-white hover:bg-secondary-green hover:text-white active:bg-default-green visited:text-white outline-2',
  [IButtonColor.BLUE]:
    'bg-default-blue text-white hover:bg-secondary-blue hover:text-white active:bg-default-blue visited:text-white',
  [IButtonColor.RED]:
    'bg-default-red text-white hover:bg-secondary-red hover:text-white active:bg-default-red visited:text-white',
  [IButtonColor.GREY_1]:
    'bg-grey-1 text-default-text hover:bg-grey-2 active:bg-grey-1 visited:text-default-text',
  [IButtonColor.GREY_2]:
    'bg-grey-2 text-default-text hover:bg-grey-3 active:bg-grey-2 visited:text-default-text',
  [IButtonColor.GREY_3]:
    'bg-grey-3 text-default-text hover:bg-grey-4 active:bg-grey-3 visited:text-default-text p-2.5',
  [IButtonColor.GREY_9]:
    'bg-grey-9 text-white hover:bg-grey-6 active:bg-grey-9 visited:text-white',
  [IButtonColor.WHITE]:
    'bg-white text-default-text hover:bg-grey-1 active:bg-white visited:text-default-text',
};

export const ButtonSizes: Record<IButtonSize, string> = {
  [IButtonSize.DEFAULT]: 'leading-normal p-2.5',
  [IButtonSize.SMALL]: 'leading-6 px-1',
  [IButtonSize.WIDE]: 'p-2.5 w-full h-[41px]',
};
