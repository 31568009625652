import clsx from 'clsx';
import React from 'react';

type ErrorProps = {
  children?: React.ReactNode;
  icon?: string;
  message?: string;
  title?: string;
};

const defaultTitle = 'An error occurred';

export const ErrorMessage = ({
  children,
  icon,
  message,
  title = defaultTitle,
}: ErrorProps) => (
  <div>
    <div className="max-w-xl m-auto mt-40 px-3 text-center text-default-text leading-normal">
      {icon && (
        <i
          data-testid="error-icon"
          aria-hidden="true"
          className={clsx(
            'cc-icon text-3xl ui-disabled:text-grey-5 leading-5',
            icon
          )}
        />
      )}
      <h1 className="font-bold text-2xl my-4">{title}</h1>
      {message && <p>{message}</p>}
      {children}
    </div>
  </div>
);
