import { INodeConfig, INodeType } from '@site-mate/sitemate-flowsite-shared';

import { IntegrationsMap } from '@/hooks';
import { ICreateProps } from '@/pages/flows/types';

import { generateDefaultConfig } from './default-config';
import { generateHostedFileConfig } from './hosted-file-config';
import { generateQuickbooksConfig } from './quickbooks-config';
import { generateXeroConfig } from './xero-config';

export const configMap: Record<
  INodeType,
  (createProps: ICreateProps, integrationsMap: IntegrationsMap) => INodeConfig
> = {
  [INodeType.XERO_INVOICE_V1]: generateXeroConfig,
  [INodeType.XERO_TIMESHEET_V1]: generateXeroConfig,
  [INodeType.XERO_BILL_V1]: generateXeroConfig,
  [INodeType.POWERBI_CONNECTOR_V1]: generateHostedFileConfig,
  [INodeType.EXCEL_CONNECTOR_V1]: generateHostedFileConfig,
  [INodeType.QUICKBOOKS_INVOICE_V1]: generateQuickbooksConfig,
  [INodeType.MICROSOFT_SHAREPOINT_FORM_UPLOAD_V1]: generateDefaultConfig,
};
