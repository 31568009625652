import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Maybe } from '@site-mate/sitemate-flowsite-shared';
import { WorkspaceCreationContextTypes } from '@site-mate/sitemate-global-shared';

import { Spinner } from '@/components';
import {
  useAccountWorkspaces,
  useCreateWorkspace,
  useWorkspaces,
} from '@/hooks';
import { useAccounts } from '@/hooks/useAccounts';

import { AccountSelectorModal } from './AccountSelectorModal';
import { WorkspaceExistsModal } from './WorkspaceExistsModal';
import { WorkspaceTrialModal } from './WorkspaceTrialModal';

export const SignUp = () => {
  const navigate = useNavigate();

  const [selectedAccountId, setSelectedAccountId] = useState<Maybe<string>>();

  const workspaces = useWorkspaces();

  const hasNoExistingWorkspaces =
    workspaces.isSuccess && workspaces.data?.length === 0;
  const accounts = useAccounts(hasNoExistingWorkspaces);

  const accountWorkspaces = useAccountWorkspaces(selectedAccountId);
  const createWorkspace = useCreateWorkspace();

  const [showAccountSelectorModal, setShowAccountSelectorModal] =
    useState(false);
  const [showExistingWorkspaceModal, setShowExistingWorkspaceModal] =
    useState(false);
  const [showWorkspaceTrialModal, setShowWorkspaceTrialModal] = useState(false);

  /**
   * Blocks the user from signing up if they already have a workspace.
   * Once a workspace is created, the workspaces hook will refetch and navigate to the new workspace.
   */
  useEffect(() => {
    if (workspaces.isSuccess && workspaces.data?.length > 0) {
      navigate(`/workspaces${workspaces.data[0]._id}`);
    }
  }, [workspaces.data, workspaces.isSuccess, navigate]);

  /**
   * Checks the user's accounts and shows the account selector modal for multi-account users.
   * Automatically selects the account if there is only one.
   */
  useEffect(() => {
    if (!accounts.isSuccess) {
      return;
    }

    if (accounts.data?.length === 1) {
      setSelectedAccountId(accounts.data[0]._id);
      return;
    }

    // Otherwise, show the account selector modal
    setShowAccountSelectorModal(true);
  }, [accounts.isSuccess, accounts.data]);

  /**
   * Checks if the selected account has an existing Flowsite workspace.
   * If Flowsite workspace exists, show the admin invite instructions modal.
   * Otherwise, show the start workspace trial modal.
   */
  useEffect(() => {
    if (accountWorkspaces.isSuccess) {
      setShowAccountSelectorModal(false);

      if (accountWorkspaces.data?.length > 0) {
        setShowExistingWorkspaceModal(true);
      } else {
        setShowWorkspaceTrialModal(true);
      }
    }
  }, [accountWorkspaces.data?.length, accountWorkspaces.isSuccess]);

  const handleAccountSelect = (accountId: string) => {
    setSelectedAccountId(accountId);
  };

  const handleOnStartTrial = () => {
    createWorkspace.mutate(
      {
        sitemateAccountId: selectedAccountId,
        contextType: WorkspaceCreationContextTypes.FirstLogin,
        includeUserMetadata: true,
      },
      {
        onSuccess: () => {
          setShowWorkspaceTrialModal(false);
        },
      }
    );
  };

  if (workspaces.isLoading || accounts.isLoading) {
    return (
      <div className="m-auto">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <AccountSelectorModal
        open={showAccountSelectorModal}
        accounts={accounts.data}
        onSelectAccount={handleAccountSelect}
      />
      <WorkspaceExistsModal open={showExistingWorkspaceModal} />
      <WorkspaceTrialModal
        open={showWorkspaceTrialModal}
        buttonDisabled={createWorkspace.isLoading}
        onStartTrial={handleOnStartTrial}
      />
    </>
  );
};
