import { useQuery } from 'react-query';

import { IUserProfile } from '@site-mate/sitemate-global-shared';

import { api } from '@/common';

export type UserProfile = IUserProfile & { isSuperUser: boolean };

/**
 * Gets the authenticated user.
 */
export function useAuthenticatedUser() {
  return useQuery({
    queryKey: ['users', 'me'],
    queryFn: () => api.get<UserProfile>('/users/me'),
  });
}
