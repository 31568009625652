import clsx from 'clsx';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import {
  IAccount,
  WorkspaceCreationContextTypes,
} from '@site-mate/sitemate-global-shared';

import { Button, Modal, TextInput } from '@/components';
import { useCreateWorkspace } from '@/hooks';
import { SitemateStartAccountIdInput } from '@/pages/settings';

interface ICreateWorkspaceForm {
  name: string;
  sitemateAccountId: string;
  sitemateAccountName: string;
}

export type CreateWorkspaceModalProps = {
  /**
   * Function to be called when a workspace is successfully created.
   */
  onWorkspaceCreated: (workspaceId: string) => void;
};

const FieldWrapper = ({
  children,
  htmlFor,
  label,
}: {
  children: ReactNode;
  htmlFor: string;
  label: string;
}) => (
  <label
    htmlFor={htmlFor}
    className="flex flex-1 gap-y-4 gap-x-2 leading-normal"
  >
    <span className="flex-1">{label}</span>
    <div className="flex flex-col w-60 items-stretch">{children}</div>
  </label>
);

export const CreateWorkspaceModal = ({
  onWorkspaceCreated,
}: CreateWorkspaceModalProps) => {
  const {
    register,
    handleSubmit,
    getValues,
    clearErrors,
    formState,
    setError,
    reset,
  } = useForm<ICreateWorkspaceForm>({
    mode: 'onChange',
    shouldFocusError: false,
  });
  const { isValid, errors: formErrors } = formState;
  const createWorkspace = useCreateWorkspace();

  const [open, setIsOpen] = useState(false);
  const [sitemateStartAccount, setSitemateStartAccount] = useState<IAccount>();

  const onClose = useCallback(() => {
    setSitemateStartAccount(undefined);
    reset();
    setIsOpen(false);
  }, [reset]);

  /**
   * Resets and closes the modal when create workspace is successful.
   */
  useEffect(() => {
    if (createWorkspace.isSuccess && open) {
      const createdWorkspaceId = createWorkspace.data?._id;
      createWorkspace.reset();

      if (createdWorkspaceId) {
        onWorkspaceCreated(createdWorkspaceId);
      }

      onClose();
    }
  }, [open, onClose, onWorkspaceCreated, createWorkspace]);

  const onSubmit: SubmitHandler<ICreateWorkspaceForm> = ({
    name,
    sitemateAccountId,
  }) => {
    createWorkspace.mutate({
      name,
      sitemateAccountId,
      contextType: WorkspaceCreationContextTypes.Manual,
    });
  };

  return (
    <>
      <Button onClick={() => setIsOpen(true)} className="whitespace-nowrap">
        <div>Create Workspace</div>
      </Button>

      <Modal open={open} onClose={onClose}>
        <Modal.Header>
          <Modal.Title>Create Flowsite Workspace</Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>

        <Modal.Content>
          <form onSubmit={handleSubmit(() => onSubmit(getValues()))}>
            <div className="flex flex-col gap-4 mb-4">
              <FieldWrapper htmlFor="name" label="Workspace Name">
                <TextInput
                  id="name"
                  {...register('name', {
                    required: 'Required',
                  })}
                  placeholder="Text..."
                  className={clsx({ '!border-default-red': formErrors.name })}
                />
                {formErrors.name && (
                  <span className="text-default-red text-xs leading-sm-18">
                    {formErrors.name.message}
                  </span>
                )}
              </FieldWrapper>

              <FieldWrapper
                htmlFor="sitemateAccountId"
                label="Sitemate Start Account Id"
              >
                <SitemateStartAccountIdInput
                  id="sitemateAccountId"
                  sitemateAccountIdRegister={{
                    ...register('sitemateAccountId', {
                      required: 'Required',
                    }),
                  }}
                  errors={formErrors.sitemateAccountId}
                  placeholder="Text..."
                  setError={setError}
                  clearErrors={clearErrors}
                  onAccountChange={setSitemateStartAccount}
                />
              </FieldWrapper>

              <FieldWrapper
                htmlFor="sitemateAccountName"
                label="Sitemate Start Account Name"
              >
                <TextInput
                  id="sitemateAccountName"
                  name="sitemateAccountName"
                  value={sitemateStartAccount?.name ?? ''}
                  disabled
                />
                <span className="text-xs text-secondary-text mt-1">
                  This will appear once you enter a Sitemate Start Account Id.
                </span>
              </FieldWrapper>
            </div>

            <Button
              disabled={
                !isValid || !sitemateStartAccount || createWorkspace.isLoading
              }
              type="submit"
            >
              Create Workspace
            </Button>

            {createWorkspace.isError && (
              <div className="mt-1 text-xs text-default-red">
                Workspace creation failed.
              </div>
            )}
          </form>
        </Modal.Content>
      </Modal>
    </>
  );
};
