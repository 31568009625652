import { useQuery } from 'react-query';

import { api } from '@/common';

function getAccounts() {
  return () => api.get(`/accounts`);
}

export function useAccounts(isEnabled: boolean) {
  return useQuery({
    queryKey: ['accounts'],
    queryFn: getAccounts(),
    enabled: isEnabled,
  });
}
