import { useMutation, useQuery, useQueryClient } from 'react-query';

import { IFlow } from '@site-mate/sitemate-flowsite-shared';

import { api } from '@/common/api';
import { NEW_FLOW_ID } from '@/services/node.service';

export interface IFlowQueryParams {
  limit?: number;
  lastFlowPath?: string;
}

const credentialMap = new Map<string, Record<string, string>>();

function getFlows(
  workspaceId: string,
  params?: IFlowQueryParams
): () => Promise<IFlow[]> {
  return async () => {
    const flows = await api.get<IFlow[]>(`workspaces/${workspaceId}/flows`, {
      params,
    });
    return flows;
  };
}

export function useFlows(workspaceId: string, queryParams?: IFlowQueryParams) {
  return useQuery({
    queryKey: ['workspace', workspaceId, 'flows', queryParams],
    queryFn: getFlows(workspaceId, queryParams),
  });
}

export function useCreateOrUpdateFlow(workspaceId: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (newFlow: IFlow) => {
      const { _id: flowPath } = newFlow;

      if (flowPath === NEW_FLOW_ID) {
        const { _id: id, ...flowWithoutId } = newFlow;

        const createdFlow = await api.post<IFlow>(
          `workspaces/${workspaceId}/flows`,
          flowWithoutId
        );

        const credentials = newFlow?.metadata?.credentials;
        if (credentials) {
          credentialMap.set(createdFlow._id, newFlow?.metadata?.credentials);
        }

        return createdFlow;
      }

      const flowId = flowPath.split('/').pop();

      return api.patch<IFlow>(
        `workspaces/${workspaceId}/flows/${flowId}`,
        newFlow
      );
    },
    onSuccess: () =>
      queryClient.invalidateQueries(['workspace', workspaceId, 'flows']),
  });
}

export function useFlowCredentials(flowId: string) {
  return credentialMap.get(flowId);
}

export function useDeleteFlow(workspaceId: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (flowPath: string) => {
      const flowId = flowPath.split('/').pop();

      return api.delete<unknown>(`workspaces/${workspaceId}/flows/${flowId}`);
    },
    onSuccess: () =>
      queryClient.invalidateQueries(['workspace', workspaceId, 'flows']),
  });
}
