import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import React, { Fragment, HTMLProps } from 'react';

type BaseProps = {
  /**
   * The children nodes.
   */
  children: React.ReactNode;
  /**
   * Additional class names to apply to the modal component.
   */
  className?: HTMLProps<HTMLElement>['className'];
};

type EventHandlerProps = {
  /**
   * The function to call when the modal is closed.
   */
  onClose?: () => void;
};

export enum ModalPositions {
  Top = 'top',
  Center = 'center',
}

export type ModalProps = {
  /**
   * If true, stretches the modal to max width.
   * @default true
   */
  fullWidth?: boolean;
  /**
   * The maximum width of the modal.
   * Used in conjunction with `fullWidth`.
   * @default 'max-w-125' (500px).
   */
  maxWidth?: string;
  /**
   * If true, the modal is open.
   */
  open: boolean;
  /**
   * The position of the modal, supports 'top' and 'center'.
   * @default IModalPositions.top
   */
  position?: ModalPositions;
} & BaseProps &
  EventHandlerProps;

type ModalHeaderProps = BaseProps & EventHandlerProps;
type ModalTitleProps = BaseProps;
type CloseButtonProps = EventHandlerProps;
type ModalContentProps = BaseProps;

const ModalTitle = ({ children, className }: ModalTitleProps) => (
  <Dialog.Title
    className={clsx(
      'pr-2 text-left font-medium leading-sm-30 text-default-text',
      className
    )}
  >
    {children}
  </Dialog.Title>
);

const ModalCloseButton = ({ onClose }: CloseButtonProps) => (
  <button
    type="button"
    className="w-5.5 ml-auto text-default-text focus:outline-none"
    onClick={onClose}
  >
    <i
      data-testid="modal-close-button"
      className="cc-icon cc-icon-close text-xl"
    />
  </button>
);

const ModalHeader = ({ className, onClose, children }: ModalHeaderProps) => (
  <div
    className={clsx(
      'mb-4 flex items-start justify-between text-xl leading-5',
      className
    )}
  >
    {React.Children.map(children, (child) =>
      React.cloneElement(child as React.ReactElement, { onClose })
    )}
  </div>
);

const ModalContent = ({ children, className }: ModalContentProps) => (
  <div className={clsx('text-default-text', className)}>{children}</div>
);

const Modal = ({
  children,
  className,
  fullWidth = true,
  maxWidth = 'max-w-125',
  open,
  position = ModalPositions.Top,
  onClose = () => {},
}: ModalProps) => (
  <Transition show={open} as={Fragment}>
    <Dialog open={open} onClose={onClose} unmount>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 z-40 bg-black/50" aria-hidden="true" />
      </Transition.Child>

      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div
          className={clsx(
            'fixed inset-0 z-50 flex w-screen px-6 text-sm text-default-text',
            position === 'top' ? 'items-start' : 'items-center',
            className
          )}
        >
          <Dialog.Panel
            className={clsx(
              'relative mx-auto rounded bg-white p-6 ',
              maxWidth,
              {
                'w-full': fullWidth,
                'mt-25': position === 'top',
              }
            )}
          >
            {React.Children.map(children, (child) =>
              React.cloneElement(child as React.ReactElement, { onClose })
            )}
          </Dialog.Panel>
        </div>
      </Transition.Child>
    </Dialog>
  </Transition>
);

Modal.Title = ModalTitle;
Modal.CloseButton = ModalCloseButton;
Modal.Header = ModalHeader;
Modal.Content = ModalContent;

export { Modal };
