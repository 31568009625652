import { Button, Modal } from '@/components';

import { IButtonColor } from '../button/Button.variants';

type AlertButtonProps = {
  label: string;
  color: IButtonColor;
};

export type AlertModalProps = {
  /**
   * If true, the modal is open.
   */
  open: boolean;
  /**
   * The message to display as alert title.
   */
  message: string;
  /**
   * Properties for the confirm (affirmative) button.
   * @default {label: 'OK', color: IButtonColor.GREEN }
   */
  confirmButtonProps?: AlertButtonProps;

  /**
   * Properties for the cancel (dismissive) button.
   * @default { label: 'Cancel', color: IButtonColor.GREY_1 }
   */
  cancelButtonProps?: AlertButtonProps;
  /**
   * The function to call when the confirm button is clicked.
   */
  onConfirm: () => void;
  /**
   * The function to call when the confirm button is clicked.
   */
  onCancel: () => void;
  /**
   * A boolean value that determines if the confirm button is disabled or not
   */
  confirmButtonClicked?: boolean;
};

export const AlertModal = ({
  open,
  cancelButtonProps = { label: 'Cancel', color: IButtonColor.GREY_1 },
  confirmButtonProps = { label: 'OK', color: IButtonColor.GREEN },
  message,
  onConfirm,
  onCancel,
  confirmButtonClicked,
}: AlertModalProps) => (
  <Modal open={open} onClose={onCancel}>
    <Modal.Header>
      <Modal.Title>{message}</Modal.Title>
      <Modal.CloseButton />
    </Modal.Header>
    <Modal.Content>
      <div className="flex gap-2">
        <Button
          disabled={confirmButtonClicked}
          color={confirmButtonProps.color}
          onClick={onConfirm}
        >
          {confirmButtonProps?.label}
        </Button>
        <Button color={cancelButtonProps.color} onClick={onCancel}>
          {cancelButtonProps?.label}
        </Button>
      </div>
    </Modal.Content>
  </Modal>
);
