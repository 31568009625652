import { INodeType } from '@site-mate/sitemate-flowsite-shared';

import { OutgoingActionProps } from '@/pages/flows/components/flows/Action';
import {
  ExcelConnectorActionNode,
  PowerBIConnectorActionNode,
} from '@/pages/flows/components/nodes/hosted-file';
import { QuickbooksInvoiceActionNode } from '@/pages/flows/components/nodes/quickbooks';
import {
  XeroInvoiceActionNode,
  XeroTimesheetActionNode,
} from '@/pages/flows/components/nodes/xero';

export const NodeActionComponents: Record<
  INodeType,
  ({ flow, onFlowChange, node }: OutgoingActionProps) => JSX.Element
> = {
  [INodeType.XERO_TIMESHEET_V1]: XeroTimesheetActionNode,
  [INodeType.XERO_INVOICE_V1]: XeroInvoiceActionNode,
  [INodeType.XERO_BILL_V1]: XeroInvoiceActionNode,
  [INodeType.POWERBI_CONNECTOR_V1]: PowerBIConnectorActionNode,
  [INodeType.EXCEL_CONNECTOR_V1]: ExcelConnectorActionNode,
  [INodeType.QUICKBOOKS_INVOICE_V1]: QuickbooksInvoiceActionNode,
  [INodeType.MICROSOFT_SHAREPOINT_FORM_UPLOAD_V1]: () => (
    <div className="mb-4 text-sm">Not supported</div>
  ),
};
