import Bugsnag from '@bugsnag/js';
import { useQuery } from 'react-query';

import { IAccount } from '@site-mate/sitemate-global-shared';

import { globalAPI } from '@/common/global.api';

function getSitemateStartAccount(
  accountId: string
): () => Promise<IAccount | null> {
  return async () => {
    try {
      const account = await globalAPI.get(`/accounts/${accountId}`);
      return account;
    } catch (error) {
      if (error instanceof Error) {
        Bugsnag.notify(error);
      } else {
        Bugsnag.notify(new Error('An unknown error occurred'));
      }
      return null;
    }
  };
}

export function useSitemateStartAccount(accountId?: string) {
  return useQuery({
    queryKey: ['sitemate-account', accountId],
    queryFn: getSitemateStartAccount(accountId!),
    enabled: !!accountId,
  });
}
