import clsx from 'clsx';
import { ChangeEvent, useRef, useState } from 'react';

const copyIcon = 'cc-icon-clone';
const checkIcon = 'cc-icon-check';

export type CopyToClipboardTextInputProps = {
  /**
   * Additional class names to apply to the input.
   */
  className?: string;
  /**
   * Value of data-testid to be embedded on text input element
   */
  dataTestId?: string;
  /**
   * If true, the dropdown is disabled.
   * @default false
   */
  disabled?: boolean;
  /**
   * Name attribute of the input. Also used as a testid prefix.
   */
  name?: string;
  /**
   * The placeholder text.
   */
  placeholder?: string;
  /**
   * The value to display
   */
  value?: string;
  /**
   * The width of the dropdown.
   * @default 'w-50' (200px)
   */
  width?: string;
  /**
   * Callback when the text input changes.
   * @param option The new text value
   */
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const CopyToClipboardTextInput = ({
  className,
  dataTestId,
  disabled = false,
  name,
  placeholder,
  value,
  width = 'w-50',
  onChange = () => {},
}: CopyToClipboardTextInputProps) => {
  const processedClass = clsx(
    'flex border-2 border-grey-3 rounded-md overflow-hidden focus-within:overflow-visible',
    width,
    className
  );

  const textRef = useRef<HTMLInputElement>(null);

  const [icon, setIcon] = useState<string>(copyIcon);

  const copyContent = () => {
    let timer: NodeJS.Timeout;

    if (textRef.current?.value) {
      navigator.clipboard.writeText(textRef.current.value);
      setIcon(checkIcon);
      timer = setTimeout(() => {
        setIcon(copyIcon);
      }, 2000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  };

  return (
    <div className={processedClass}>
      <input
        id={name}
        type="text"
        ref={textRef}
        className="flex-1 w-0 p-2 text-sm z-10 disabled:bg-grey-1 hover:cursor-not-allowed placeholder:text-default-text"
        data-testid={dataTestId}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={onChange}
      />
      <button
        className="inline-flex border-grey-3 border-l-2 hover:bg-grey-2 active:bg-grey-2 text-default-text"
        data-testid={`${name}-copy-to-clipboard`}
        type="button"
        onClick={copyContent}
        disabled={!value}
      >
        <i
          aria-hidden="true"
          className={clsx('cc-icon text-xl leading-5 p-2', icon)}
        />
      </button>
    </div>
  );
};
