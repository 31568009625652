import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  ICreateWorkspaceRequest,
  IWorkspace,
  Maybe,
} from '@site-mate/sitemate-flowsite-shared';

import { api, parseResponseError } from '@/common/api';
import { AppError } from '@/common/app.error';
import { CreateWorkspaceErrorMessages } from '@/models';
import {
  getUserContinentCode,
  getUserTimezone,
} from '@/services/user-location.service';

export interface IUserSignUpMetadata {
  userContinent?: string;
  userTimezone: string;
}

type WithCreatedFlag<T> = T & { isCreated?: boolean };

/**
 * Creates a new workspace
 *
 * @param workspaceRequest - The request object for creating a workspace
 * @returns - Promise of the created workspace
 */
function createWorkspace(
  workspaceRequest: ICreateWorkspaceRequest = {}
): Promise<IWorkspace> {
  return api.post(`/workspaces`, workspaceRequest);
}

/**
 * Retrieves the list of workspaces for the given account id.
 *
 * @param accountId - The account id to retrieve workspaces for.
 * @returns - The list of workspaces
 */
function getWorkspacesByAccountId(
  accountId: string
): () => Promise<IWorkspace[]> {
  return () => api.get(`/accounts/${accountId}/workspaces`);
}

/**
 * Retrieves the list of workspaces the user can access.
 * If the user is a super user, it returns all workspaces.
 */
function getWorkspaces(): () => Promise<IWorkspace[]> {
  return () => api.get(`/workspaces`);
}

/**
 * Finds or creates a workspace by accountId.
 *
 * @param accountId - The account id to find or create a workspace for.
 * @param userWorkspaces - The list of workspaces the user is a member of.
 * @param signUpReference - Reference data associated with the referral sign-up.
 * @returns - Returns a GetOrCreateWorkspaceResult object
 */
function getOrCreateWorkspaceByAccountId(
  accountId: string,
  userWorkspaces: IWorkspace[],
  userSignUpMetadata: IUserSignUpMetadata,
  signUpReference: {
    reference?: string;
    referenceWorkspaceId?: string;
  }
) {
  return async (): Promise<WithCreatedFlag<{ workspace: IWorkspace }>> => {
    let workspaces: IWorkspace[] = [];

    try {
      workspaces = await api.get(`/accounts/${accountId}/workspaces`);
    } catch (responseError: unknown) {
      throw parseResponseError(responseError);
    }

    if (workspaces.length > 0) {
      const existingUserWorkspace = workspaces.find((workspace) =>
        userWorkspaces.some(
          (userWorkspace) => userWorkspace._id === workspace._id
        )
      );

      if (existingUserWorkspace) {
        return { workspace: existingUserWorkspace, isCreated: false };
      }

      // User is NOT a member of the existing workspace
      throw new AppError(CreateWorkspaceErrorMessages.WorkspaceAlreadyExists);
    }

    try {
      const createdWorkspace = await createWorkspace({
        sitemateAccountId: accountId,
        ...userSignUpMetadata,
        ...signUpReference,
      });
      return { workspace: createdWorkspace, isCreated: true };
    } catch (responseError: unknown) {
      throw new AppError(CreateWorkspaceErrorMessages.WorkspaceCreationFailed);
    }
  };
}

function getWorkspace(workspaceId: string): () => Promise<IWorkspace> {
  return () => api.get(`/workspaces/${workspaceId}`);
}

function searchWorkspaces(): () => Promise<IWorkspace[]> {
  return () => api.get(`/workspaces/search`);
}

/**
 * Retrieves a specific workspace by id
 * @returns - The workspace for the given workspaceId
 */
export function useWorkspace(workspaceId?: string) {
  return useQuery({
    queryKey: ['workspaces', workspaceId],
    queryFn: getWorkspace(workspaceId!),
    enabled: !!workspaceId,
  });
}

/**
 * Retrieves the list of workspaces for the given account id.
 * @param accountId
 * @returns - The workspace for the given workspaceId
 */
export function useAccountWorkspaces(accountId?: string) {
  return useQuery({
    queryKey: ['accounts', accountId, 'workspaces'],
    queryFn: getWorkspacesByAccountId(accountId!),
    enabled: !!accountId,
  });
}

export function useWorkspaces() {
  return useQuery({
    queryKey: ['workspaces'],
    queryFn: getWorkspaces(),
  });
}

/**
 * Retrieves the list of workspaces the user can access.
 * If the user is a super user, it returns all workspaces.
 * @returns - The list of workspaces
 */
export function useSearchWorkspaces() {
  return useQuery({
    queryKey: ['search', 'workspaces'],
    queryFn: searchWorkspaces(),
  });
}

/**
 * Finds if a workspace exists for the given account id, if not, creates a new workspace.
 * If the workspace exists and the user is a member, it returns the existing workspace.
 * If the workspace exists and the user is not a member, it throws an error.
 * Resolves the user's timezone and continent code.
 *
 * @param accountId - The account id to find or create a workspace for.
 * @param signUpReference - Reference data associated with the referral sign-up.
 *  - reference: Contains the context for the referral e.g. dashpivot-xero-timesheet
 *  - referenceWorkspaceId: The workspaceId of the referrer e.g. Dashpivot workspaceId
 * @returns - Returns a GetOrCreateWorkspaceResult object,
 *  containing the workspace and a flag if the workspace was created.
 */
export function useGetOrCreateWorkspaceForAccount(
  accountId: Maybe<string>,
  signUpReference: {
    reference?: string;
    referenceWorkspaceId?: string;
  }
) {
  const queryClient = useQueryClient();

  const userMetadata: IUserSignUpMetadata = {
    userTimezone: getUserTimezone(),
    userContinent: getUserContinentCode(),
  };

  // Necessary to fetch user workspaces first
  // to determine membership in case there's an existing workspace.
  const userWorkspacesQuery = useSearchWorkspaces();

  return useQuery({
    queryFn: getOrCreateWorkspaceByAccountId(
      accountId!,
      userWorkspacesQuery.data ?? [],
      userMetadata,
      signUpReference
    ),
    enabled: !!accountId && userWorkspacesQuery.isSuccess,
    onError: (error: AppError) => error,
    onSuccess: () => {
      queryClient.invalidateQueries(['workspaces']);
      queryClient.invalidateQueries(['search', 'workspaces']);
    },
  });
}

/**
 * Mutation for creating a workspace
 *
 * @returns - The mutation object for creating a workspace
 */
export function useCreateWorkspace() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (
      createWorkspaceRequest: ICreateWorkspaceRequest & {
        includeUserMetadata?: boolean;
      }
    ) => {
      const { includeUserMetadata, ...workspaceRequest } =
        createWorkspaceRequest;

      if (includeUserMetadata) {
        workspaceRequest.userTimezone = getUserTimezone();
        workspaceRequest.userContinent = getUserContinentCode();
      }

      return createWorkspace(workspaceRequest);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['workspaces']);
      queryClient.invalidateQueries(['search', 'workspaces']);
    },
  });
}

export interface IWorkspaceMutationVariables {
  updatedWorkspace: Partial<IWorkspace>;
  workspaceId: string;
}

export function useUpdateWorkspace() {
  const queryClient = useQueryClient();

  return useMutation<IWorkspace, unknown, IWorkspaceMutationVariables, unknown>(
    {
      mutationFn: (vars) =>
        api.patch(
          `/workspaces${vars.workspaceId}`,
          vars.updatedWorkspace
        ) as Promise<IWorkspace>,
      onSuccess: () => {
        queryClient.invalidateQueries(['workspaces']);
        queryClient.invalidateQueries(['search', 'workspaces']);
      },
    }
  );
}

export function useUpdateWorkspaceName() {
  const queryClient = useQueryClient();

  return useMutation<IWorkspace, unknown, IWorkspaceMutationVariables, unknown>(
    {
      mutationFn: (vars) =>
        api.patch(
          `/workspaces${vars.workspaceId}/name`,
          vars.updatedWorkspace
        ) as Promise<IWorkspace>,
      onSuccess: () => {
        queryClient.invalidateQueries(['workspaces']);
        queryClient.invalidateQueries(['search', 'workspaces']);
      },
    }
  );
}
