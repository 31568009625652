import TrialExpiredImage from '@/assets/svg/clock.svg';
import { useSearchWorkspaces } from '@/hooks';

import { Modal, ModalPositions } from './Modal';
import { Button, IButtonColor, IButtonSize } from '../button';

interface TrialExpiredModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const TrialExpiredModal = ({
  isOpen,
  onClose,
}: TrialExpiredModalProps) => {
  const { data: workspaces } = useSearchWorkspaces();

  return (
    <Modal
      open={isOpen}
      position={ModalPositions.Top}
      onClose={() => undefined}
    >
      <div className="flex flex-col items-center text-center align-middle">
        <img
          src={TrialExpiredImage}
          alt="Your trial has expired."
          className="mb-4 h-[180px]"
        />
        <h1 className="mb-4 text-xl font-medium">
          Your 30-day free trial of Flowsite has ended
        </h1>
        <p>We hope you had a great experience!</p>
        <p className="mb-4">You will need to subscribe to continue usage</p>
        <div className="flex flex-row justify-between gap-2">
          <Button
            as="a"
            href="mailto:info@sitemate.com?subject=Upgrade Subscription - Flowsite"
            color={IButtonColor.GREEN}
            size={IButtonSize.DEFAULT}
            className="px-4 py-4"
          >
            <i className="cc-icon cc-icon-send mr-1" /> Email us
          </Button>
        </div>
        {workspaces && workspaces.length > 1 && (
          <div className="mt-4">
            <p className="mb-4">Have access to multiple workspaces?</p>
            <Button color={IButtonColor.GREY_2} onClick={() => onClose()}>
              <i className="cc-icon cc-icon-switch " /> Switch workspace
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};
