import clsx from 'clsx';
import { ChangeEventHandler, ForwardedRef, forwardRef } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

type DateInputProps = {
  className?: string;
  containerClassName?: string;
  dataTestId?: string;
  disabled?: boolean;
  id?: string;
  name?: string;
  placeholder?: string;
  register?: UseFormRegisterReturn;
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

const DateInput = forwardRef(
  (
    {
      className,
      containerClassName,
      disabled = false,
      dataTestId,
      id,
      name,
      placeholder,
      value,
      onChange,
      register,
    }: DateInputProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const inputProps = register ?? {
      name,
      value,
      onChange,
    };

    return (
      <div className={clsx(containerClassName, 'relative')}>
        <input
          {...inputProps}
          ref={ref}
          aria-label="date input"
          id={id}
          data-testid={dataTestId}
          type="date"
          className={clsx(
            className,
            'rounded border-2 border-grey-3 bg-white',
            'leading-normal text-sm text-default-text',
            'disabled:cursor-not-allowed disabled:bg-grey-1',
            'focus-visible:outline-default-blue'
          )}
          disabled={disabled}
          placeholder={placeholder}
        />

        <div
          className={clsx(
            'absolute inset-y-0 right-[2px] flex justify-center items-center w-[28px] m-[4px] pointer-events-none',
            disabled ? 'bg-grey-1' : 'bg-white'
          )}
        >
          <i
            data-testid="date-input-icon"
            className={clsx(
              'cc-icon cc-icon-calendar text-xl cursor-pointer text-default-text',
              disabled && 'text-grey-5'
            )}
          />
        </div>
      </div>
    );
  }
);

DateInput.displayName = 'DateInput';

export { DateInput };
