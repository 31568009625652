import { useCallback } from 'react';
import { RRule } from 'rrule';

import {
  DEFAULT_TIME_IN_HOURS,
  getIntervalOptions,
  getHourOptions,
  getStartingDate,
} from '@/common/datetime.util';
import { Combobox } from '@/components';
import { Dropdown } from '@/components/elements/dropdown';
import { TriggerProps } from '@/pages/flows/components/flows/Trigger';
import { useFilterByContainsQuery } from '@/pages/flows/hooks/useFilteredItems';
import { nodeService } from '@/services';
import { defaultTriggerScheduleRule } from '@/services/metadata';

interface IStartingDateOption {
  _id: string;
  label: string;
  value: string;
}

export const ScheduledTrigger = ({ flow, onFlowChange }: TriggerProps) => {
  const flowRule = nodeService.getTriggerSchedule(flow);
  const rule = flowRule
    ? RRule.fromString(flowRule)
    : defaultTriggerScheduleRule;
  const hourOptions = getHourOptions();

  const intervalOptions = getIntervalOptions();

  const { setQuery, items: filteredColumnOptions } = useFilterByContainsQuery(
    hourOptions,
    'label'
  );

  const selectedStartingTime = filteredColumnOptions.find((option) => {
    const { dtstart } = rule.origOptions;

    return (
      option.value === (dtstart || getStartingDate()).getUTCHours().toString()
    );
  });

  const selectedInterval = intervalOptions.find(
    (option) => option.value === rule.origOptions.interval
  );

  const handleStartingTimeChange = useCallback(
    (column: IStartingDateOption) => {
      const dtstart = getStartingDate(parseInt(column.value, 10));
      onFlowChange(
        nodeService.setTriggerSchedule(
          flow,
          RRule.optionsToString({
            freq: rule.origOptions.freq,
            interval:
              selectedInterval?.value ||
              defaultTriggerScheduleRule.origOptions.interval,
            dtstart,
          })
        )
      );
    },
    [flow, onFlowChange, selectedInterval, rule]
  );

  const handleIntervalChange = useCallback(
    (interval: number) => {
      const selectedHours = selectedStartingTime?.value
        ? parseInt(selectedStartingTime.value, 10)
        : DEFAULT_TIME_IN_HOURS;
      const dtstart = getStartingDate(selectedHours);

      onFlowChange(
        nodeService.setTriggerSchedule(
          flow,
          RRule.optionsToString({
            freq: rule.origOptions.freq,
            interval,
            dtstart,
          })
        )
      );
    },
    [flow, onFlowChange, selectedStartingTime, rule]
  );

  return (
    <>
      <div className="mt-4 mb-2 font-bold">Trigger: Recurring Time</div>
      <ul className="space-y-2">
        <li className="flex space-x-2 items-center">
          <span className="w-25">Start:</span>
          <Dropdown
            name="trigger-start"
            options={['Immediately']}
            value="Immediately"
            disabled
          />
        </li>
        <li className="flex space-x-2 items-center">
          <span className="w-25">Repeat every:</span>
          <Dropdown
            name="interval"
            options={intervalOptions}
            value={selectedInterval}
            onChange={handleIntervalChange}
          />
          <span>starting at</span>
          <Combobox
            name="starting-time"
            dataTestId="starting-time"
            labelKey="label"
            valueKey="value"
            value={selectedStartingTime}
            options={filteredColumnOptions}
            onInputChange={setQuery}
            onChange={handleStartingTimeChange}
          />
        </li>
      </ul>
    </>
  );
};
