import { useCallback } from 'react';

import {
  IConnection,
  IDropdownOption,
  IFieldMappingConfigKey,
  INodeConfig,
  IntegrationType,
  ITimesheetUpdateRule,
} from '@site-mate/sitemate-flowsite-shared';

import { Combobox } from '@/components';
import { ConnectionSelector } from '@/pages/flows/components/connection-selector/ConnectionSelector';
import {
  FixedMappings,
  OutgoingActionProps,
  VariableMappings,
} from '@/pages/flows/components/flows';
import { useFilterByContainsQuery } from '@/pages/flows/hooks/useFilteredItems';
import { useTemplate } from '@/pages/flows/hooks/useTemplate';
import {
  getSourceKey,
  useXeroEarningsRates,
} from '@/pages/flows/hooks/useXeroApis';
import { IRequestParameterItem } from '@/pages/flows/types';
import { useWorkspaceContext } from '@/providers/WorkspaceProvider';
import { nodeService } from '@/services';

interface IHandlingOption {
  _id: string;
}

export const XeroTimesheetActionNode = ({
  flow,
  onFlowChange,
  node,
}: OutgoingActionProps) => {
  const { workspaceId } = useWorkspaceContext();
  const templateId = nodeService.getTemplateId(flow);
  const templateQuery = useTemplate(workspaceId, templateId);
  const template = templateQuery.data;

  const handlingOptions = [
    ITimesheetUpdateRule.COMBINE,
    ITimesheetUpdateRule.OVERWRITE,
  ].map((option) => ({ _id: option })) as IHandlingOption[];

  const { setQuery, items: filteredHandlingOptions } = useFilterByContainsQuery(
    handlingOptions,
    '_id'
  );

  const timesheetExistsSetting = handlingOptions.find(
    (option) =>
      option._id === nodeService.getTimesheetExistsSetting(node?.config)
  );
  const connectionId = node?.connectionId;

  const earningsRatesQuery = useXeroEarningsRates(workspaceId, connectionId);

  const earningsRatesDropdownOptions =
    earningsRatesQuery?.data
      ?.map((earningsRate: IRequestParameterItem) => ({
        id: earningsRate.id,
        label: earningsRate.value,
        value: earningsRate.id,
      }))
      .sort((a: IDropdownOption, b: IDropdownOption) =>
        a.label.localeCompare(b.label)
      ) ?? [];

  const handleSetConnection = useCallback(
    (connection: IConnection) => {
      onFlowChange(
        nodeService.setNode(
          flow,
          nodeService.setConnectionId(
            node,
            connection._id,
            getSourceKey(connection)
          )
        )
      );
    },
    [flow, node, onFlowChange]
  );
  const handleTimesheetSettingChange = useCallback(
    (selectedOption: IHandlingOption | undefined) => {
      onFlowChange(
        nodeService.setNodeConfig(
          flow,
          nodeService.setTimesheetExistsSetting(
            node.config,
            selectedOption?._id as ITimesheetUpdateRule
          )
        )
      );
    },
    [flow, node.config, onFlowChange]
  );

  const handleNodeChange = (newNode: INodeConfig) => {
    onFlowChange(nodeService.setNodeConfig(flow, newNode));
  };

  const dashpivotToXeroMappingLabels = {
    source: 'Dashpivot:',
    destination: 'Xero:',
  };

  return (
    <>
      <div className="mb-2 mt-4 font-bold">Action: Create/edit timesheet</div>
      <ConnectionSelector
        connectionType={IntegrationType.XERO}
        connectionId={connectionId}
        title="Xero Tenant"
        onChangeConnection={handleSetConnection}
      />
      <p className="my-2 py-1 text-sm font-bold">Population</p>
      <p className="mb-4 text-sm">
        To create/edit timesheets, you will need to define the sources of each
        input.
      </p>

      <FixedMappings
        node={node?.config}
        template={template}
        wrappedMappingConfigs={[
          {
            mappingKey: IFieldMappingConfigKey.Employee,
            mappingLabel: dashpivotToXeroMappingLabels,
          },

          {
            mappingKey: IFieldMappingConfigKey.Date,
            mappingLabel: dashpivotToXeroMappingLabels,
          },
          {
            mappingKey: IFieldMappingConfigKey.TrackingItem,
            mappingLabel: dashpivotToXeroMappingLabels,
          },
        ]}
        onNodeChange={handleNodeChange}
        header="General"
      />

      <VariableMappings
        node={node?.config}
        template={template}
        mappingDefinition={IFieldMappingConfigKey.Hours}
        onNodeChange={handleNodeChange}
        mappingLabels={{
          header: 'Hours',
          source: 'Dashpivot:',
          destination: 'Xero:',
        }}
        dropdownOptions={{ destination: earningsRatesDropdownOptions }}
      />

      <div className="mb-2 mt-2">
        <p className="text-sm font-bold">Editing existing timesheets</p>
        <p className="mb-2 pt-2 text-sm text-default-text">
          If a timesheet already exists in the same pay period, how should new
          values be handled?
        </p>
        <Combobox
          dataTestId="handling-dropdown"
          name="handling"
          labelKey="_id"
          valueKey="_id"
          options={filteredHandlingOptions}
          value={timesheetExistsSetting}
          placeholder="Select..."
          width="w-57.5"
          capitalize
          onInputChange={setQuery}
          onChange={handleTimesheetSettingChange}
        />
      </div>
    </>
  );
};
