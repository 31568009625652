import logoExcel from 'src/assets/integrations/icons/logo-microsoft-excel.svg';
import logoPowerBI from 'src/assets/integrations/icons/logo-microsoft-power-bi.svg';
import logoQuickbooks from 'src/assets/integrations/icons/logo-quickbooks.svg';
import logoXero from 'src/assets/integrations/icons/logo-xero.svg';

import { INodeType } from '@site-mate/sitemate-flowsite-shared';

import { FeatureToggles, isFeatureEnabled } from '@/common/feature-toggle';

export interface IFlowOption {
  title: string;
  image: string;
  options: IFlowOptionItem[];
}

export interface IFlowOptionItem {
  name: string;
  description: string;
  flow: INodeType;
  subtext?: string;
}

const quickbooksOption: IFlowOption[] = [];
if (isFeatureEnabled(FeatureToggles.QUICKBOOKS)) {
  quickbooksOption.push({
    title: 'QuickBooks',
    image: logoQuickbooks,
    options: [
      {
        name: 'Invoices',
        description: 'Populate QuickBooks Invoices with data from Dashpivot',
        flow: INodeType.QUICKBOOKS_INVOICE_V1,
      },
    ],
  });
}

export const FlowOptions: IFlowOption[] = [
  {
    title: 'Xero',
    image: logoXero,
    options: [
      {
        name: 'Timesheets',
        description: 'Populate Xero Timesheets with data from Dashpivot',
        flow: INodeType.XERO_TIMESHEET_V1,
      },
      {
        name: 'Invoices',
        description: 'Populate Xero Invoices with data from Dashpivot',
        flow: INodeType.XERO_INVOICE_V1,
      },
      {
        name: 'Bills',
        description: 'Populate Xero Bills with data from Dashpivot',
        flow: INodeType.XERO_BILL_V1,
      },
    ],
  },
  ...quickbooksOption,
  {
    title: 'Microsoft Power BI',
    image: logoPowerBI,
    options: [
      {
        name: 'Export',
        description:
          'Enable Microsoft Power BI to access forms data in Dashpivot',
        flow: INodeType.POWERBI_CONNECTOR_V1,
      },
    ],
  },
  {
    title: 'Microsoft Excel',
    image: logoExcel,
    options: [
      {
        name: 'Export',
        description: 'Enable Microsoft Excel to access forms data in Dashpivot',
        flow: INodeType.EXCEL_CONNECTOR_V1,
      },
    ],
  },
];
