import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useState } from 'react';

import { IUserProfile } from '@site-mate/sitemate-global-shared';

import { HelpArticles } from '@/common';
import { Button, IButtonColor, IButtonSize, Spinner } from '@/components';
import { useAuthenticatedUser } from '@/hooks/useUsers';
import {
  useRemoveUserFromWorkspace,
  useUsers,
} from '@/pages/users/hooks/useUsers';
import { useWorkspaceContext } from '@/providers/WorkspaceProvider';

import { InviteUser } from './InviteUser';

const columnHelper = createColumnHelper<IUserProfile>();

const columns = [
  columnHelper.accessor('firstName', {
    header: () => 'First Name',
    size: 1,
  }),
  columnHelper.accessor('lastName', {
    header: () => 'Last Name',
    size: 1,
  }),
  columnHelper.accessor('email', {
    header: () => 'Email',
    size: 1,
  }),
];

export const Users = () => {
  const [users, setUsers] = useState<IUserProfile[]>([]);
  const [userToRemove, setUserToRemove] = useState<IUserProfile>();
  const { workspaceId } = useWorkspaceContext();
  const { data: currentUser, isFetching: isFetchingCurrentUser } =
    useAuthenticatedUser();
  const { mutate: removeUser, isLoading: isRemoving } =
    useRemoveUserFromWorkspace(workspaceId);
  const { data, isFetching } = useUsers(workspaceId);

  const table = useReactTable({
    data: users,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 1,
    },
    getRowId: (user: IUserProfile) => user._id,
  });

  const handleUserDelete = (email: string) => {
    setUserToRemove(users.find((user) => user.email === email));
    removeUser({ email });
  };

  useEffect(() => {
    // Resets the users when the workspaceId has changed
    setUsers([]);
  }, [workspaceId]);

  useEffect(() => {
    if (data) {
      setUsers(data);
    }
    if (!isRemoving) {
      setUserToRemove(undefined);
    }
  }, [data, isRemoving]);

  return (
    <div className="mt-4 rounded-sm-10 bg-white p-6 shadow">
      <div className="flex flex-row items-start justify-between">
        <h2 className="mb-2 text-xl font-bold">Users</h2>
        <Button
          as="a"
          href={HelpArticles.InvitingUsersToFlowsite}
          color={IButtonColor.GREY_1}
          size={IButtonSize.SMALL}
        >
          <span className="-mb-[4px] text-xl">
            <i className="cc-icon cc-icon-question" />
          </span>
          <span className="inline-block">Learn more</span>
        </Button>
      </div>

      <div className="users">
        <InviteUser />
        {isFetching || isFetchingCurrentUser ? (
          <Spinner />
        ) : (
          <table className="border-slate-500 w-full table-fixed border-collapse border text-left">
            <thead className="bg-grey-2 text-sm">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    const colWidth = `${(
                      (header.getSize() / table.getTotalSize()) *
                      100
                    ).toFixed(2)}%`;
                    return (
                      <th
                        key={header.id}
                        className="border-slate-600 border px-2 py-2"
                        style={{
                          width: colWidth,
                        }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody className="bg-white">
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id} className="align-top">
                  {row.getVisibleCells().map((cell) => {
                    const isEmailColumn = cell.column.id === 'email';
                    const rowIsCurrentUser = row.id === currentUser?._id;
                    const disableRemoveButton =
                      isRemoving && row.id === userToRemove?._id; // disable button if the user is being removed
                    return (
                      <td
                        valign="middle"
                        className="border-slate-500 overflow-hidden text-clip break-words border p-2 text-sm"
                        key={cell.id}
                      >
                        <div className="flex items-center justify-between">
                          <div className="overflow-hidden text-ellipsis whitespace-nowrap pr-1">
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </div>
                          {isEmailColumn && !rowIsCurrentUser && (
                            <Button
                              color={IButtonColor.WHITE}
                              size={IButtonSize.SMALL}
                              disabled={disableRemoveButton}
                              aria-label="delete-user"
                              onClick={() =>
                                handleUserDelete(cell.getValue() as string)
                              }
                            >
                              <i className="cc-icon cc-icon-delete cursor-pointer text-xl text-default-red" />
                            </Button>
                          )}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
