import { useQuery } from 'react-query';

import { IntegrationType } from '@site-mate/sitemate-flowsite-shared';

import { useIntegrationsMap } from './useIntegrations';

export function useIntegration(integrationType?: IntegrationType) {
  const integrationsMap = useIntegrationsMap();
  return useQuery({
    queryKey: ['integration', integrationType],
    queryFn: () => {
      const integration = integrationsMap.data?.get(
        integrationType as IntegrationType
      );
      if (!integration) {
        throw new Error(
          `No existing integrations found for ${integrationType}`
        );
      }
      return integration;
    },
    enabled: integrationsMap.isSuccess && !!integrationType,
  });
}
