import { useEffect, useState } from 'react';

export const useLocalStorage = (key: string, defaultValue?: string) => {
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue || defaultValue;
  });

  useEffect(() => {
    if (value) {
      localStorage.setItem(key, value);
    }
  }, [key, value]);

  return [value, setValue] as [
    string,
    React.Dispatch<React.SetStateAction<string>>,
  ];
};
