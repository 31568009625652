import WelcomeImage from '@/assets/svg/welcome.svg';
import { Modal, ModalPositions, Button, IButtonColor } from '@/components';

type WorkspaceTrialModalProps = {
  buttonDisabled: boolean;
  open: boolean;
  onStartTrial: () => void;
};

export const WorkspaceTrialModal = ({
  buttonDisabled,
  open,
  onStartTrial,
}: WorkspaceTrialModalProps) => (
  <Modal open={open} position={ModalPositions.Top}>
    <div className="flex flex-col items-center text-center align-middle leading-normal">
      <img src={WelcomeImage} alt="Welcome to Flowsite" className="w-70 pb-4" />
      <h1 className="pb-4 text-xl font-medium">Welcome to Flowsite</h1>
      <p className="pb-4">
        Flowsite is an integrations platform which allows you to connect your
        software tools through instant no-code integrations.
      </p>
      <p className="pb-4">Flowsite is free for the first 30 days.</p>
      <Button
        disabled={buttonDisabled}
        color={IButtonColor.GREEN}
        onClick={onStartTrial}
      >
        Get Started
      </Button>
    </div>
  </Modal>
);
