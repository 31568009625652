import { HttpStatus } from '@site-mate/sitemate-flowsite-shared';

export const defaultErrorMessage = 'An error occurred';

export class AppError extends Error {
  public readonly statusCode: number;

  constructor(
    message: string = defaultErrorMessage,
    statusCode = HttpStatus.INTERNAL_SERVER_ERROR
  ) {
    super(message);
    this.statusCode = statusCode;
    this.name = this.constructor.name;
  }

  public static create(error: unknown) {
    if (error instanceof Error) {
      return new AppError(error.message);
    }

    return new AppError();
  }
}
