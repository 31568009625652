import { OidcClientSettings } from 'oidc-client-ts';

export const xeroConfig: OidcClientSettings = {
  authority: 'https://identity.xero.com/',
  client_id: import.meta.env.PUBLIC_XERO_CLIENT_PKCE,
  redirect_uri: `${window.location.origin}${
    import.meta.env.PUBLIC_XERO_REDIRECT_PATH
  }`,
  response_type: 'code',
  scope:
    `openid profile email offline_access ` +
    `payroll.timesheets accounting.settings.read payroll.employees.read payroll.settings.read ` +
    `accounting.contacts accounting.transactions`,
  metadata: {
    issuer: 'https://identity.xero.com',
    authorization_endpoint: 'https://login.xero.com/identity/connect/authorize',
    userinfo_endpoint: 'https://identity.xero.com/connect/userinfo',
    end_session_endpoint: 'https://login.xero.com/identity/connect/endsession',
    token_endpoint: 'https://identity.xero.com/connect/token',
  },
};
