import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { PageLayout } from '@/components';
import { TrialExpiredModal } from '@/components/elements/modal/TrialExpiredModal';
import { WelcomeModal } from '@/components/elements/modal/WelcomeModal';
import { WorkspaceSwitcherModal } from '@/components/elements/modal/WorkspaceSwitcherModal';
import { useCreateDashpivotBasicConnection } from '@/hooks/useConnections';
import { Connections } from '@/pages/connections/components/connections';
import { useWorkspaceContext } from '@/providers/WorkspaceProvider';

import { prepareIntegrationConfig } from './components/integrations/integration.config';
import { IntegrationList } from './components/integrations/IntegrationList';

export const ConnectionsPage = () => {
  const [searchParams] = useSearchParams();
  const firstLogin = searchParams.get('firstLogin') === 'true';

  const { workspaceId } = useWorkspaceContext();

  const trialExpired = searchParams.get('trialExpired') === 'true';
  const [isTrialExpiredModalOpen, setIsTrialExpiredModalOpen] =
    useState<boolean>(trialExpired);

  useEffect(() => {
    if (trialExpired) {
      setIsTrialExpiredModalOpen(true);
    }
  }, [trialExpired]);

  const createDashpivotConnection =
    useCreateDashpivotBasicConnection(workspaceId);

  const integrationRows = prepareIntegrationConfig({
    createDashpivotConnection,
  });

  return (
    <PageLayout>
      {firstLogin && <WelcomeModal />}
      <TrialExpiredModal
        isOpen={isTrialExpiredModalOpen}
        onClose={() => setIsTrialExpiredModalOpen(false)}
      />
      <WorkspaceSwitcherModal
        isOpen={trialExpired && !isTrialExpiredModalOpen}
        onClose={() => setIsTrialExpiredModalOpen(true)}
      />
      <Connections />
      <IntegrationList integrationRows={integrationRows} />
    </PageLayout>
  );
};
