import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import qs from 'qs';

import { userManager } from '@/auth/userManager';

import { AppError } from './app.error';

export const api = axios.create({
  baseURL: new URL(
    'flowsite',
    import.meta.env.PUBLIC_SITEMATE_API_URL
  ).toString(),
  paramsSerializer: {
    serialize: (params) => qs.stringify(params),
  },
});

const authInterceptor = async (requestConfig: AxiosRequestConfig) => {
  const config = { ...requestConfig };
  const user = await userManager.getUser();
  const accessToken = user?.access_token;

  if (accessToken) {
    config.headers = {
      ...config.headers,
      authorization: accessToken,
      authtype: 'fusionauth',
    };
  }

  return config;
};

api.interceptors.request.use(authInterceptor);

declare module 'axios' {
  export interface AxiosInstance {
    request<T = any>(config: AxiosRequestConfig): Promise<T>;
    get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
    delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
    head<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
    post<T = any>(
      url: string,
      data?: any,
      config?: AxiosRequestConfig
    ): Promise<T>;
    put<T = any>(
      url: string,
      data?: any,
      config?: AxiosRequestConfig
    ): Promise<T>;
    patch<T = any>(
      url: string,
      data?: any,
      config?: AxiosRequestConfig
    ): Promise<T>;
  }
}
api.interceptors.response.use((response) => response.data);

/**
 * Parses the response error from the API
 * @param error - The error to parse
 * @returns An instance of AppError from the response
 */
export const parseResponseError = (error: unknown): AppError => {
  if (error instanceof AxiosError) {
    return new AppError(
      error?.response?.data?.message ?? error?.response?.data,
      error?.response?.status
    );
  }

  if (error instanceof Error) {
    return new AppError(error.message);
  }

  return new AppError();
};
