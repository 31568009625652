import { OidcClientSettings } from 'oidc-client-ts';

export const dashpivotConfig: OidcClientSettings = {
  authority: import.meta.env.PUBLIC_FUSIONAUTH_AUTHORITY,
  client_id: import.meta.env.PUBLIC_FUSIONAUTH_DASHPIVOT_APPLICATION_ID,
  redirect_uri: `${window.location.origin}${
    import.meta.env.PUBLIC_DASHPIVOT_REDIRECT_PATH
  }`,
  response_type: 'code',
  scope: 'openid offline_access',
};
