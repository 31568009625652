import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setupMonitoring(): any | undefined {
  if (process.env.NODE_ENV === 'test') {
    return undefined;
  }

  // datadog
  const sharedConfig = {
    clientToken: import.meta.env.PUBLIC_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    env: import.meta.env.PUBLIC_NODE_ENV,
  };

  // env suffix
  const envSuffix = sharedConfig.env ? `-${sharedConfig.env}` : '';

  if (sharedConfig.env === 'production') {
    datadogRum.init({
      ...sharedConfig,
      applicationId: import.meta.env.PUBLIC_DATADOG_APPLICATION_ID,
      service: `sitemate-flowsite-client-rum${envSuffix}`,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.startSessionReplayRecording();
  }

  datadogLogs.init({
    ...sharedConfig,
    service: `sitemate-flowsite-client-logs${envSuffix}`,
    sessionSampleRate: 100,
    forwardErrorsToLogs: true,
  });

  // bugsnag
  Bugsnag.start({
    appVersion: import.meta.env.PUBLIC_PACKAGE_VERSION,
    apiKey: import.meta.env.PUBLIC_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
    releaseStage: import.meta.env.PUBLIC_NODE_ENV,
  });

  const plugin = Bugsnag.getPlugin('react');

  if (!plugin) {
    return undefined;
  }

  return plugin.createErrorBoundary(React);
}
