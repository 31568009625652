import clsx from 'clsx';
import { PropsWithChildren, useCallback } from 'react';
import { Link } from 'react-router-dom';

import {
  ButtonColors,
  ButtonSizes,
  IButtonColor,
  IButtonSize,
} from './Button.variants';

type ButtonProps<Element extends React.ElementType = 'button'> = {
  as?: Element;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  size?: IButtonSize;
  color?: IButtonColor;
  corner?: string;
  hidden?: boolean;
} & React.ComponentPropsWithoutRef<Element>;

export const Button = <Element extends React.ElementType = 'button'>({
  as,
  onClick,
  disabled,
  className,
  color = IButtonColor.GREEN,
  size = IButtonSize.DEFAULT,
  corner = 'rounded',
  hidden = false,
  children,
  ...props
}: PropsWithChildren<ButtonProps<Element>>) => {
  const Component = as || 'button';
  const defaultClassName = clsx(
    'text-sm font-medium inline-flex items-center justify-center gap-sm-5 outline-none',
    'focus-visible:outline focus-visible:outline-2 focus-visible:outline-default-blue/50 focus-visible:outline-offset-0',
    corner,
    disabled ? 'opacity-70 cursor-not-allowed' : 'hover:bg-grey-1',
    '[&>i::before]:text-xl [&>i::before]:leading-none',
    ButtonSizes[size],
    ButtonColors[color],
    { 'invisible h-0': hidden },
    className
  );

  const linkClassName = `no-underline hover:text-default-text visited:text-default-text ${defaultClassName}`;

  const tabIndex = disabled ? -1 : undefined;

  const handleClick = useCallback(
    (element: React.MouseEvent<HTMLElement>) => {
      if (disabled) {
        element.preventDefault();
        element.stopPropagation();
        return;
      }
      onClick?.();
    },
    [disabled, onClick]
  );

  if (Component === 'a') {
    return (
      <a
        onClick={handleClick}
        className={linkClassName}
        target={props.target || '_blank'}
        rel={props.rel || 'noopener noreferrer'}
        href={props.href}
        tabIndex={tabIndex}
        hidden={hidden}
      >
        {children}
      </a>
    );
  }

  if (props.to) {
    return (
      <Link
        onClick={handleClick}
        className={linkClassName}
        to={props.to}
        tabIndex={tabIndex}
        hidden={hidden}
      >
        {children}
      </Link>
    );
  }

  return (
    <Component
      {...props}
      hidden={hidden}
      onClick={handleClick}
      disabled={disabled}
      className={defaultClassName}
      tabIndex={tabIndex}
      role={props.role || 'button'}
    >
      {children}
    </Component>
  );
};
