import { OidcClientSettings } from 'oidc-client-ts';

export const quickbooksConfig: OidcClientSettings = {
  authority:
    import.meta.env.PUBLIC_NODE_ENV === 'production'
      ? 'https://developer.api.intuit.com/.well-known/openid_configuration'
      : 'https://developer.api.intuit.com/.well-known/openid_sandbox_configuration',
  client_id: import.meta.env.PUBLIC_QUICKBOOKS_CLIENT_ID,
  client_authentication: 'client_secret_basic',
  redirect_uri: `${window.location.origin}${
    import.meta.env.PUBLIC_QUICKBOOKS_REDIRECT_PATH
  }`,
  response_type: 'code',
  scope: 'com.intuit.quickbooks.accounting',

  metadata: {
    issuer: 'https://oauth.platform.intuit.com/op/v1',
    authorization_endpoint: 'https://appcenter.intuit.com/connect/oauth2',
    userinfo_endpoint:
      import.meta.env.PUBLIC_NODE_ENV === 'production'
        ? 'https://accounts.platform.intuit.com/v1/openid_connect/userinfo'
        : 'https://sandbox-accounts.platform.intuit.com/v1/openid_connect/userinfo',
    revocation_endpoint:
      'https://developer.api.intuit.com/v2/oauth2/tokens/revoke',
    token_endpoint: 'https://oauth.platform.intuit.com/oauth2/v1/tokens/bearer',
  },
};
